import React, { useContext } from 'react';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';

const PractitionerDetailsHeader = (props) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  
  const practitioner = props.practitioner;

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row>
          <Col>
            <h5 className="mb-2">
              {practitioner.last_name}, {practitioner.first_name} {props.practitioner.qualification} ({props.practitioner.identifiers.NPI}) / (Access Medical Clinic, Inc.)
            </h5>
            <IconButton
              iconClassName="fs--2 me-1"
              variant="falcon-default"
              size="sm"
              icon="plus"
            >
              Add note
            </IconButton>
            <Dropdown className="d-inline-block ms-2">
              <Dropdown.Toggle
                as={Button}
                variant="falcon-default"
                size="sm"
                className="dropdown-caret-none"
              >
                <FontAwesomeIcon icon="ellipsis-h" />
              </Dropdown.Toggle>

              <Dropdown.Menu align={isRTL ? 'end' : 'start'}>
                <Dropdown.Item as={Link} to="#!">
                  Edit
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="#!">
                  Archive
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="auto">
            <h6 className="text-uppercase text-600">
              Provider
              <FontAwesomeIcon icon="users-medical" className="ms-2" />
            </h6>
          </Col>
        </Row>
      </Card.Header>
    </Card>
  );
};

export default PractitionerDetailsHeader;

import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {Button, Modal} from "react-bootstrap";
import {useMutation} from "react-relay";
import {toast} from "react-toastify";

const DeleteOrganizationAddressModal = ({showModal, onClose, selectedAddress}) => {
    const mutation = graphql`
        mutation DeleteOrganizationAddressModalMutation($id: ID!) {
                deleteNode(input: {id: $id}) {
                    owner {
                        ... on Organization {
                            id
                            addresses {
                            ...OrganizationAddresses_Addresses
                        }
                    }
                }
            }
        }`
    const [commitMutation, isInFlight] = useMutation(mutation);
    
    const handleDeleteClick = () => {
        commitMutation({variables: {id: selectedAddress.id},
            onCompleted: (data) => {
                if(data.deleteNode) {
                    toast.success("Address has been deleted");
                    onClose();
                }
            }})
    }
    let addressLine = selectedAddress.addressLine1
    if(selectedAddress.addressLine2) {
        addressLine = addressLine + " " + selectedAddress.addressLine2
    }
    if(selectedAddress.addressLine3) {
        addressLine = addressLine + " " + selectedAddress.addressLine3
    }
    return (
        <Modal show={showModal} onHide={onClose} centered size={"lg"} aria-labelledby={"contained-modal-title-vcenter"}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Address?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this Address: {addressLine} {selectedAddress?.city}, {selectedAddress?.state} {selectedAddress?.postalCode}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"falcon-default"} onClick={handleDeleteClick}>{isInFlight ? "Deleting" : "Delete"}</Button>
            </Modal.Footer>
        </Modal>
    )
}
DeleteOrganizationAddressModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedAddress: PropTypes.object.isRequired
}
export default DeleteOrganizationAddressModal
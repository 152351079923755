/**
 * @generated SignedSource<<6f2e1f538baac7b776853ee9676ebeb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ContractFragments_AllContractsDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "LegalContract",
  "abstractKey": null
};

node.hash = "594f32588ae76791121d45d4fef00542";

export default node;

import React from 'react';
import {useLoaderData} from "react-router-dom";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import {Card, Col, Form, Row, Tab} from "react-bootstrap";
import {FormProvider, useForm} from "react-hook-form";
import BillingGroupTab from "./BillingGroups/BillingGroupTab";
import IconButton from "../../components/common/IconButton";
import DetailsTab from "./DetailsTab";
import {useMutation, usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import TabNav from "./components/TabNav";
import {toast} from "react-toastify";

const RosterLoadDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(
        graphql`query RosterLoadDetailsQuery($id: ID!) {
            node(id: $id) {
                ... on RosterLoad {
                    id
                    status
                    fileName
                    downloadUrl
                    createdOn
                    createdBy {
                        userName
                    }
                    legalContractLinesOfService {
                        linesOfService {
                            lineOfService {
                                name
                            }
                            contract {
                                name
                            }
                        }
                    }
                    addedBillingGroups {
                        id
                        status
                        properties {
                            id
                            propertyName
                            displayOrder
                            required
                            isKey
                            proposedValue
                            originalValue
                            possibleValues {
                                showsUp
                                value
                            }
                        }
                    }
                    changedBillingGroups {
                        id
                        status
                        properties {
                            id
                            propertyName
                            displayOrder
                            required
                            isKey
                            proposedValue
                            originalValue
                            possibleValues {
                                showsUp
                                value
                            }
                        }
                    }
                }
            }
        }`
    ,loader.rosterLoadDetailsQuery);
    const commit = graphql`
    mutation RosterLoadDetailsCommitMutation($id: ID!) {
        rosterLoad {
            commitRosterLoad(input: {rosterLoadId: $id}) {
                rosterLoad {
                    id
                    fileName
                    status
                }
            }
        }
    }`
    const methods = useForm({defaultValues:{...data}});
    const [commitMutation, isInFlight] = useMutation(commit);
    const handleCommit = () => {
        commitMutation({variables: {
            id: data.node.id
            }, onCompleted: () => {
                toast.success("Roster Load applied.");
            }})
    }
    return (
        <>
            <DetailsPageHeader title={"Roster Load: " + data.node.fileName} iconText={"Roster Load"} iconType={"upload"} />
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(handleCommit)} noValidate>
                    <Tab.Container id={"rosterLoad"} defaultActiveKey={"rosterLoadDetails"}>
                        <TabNav rosterLoad={data.node} />
                        <Tab.Content>
                            <Tab.Pane eventKey={"rosterLoadDetails"}>
                                <DetailsTab fileName={data.node.fileName} downloadUrl={data.node.downloadUrl} linesOfService={data.node.legalContractLinesOfService.map(l => l.linesOfService)}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"billingGroups"}>
                                <BillingGroupTab billingGroupsAdded={data.node.addedBillingGroups} billingGroupsChanged={data.node.changedBillingGroups} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <IconButton title={"apply roster"} type={"submit"} icon={"check"}>{isInFlight ? "Applying Roster..." : "Apply Roster"}</IconButton>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
            </Form>
            </FormProvider>
        </>
    )
}
export default RosterLoadDetails
export const payersData = [
    {
        id: '1',
        name: 'Arkansas Superior Select, Inc.',
        avatar: {
            name: 'Arkansas SuperiorSelect, Inc.'
        },
        email: 'main@superiorselectinc.com',
        phone: '(501) 200-1851',
        address: 'One Riverplace Plaza, North Little Rock, Arkansas 72201',
        joined: '03/30/2015'
    }
];

/**
 * @generated SignedSource<<379a9c80e6a238b1ce22e240a54c6927>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractLineOfServiceDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContractLineOfService",
                "kind": "LinkedField",
                "name": "linesOfService",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LineOfService",
                    "kind": "LinkedField",
                    "name": "lineOfService",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ContractLineOfServiceInfo_ContractLineOfService"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LegalContract",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractLineOfServiceDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContractLineOfService",
                "kind": "LinkedField",
                "name": "linesOfService",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LineOfService",
                    "kind": "LinkedField",
                    "name": "lineOfService",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isComplex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percentageOfBase",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "otherServicesDiscountPercentage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveFrom",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveTo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "feeScheduleDescription",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LegalContract",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55390e6a99c629cfa750ff856dec2d29",
    "id": null,
    "metadata": {},
    "name": "ContractLineOfServiceDetailsQuery",
    "operationKind": "query",
    "text": "query ContractLineOfServiceDetailsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on LegalContract {\n      id\n      linesOfService {\n        id\n        lineOfService {\n          name\n          id\n        }\n        ...ContractLineOfServiceInfo_ContractLineOfService\n      }\n    }\n    id\n  }\n}\n\nfragment ContractLineOfServiceInfo_ContractLineOfService on LegalContractLineOfService {\n  id\n  contract {\n    startDate\n    endDate\n    id\n  }\n  lineOfService {\n    name\n    id\n  }\n  isComplex\n  percentageOfBase\n  otherServicesDiscountPercentage\n  effectiveFrom\n  effectiveTo\n  feeScheduleDescription\n}\n"
  }
};
})();

node.hash = "2ba27c43ddebe7f46c50df5e8485932e";

export default node;

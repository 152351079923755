/**
 * @generated SignedSource<<0604ed7408e9b262fc492cb5375d8661>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "feeScheduleDescription"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isComplex"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "otherServicesDiscountPercentage"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "percentageOfBase"
},
v7 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "feeScheduleDescription",
        "variableName": "feeScheduleDescription"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "isComplex",
        "variableName": "isComplex"
      },
      {
        "kind": "Variable",
        "name": "otherServicesDiscountPercentage",
        "variableName": "otherServicesDiscountPercentage"
      },
      {
        "kind": "Variable",
        "name": "percentageOfBase",
        "variableName": "percentageOfBase"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractLineOfServiceInfo_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "LegalContractLineOfServiceModifyPayload",
            "kind": "LinkedField",
            "name": "modifyLineOfServiceCurrentVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContractLineOfService",
                "kind": "LinkedField",
                "name": "legalContractLineOfService",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ContractLineOfServiceInfo_ContractLineOfService"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContractLineOfServiceInfo_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "LegalContractLineOfServiceModifyPayload",
            "kind": "LinkedField",
            "name": "modifyLineOfServiceCurrentVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContractLineOfService",
                "kind": "LinkedField",
                "name": "legalContractLineOfService",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LineOfService",
                    "kind": "LinkedField",
                    "name": "lineOfService",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isComplex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "percentageOfBase",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "otherServicesDiscountPercentage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveFrom",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveTo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "feeScheduleDescription",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "648213f826fcc9f6fb9f5770061b0ab3",
    "id": null,
    "metadata": {},
    "name": "ContractLineOfServiceInfo_Mutation",
    "operationKind": "mutation",
    "text": "mutation ContractLineOfServiceInfo_Mutation(\n  $id: ID!\n  $percentageOfBase: DecimalInput\n  $otherServicesDiscountPercentage: DecimalInput\n  $isComplex: Boolean\n  $feeScheduleDescription: String\n  $effectiveFrom: DateOnly\n  $effectiveTo: DateOnly\n) {\n  legalContract {\n    modifyLineOfServiceCurrentVersion(input: {id: $id, percentageOfBase: $percentageOfBase, otherServicesDiscountPercentage: $otherServicesDiscountPercentage, isComplex: $isComplex, feeScheduleDescription: $feeScheduleDescription, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      legalContractLineOfService {\n        ...ContractLineOfServiceInfo_ContractLineOfService\n        id\n      }\n    }\n  }\n}\n\nfragment ContractLineOfServiceInfo_ContractLineOfService on LegalContractLineOfService {\n  id\n  contract {\n    startDate\n    endDate\n    id\n  }\n  lineOfService {\n    name\n    id\n  }\n  isComplex\n  percentageOfBase\n  otherServicesDiscountPercentage\n  effectiveFrom\n  effectiveTo\n  feeScheduleDescription\n}\n"
  }
};
})();

node.hash = "4cc1544724b36dace83e18a6344668c4";

export default node;

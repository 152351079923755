/**
 * @generated SignedSource<<2a36c7a2fb7bbe78413900c79b21d415>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Role",
    "kind": "LinkedField",
    "name": "allRoles",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RolesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RolesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "94b2a37ba761d6d3140e2582603fea88",
    "id": null,
    "metadata": {},
    "name": "RolesQuery",
    "operationKind": "query",
    "text": "query RolesQuery {\n  allRoles {\n    id\n    name\n  }\n}\n"
  }
};
})();

node.hash = "805f24f9350f2a0585626c383bcac01a";

export default node;

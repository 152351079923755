import React from 'react';
import ContractInfo from "./ContractInfo";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import {useLoaderData} from "react-router-dom";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import ContractAttachments from "./ContractAttachments/ContractAttachments";
import ContractLinesOfService from "./ContractLineOfService/ContractLinesOfService";
import ContractTerms from "./ContractTerms/ContractTerms";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import {LinkCell} from "../../components/common/advance-table/CustomCells";
import RosterLoadTable from "../roster-load/RosterLoadTable";
import {useUser} from "../../hooks/useUser";

const cols = [
    {
        accessor: "fileName",
        Header: "Filename",
        Cell: rowData => {
            const {fileName, id} = rowData.row.original;
            return <LinkCell name={fileName} to={'/roster-loads/' + id} hasAvatar />
        }
    },
    {
        accessor: "status",
        Header: "Status"
    },
    {
        accessor: "whenCreated",
        Header: "When Created",

    },
    {
        accessor: "whoCreated",
        Header: "Who Created"
    }

]
const ContractDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
        query ContractDetailsQuery($id: ID!) {
            node(id: $id) {
                ... on LegalContract {
                    id
                    name
                    startDate
                    endDate
                    network {
                        id
                        linesOfService {
                            ...LinesOfServiceFragments_LineOfServiceDropdown
                        }
                    }
                    ...ContractInfo_Contract
                    attachments {
                        ...ContractAttachments_Attachments
                    }
                    linesOfService {
                        ...ContractLinesOfServiceFragments_LinesOfService
                    }
                    terms {
                        ...ContractTerms_Terms
                    }
rosterLoads {
                    ...RosterLoadTable_RosterLoad
                }
                }
            }
            allOrganizations {
                ...OrganizationsFragments_AllOrganizationsDropdown
            }
            allNetworks {
                ...NetworkFragments_allNetworkDropdown
            }
            allTermTypes {
                id
                description
                valueType
                ...TermTypesFragments_TermTypeDropdown
            }
            allAttachmentCategories {
                id
                name
                displayOrder
                ...Fragments_AllAttachmentCategoriesDropdown
            }
        }
    `, loader.contractDetailsQuery)
    const {hasPermission} = useUser();
    return (
        <>
            <DetailsPageHeader iconText={"Contract"} iconType={"pen-nib"} title={data.node.name} />
            <ContractInfo contract={data.node} networks={data.allNetworks} organizations={data.allOrganizations} />
            <ContractTerms terms={data.node.terms} contractId={data.node.id} possibleTermTypes={data.allTermTypes}/>
            <ContractAttachments attachments={data.node.attachments} contractId={data.node.id} possibleAttachmentCategories={data.allAttachmentCategories}/>
            <ContractLinesOfService linesOfService={data.node.linesOfService}
                                    contractId={data.node.id}
                                    possibleLinesOfService={data.node.network.linesOfService}
                                    contractStartDate={data.node.startDate}
                                    contractEndDate={data.node.endDate}
            />
            {hasPermission("RosterLoads.Read") && 
                <RosterLoadTable 
                    linesOfService={data.node.linesOfService} 
                    rosterLoad={data.node.rosterLoads}
                    allowNew={true}
                />}
        </>
    );
};

export default ContractDetails;

/**
 * @generated SignedSource<<f7143cd82ebfc81becb52082ac8a03bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingGroup",
  "kind": "LinkedField",
  "name": "allBillingGroups",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LegalContract",
      "kind": "LinkedField",
      "name": "legalContract",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingGroupsQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContractFragments_AllContractsDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BillingGroupsQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "13f766351daafafce6bc37bab8abb1c8",
    "id": null,
    "metadata": {},
    "name": "BillingGroupsQuery",
    "operationKind": "query",
    "text": "query BillingGroupsQuery {\n  allBillingGroups {\n    id\n    name\n    legalContract {\n      id\n      name\n    }\n  }\n  allLegalContracts {\n    ...ContractFragments_AllContractsDropdown\n    id\n  }\n}\n\nfragment ContractFragments_AllContractsDropdown on LegalContract {\n  id\n  name\n}\n"
  }
};
})();

node.hash = "7999b50c52eb66dd0bdfba010c93ec36";

export default node;

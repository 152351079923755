import React, {useState} from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allContractsDropdown} from "../contracts/ContractFragments";
import {useUser} from "../../hooks/useUser";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import InfoPanel from "../../components/common/repeated-page-panels/InfoPanel";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";

const BillingGroupInfo = ({billingGroup, contracts}) => {
    const UpdateBillingGroupMutation = graphql`
    mutation BillingGroupInfoMutation($id: ID!, $name: String!, $legalContractId: ID!) {
        billingGroup {
            modify(input: {id: $id, name: $name, legalContractId: $legalContractId}) {
                billingGroup {
                    ...BillingGroupInfo_BillingGroup
                }
            }
        }
    }`
    const billingGroupInfo = useFragment(graphql`
    fragment BillingGroupInfo_BillingGroup on BillingGroup {
        id
        name
        legalContract {
            name
            id
        }
    }`, billingGroup)
    const allContracts = useFragment(allContractsDropdown, contracts).map((c) => {return {value: c.id, label:c.name}});
    const [readMode, setReadMode] = useState(true);
    const [commitMutation, isInFlight] = useMutation(UpdateBillingGroupMutation);
    const {hasPermission} = useUser();
    const methods = useForm({values: {
        name: billingGroupInfo.name,
            legalContractId: billingGroupInfo.legalContract.id
    }});
    const onSubmit = (data) => {
        commitMutation({variables: {
            id: billingGroupInfo.id,
                ...data
            }, onCompleted: (data) => {
                if(data.billingGroup) {
                    toast.success("Billing Group has been updated")
                    setReadMode(true);
                }
            }})
    }
    const handleCancelClick = () => {
        methods.reset();
        setReadMode(true);
    }
    return (
        <FormProvider {...methods}>
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <InfoPanel readMode={readMode}
                           onCancelChangesClick={handleCancelClick}
                           name={billingGroupInfo.name}
                           isInFlight={isInFlight}
                           onUpdateDetailsClick={() => {setReadMode(false)}}
                           showUpdate={hasPermission("BillingGroups.Update")}>
                    <HorizontalFormInput controlId={"name"} 
                                         label={"Name"} 
                                         readMode={readMode} 
                                         validation={{required: "Name is required"}} 
                    />
                    <HorizontalFormSelect options={allContracts} 
                                          controlId={"legalContractId"} 
                                          label={"Contract"} 
                                          readMode={readMode} 
                                          validation={{required: "Contract is required"}} 
                    />
                </InfoPanel>
            </Form>
        </FormProvider>
    )
}
BillingGroupInfo.propTypes = {
    billingGroup: PropTypes.object.isRequired,
    contracts: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default BillingGroupInfo
import React from 'react';
import PropTypes from 'prop-types';
import {ButtonGroup, Button} from "react-bootstrap";
import {useFormContext, useWatch} from "react-hook-form";
import {useMutation} from "react-relay";

const AcceptReject = ({controlId, mutation}) => {
    const {setValue, trigger, getValues,clearErrors} = useFormContext()
    const statusKey = controlId + ".status"
    const status = useWatch({name:statusKey});
    const id = getValues(controlId + ".id");
    const [commitMutation, isInFlight] = useMutation(mutation);
    const handleAcceptClick = async () => {
        const result = await trigger(controlId)
        
        if(result) {
            commitMutation({variables: {
                    id: id,
                    status: "Accepted"
                }, onCompleted: () => {
                    setValue(statusKey,"Accepted");
                }})
        }
    }
    const handleRejectClick = () => {
        commitMutation({variables: {
                id: id,
                status: "Rejected"
            }, onCompleted: () => {
                clearErrors(controlId);
                setValue(statusKey, "Rejected");
            }})
    }
    const handleUndecidedClick = () => {
        commitMutation({variables: {
            id: id,
                status: "Undecided"
            }, onCompleted: () => {
                setValue(statusKey, "Undecided")
            }})
    }
    return (<>
            {status === "Undecided" &&
        <ButtonGroup size={"sm"}>
            <Button variant={"success"} onClick={handleAcceptClick}>Accept</Button>
            <Button variant={"danger"} onClick={handleRejectClick}>Reject</Button>
        </ButtonGroup>
}
            {status !== "Undecided" &&
                <Button variant={"primary"} size={"sm"} onClick={handleUndecidedClick}>Edit</Button>}
        </>
    )
}
AcceptReject.defaultProps = {}
AcceptReject.propTypes = {}
export default AcceptReject
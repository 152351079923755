import React, { useState } from 'react';
import {useLoaderData} from 'react-router-dom';
import ContractsTable from "./ContractsTable";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import NewContractModal from "./NewContractModal";
const Contracts = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(
        graphql`
            query ContractsQuery {
                allLegalContracts {
                    ...ContractsTable_Contract
                }
                allNetworks {
                    ...NetworkFragments_allNetworkDropdown
                }
                allOrganizations {
                    ...OrganizationsFragments_AllOrganizationsDropdown
                }
            }
        `, loader.contractsQuery)
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <ContractsTable contracts={data.allLegalContracts} onNewClick={() => {setShowModal(true)}} />
            <NewContractModal showModal={showModal} 
                              onClose={() => {setShowModal(false)}} 
                              organizations={data.allOrganizations}
                              networks={data.allNetworks} 
            />
        </>
    );
};

export default Contracts;

import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import FormModal from "../../../components/common/modals/FormModal";
import {allIdentifierTypesDropdown, allIdentifierUseCodesDropdown} from "../../../relay/Fragments";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import {toast} from "react-toastify";

const NewOrganizationIdentifierModal = ({showModal, onClose, organizationId, identifierTypes, identifierUseCodes}) => {
    const mutation = graphql`
        mutation NewOrganizationIdentifierModalMutation($organizationId: ID!, $identifierTypeId:ID!, $identifierUseCodeId:ID!, 
        $value: String!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
            organization {
                createIdentifier(input: {organizationId: $organizationId, identifierTypeId: $identifierTypeId, identifierUseCodeId: $identifierUseCodeId,
                value: $value, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                    organizationIdentifier {
                        id
                        organization {
                            id
                            identifiers {
                                ...OrganizationIdentifiers_Identifiers
                            }
                        }
                    }
                }
            }
        }`
    const idTypes = useFragment(allIdentifierTypesDropdown, identifierTypes).map((id) => {return {value: id.id, label:id.display}});
    const idUseCodes = useFragment(allIdentifierUseCodesDropdown, identifierUseCodes).map((id) => {return {value: id.id, label: id.display}});
    
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        organizationId: organizationId,
        identifierTypeId: '',
        identifierUseCodeId: '',
        value: '',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999, 11,31)
    }
    const schema = yup.object({
        identifierTypeId: yup.string().required("Identifier Type is required"),
        identifierUseCodeId: yup.string().required("Identifier Use Code is required"),
        value: yup.string().required("Value is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
            
    })
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    
    
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, 
            onCompleted: (data) => {
                if(data.organization) {
                    toast.success("Identifier for Organization created")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"New Identifier"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={idTypes} controlId={"identifierTypeId"} label={"Identifier Type"} />
            <HorizontalFormSelect options={idUseCodes} controlId={"identifierUseCodeId"} label={"Identifier Use"} />
            <HorizontalFormInput controlId={"value"} label={"Value"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
NewOrganizationIdentifierModal.propTypes = {
    showModal:PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    organizationId: PropTypes.string.isRequired,
    identifierTypes:PropTypes.array.isRequired,
    identifierUseCodes:PropTypes.array.isRequired
}
export default NewOrganizationIdentifierModal
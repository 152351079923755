/**
 * @generated SignedSource<<abe6ca5162f4749176b38aedcea6c2a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "termTypeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "legalContractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "termTypeId",
        "variableName": "termTypeId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewContractTermModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LegalContractTermModifyPayload",
            "kind": "LinkedField",
            "name": "addTerm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContractTerm",
                "kind": "LinkedField",
                "name": "legalContractTerm",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LegalContractTerm",
                        "kind": "LinkedField",
                        "name": "terms",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ContractTerms_Terms"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewContractTermModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LegalContractTermModifyPayload",
            "kind": "LinkedField",
            "name": "addTerm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContractTerm",
                "kind": "LinkedField",
                "name": "legalContractTerm",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LegalContractTerm",
                        "kind": "LinkedField",
                        "name": "terms",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TermType",
                            "kind": "LinkedField",
                            "name": "type",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "valueType",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78977a4f8e439459b37e00ecd4136c51",
    "id": null,
    "metadata": {},
    "name": "NewContractTermModal_Mutation",
    "operationKind": "mutation",
    "text": "mutation NewContractTermModal_Mutation(\n  $contractId: ID!\n  $termTypeId: ID!\n  $value: String!\n) {\n  legalContract {\n    addTerm(input: {legalContractId: $contractId, termTypeId: $termTypeId, value: $value}) {\n      legalContractTerm {\n        id\n        contract {\n          id\n          terms {\n            ...ContractTerms_Terms\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ContractTerms_Terms on LegalContractTerm {\n  id\n  type {\n    id\n    description\n    valueType\n  }\n  value\n}\n"
  }
};
})();

node.hash = "b0710c06e7740c855f08ffa06baaa8d7";

export default node;

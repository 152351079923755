import React from 'react';
import PropTypes from 'prop-types';
import SoftBadge from "../../../components/common/SoftBadge";

const StatusBadge = ({status}) => {
    const badgeType = status === "Undecided" ? "warning" : status === "Rejected" ? "info" : "success";
    return (
        <SoftBadge bg={badgeType} className={"me-2"}>
            {status}
        </SoftBadge>
    )
}
StatusBadge.propTypes = {
    status: PropTypes.oneOf(["Undecided", "Accepted", "Rejected"]).isRequired
}
export default StatusBadge
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { locationsData } from 'data/locations/locationsData';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";


const Locations = ({locations, withTaxonomies}) => {
    const [locationSet] = useState(locations || locationsData);
    const columns = [
        {
            accessor: 'name',
            Header: 'Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { name } = rowData.row.original;
                return (
                    <Link to="/e-commerce/customer-details">
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className="mb-0 fs--1">{name}</h5>
                            </div>
                        </Flex>
                    </Link>
                );
            }
        },
        {
            accessor: 'address',
            Header: 'Address'
        }];
    if (withTaxonomies) {
        columns.push({
            accessor: 'specialties',
            Header: 'Specialties'
        })
    }
    columns.push(        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: () => {
                return (
                    <CardDropdown>
                        <div className="py-2">
                            <Dropdown.Item href="#!">Edit</Dropdown.Item>
                            <Dropdown.Item href="#!">Delete</Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    );

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={locationSet}
            selection
            sortable
            pagination
            perPage={10}
        >
            <Card className="mb-3">
                <Card.Header>
                    <IndexTableHeader name={"Locations"} onNewClick={() => {}} table />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination table />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Locations;

/**
 * @generated SignedSource<<471b216de117ca0f03b2732747424b71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "networkOwnerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "primaryName"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "networkOwnerId",
        "variableName": "networkOwnerId"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "primaryName",
        "variableName": "primaryName"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationInfo_OrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMutationGroup",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "OrganizationModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "OrganizationInfo_Organization"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationInfo_OrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMutationGroup",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "OrganizationModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NetworkOwner",
                    "kind": "LinkedField",
                    "name": "networkOwner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db902b1d13ee842976021fe418cd6c6a",
    "id": null,
    "metadata": {},
    "name": "OrganizationInfo_OrganizationMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationInfo_OrganizationMutation(\n  $id: ID!\n  $primaryName: String!\n  $networkOwnerId: ID!\n) {\n  organization {\n    modify(input: {organizationId: $id, primaryName: $primaryName, networkOwnerId: $networkOwnerId}) {\n      organization {\n        ...OrganizationInfo_Organization\n        id\n      }\n    }\n  }\n}\n\nfragment OrganizationInfo_Organization on Organization {\n  id\n  primaryName\n  networkOwner {\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "b3d86436df301f30d208884b1e088576";

export default node;

import React from 'react';
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import graphql from "babel-plugin-relay/macro";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import {useMutation} from "react-relay";
import {toast} from "react-toastify";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const EditContractTermModal = ({showModal, onClose, selectedTerm, possibleTermTypes}) => {
    const UpdateContractTermMutation = graphql`
        mutation EditContractTermModal_Mutation($id: ID!, $value: String!) {
            legalContract {
                modifyTerm(input: {id: $id, value: $value}) {
                    legalContractTerm {
                        id
                        value
                    }
                }
            }
        }`
    const [commitMutation] = useMutation(UpdateContractTermMutation);
    const methods = useForm({values: {value: convertValueToFormValue(selectedTerm?.type?.valueType, selectedTerm.value)}});
    
    function convertValueToFormValue(valueType, value) {
        if (valueType === "DAYS") {
            return Number(value);
        }
        if (valueType === "DATE") {
            return dayjs(value, 'MM/DD/YYYY').toDate();
        }
        return value;
    }
    
    function convertValueToString(valueType, value) {
        if (valueType === "DAYS") {
            return value.toString();
        }
        if (valueType === "DATE") {
            return dayjs.utc(value).format('MM/DD/YYYY');
        }
        return value;
    }

    const handleSubmit = (data) => {
        commitMutation({variables: {
                id: selectedTerm.id,
                value: convertValueToString(selectedTerm.type.valueType, data.value)
            }, onCompleted: () => {
                toast.success("Term has been updated")
                onClose()
            }})
    }
    const selectedTermTypeId = selectedTerm?.type?.id;

    const valueType = possibleTermTypes.find(tt => tt.id === selectedTermTypeId)?.valueType;

    const booleanOptions = [{value: "Yes", label: "Yes"}, {value: "No", label: "No"}];

    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   methods={methods}
                   onSubmit={handleSubmit}
                   isInFlight={false}
                   title={"Edit Contract Term"}
        >
            { valueType === "BOOL" && <HorizontalFormSelect options={booleanOptions} controlId={"value"} label={"Value"} validation={{required: "Value is required"}} /> }
            { valueType === "DAYS" && <HorizontalFormInput controlId={"value"}
                                                           label={"Days"}
                                                           validation={{required: "Value is required"}}
            /> }
            { valueType === "DATE" && <HorizontalFormDate controlId={"value"} label={"Date"} validation={{required: "Value is required"}} /> }
        </FormModal>
    )
}
EditContractTermModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    possibleTermTypes: PropTypes.array.isRequired,
    selectedTerm: PropTypes.object.isRequired
}
export default EditContractTermModal
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {components} from "react-select";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon={"chevron-down"} size={"xs"} />
        </components.DropdownIndicator>
    )
}
export default DropdownIndicator
import React from 'react';
import LicenseDetailsHeader from "./LicenseDetailsHeader";
import LicenseInfo from "./LicenseInfo";
import OrganizationsTable from "../organizations/OrganizationsTable";

const LicenseDetails = () => {
    return (
        <>
            <LicenseDetailsHeader />
            <LicenseInfo />
            {/* When we get data fetching ready uncomment -> <OrganizationsTable />*/}
        </>
    );
};

export default LicenseDetails;

/**
 * @generated SignedSource<<ec28a3d62fd5158a0f0880fa2af0d32f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "networkId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "networkId",
        "variableName": "networkId"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractInfo_ContractMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "LegalContractModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContract",
                "kind": "LinkedField",
                "name": "legalContract",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ContractInfo_Contract"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContractInfo_ContractMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "LegalContractModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContract",
                "kind": "LinkedField",
                "name": "legalContract",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Network",
                    "kind": "LinkedField",
                    "name": "network",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "primaryName",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "07bd7a53eb639c70efe0a4eceac7d325",
    "id": null,
    "metadata": {},
    "name": "ContractInfo_ContractMutation",
    "operationKind": "mutation",
    "text": "mutation ContractInfo_ContractMutation(\n  $id: ID!\n  $name: String\n  $networkId: ID!\n  $organizationId: ID!\n  $startDate: DateOnly!\n  $endDate: DateOnly!\n) {\n  legalContract {\n    modify(input: {id: $id, name: $name, networkId: $networkId, organizationId: $organizationId, startDate: $startDate, endDate: $endDate}) {\n      legalContract {\n        ...ContractInfo_Contract\n        id\n      }\n    }\n  }\n}\n\nfragment ContractInfo_Contract on LegalContract {\n  id\n  name\n  startDate\n  endDate\n  network {\n    name\n    id\n  }\n  organization {\n    primaryName\n    id\n  }\n}\n"
  }
};
})();

node.hash = "7a6221179c2faf3bbfbaad76bf958d93";

export default node;

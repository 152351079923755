import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allAddressUseCodesDropdown} from "../../../relay/Fragments";
import dayjs from "dayjs";
import * as yup from 'yup'
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal"
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const EditOrganizationAddressModal = ({showModal, onClose, selectedAddress, addressUseCodes}) => {
    const mutation = graphql`
    mutation EditOrganizationAddressModalMutation($organizationAddressId: ID!, $addressUseCodeId:ID!, 
    $addressLine1:String!, $addressLine2: String!, $addressLine3: String!, $city: String!, $state: String!, $postalCode: String!,
        $longitude: DecimalInput, $latitude: DecimalInput, $effectiveFrom: DateOnly, $effectiveTo: DateOnly) {
        organization {
            modifyAddress(input: {organizationAddressId: $organizationAddressId, addressUseCodeId: $addressUseCodeId,
            addressLine1: $addressLine1, addressLine2: $addressLine2, addressLine3: $addressLine3, city: $city, state: $state,
            postalCode: $postalCode, longitude: $longitude, latitude: $latitude, effectiveFrom: $effectiveFrom,
            effectiveTo: $effectiveTo}) {
                organizationAddress {
                    id
                    organization {
                        addresses {
                            ...OrganizationAddresses_Addresses
                        }
                    }
                }
            }
        }
    }`
    const addressUse = useFragment(allAddressUseCodesDropdown, addressUseCodes).map((a) => {return {value: a.id, label: a.display}});
    const [commitMutation, isInFlight] = useMutation(mutation);
    const effectiveFrom = selectedAddress?.effectiveFrom ? dayjs(selectedAddress.effectiveFrom).toDate() : '';
    const effectiveTo = selectedAddress?.effectiveTo ? dayjs(selectedAddress.effectiveTo).toDate() : '';
    const defaultValues = {
        ...selectedAddress,
        organizationAddressId: selectedAddress?.id,
        addressUseCodeId: selectedAddress?.addressUseCode?.id,
        effectiveFrom: effectiveFrom,
        effectiveTo: effectiveTo
    };
    const schema = yup.object({
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From"),
        addressUseCodeId: yup.string().required("Use Code is required"),
        addressLine1: yup.string().required("Line 1 is required"),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required").length(2, "Please use the abbreviation for the state"),
        postalCode: yup.string().required("Postal Code is required").max(9, "Postal Code can only be 9 long"),
        longitude: yup.number(),
        latitude: yup.number(),
    });
    const methods = useForm({values: defaultValues, resolver: yupResolver(schema)});
    
    const handleSubmitClick = (data) => {
        commitMutation({variables: {...data},
            onCompleted: (data) => {
                if(data.organization) {
                    toast.success("Address has been updated")
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"Edit Address"}
                   methods={methods}
                   onSubmit={handleSubmitClick}
                   isInFlight={isInFlight}
        >
            <HorizontalFormSelect options={addressUse} controlId={"addressUseCodeId"} label={"Use Code"} />
            <HorizontalFormInput controlId={"addressLine1"} label={"Line 1"} />
            <HorizontalFormInput controlId={"addressLine2"} label={"Line 2"} />
            <HorizontalFormInput controlId={"addressLine3"} label={"Line 3"} />
            <HorizontalFormInput controlId={"city"} label={"City"} />
            <HorizontalFormInput controlId={"state"} label={"State"} />
            <HorizontalFormInput controlId={"postalCode"} label={"Postal Code"} />
            <HorizontalFormInput controlId={"longitude"} 
                                 label={"Longitude"}
                                 registerOptions={{valueAsNumber: true}}
                                 type={"number"}
                                 step={"0.0001"}
            />
            <HorizontalFormInput controlId={"latitude"}
                                 label={"Latitude"}
                                 registerOptions={{valueAsNumber: true}}
                                 type={"number"}
                                 step={"0.0001"}
            />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
EditOrganizationAddressModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedAddress: PropTypes.object.isRequired,
    addressUseCodes: PropTypes.array.isRequired
}
export default EditOrganizationAddressModal
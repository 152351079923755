import React from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Row} from "react-bootstrap";
import {useController} from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";
import DropdownIndicator from "./DropdownIndicator";
import ClearIndicator from "./ClearIndicator";
const HorizontalFormSelect = ({controlId, label, options, readMode, validation, onChange, ...rest}) => {
    const {field, fieldState: {invalid, error}} = useController({name: controlId, rules: validation})
    return (
        <Form.Group as={Row} className={"mb-1"} controlId={controlId}>
            <Form.Label column xs={7} sm={3}>{label}</Form.Label>
            <Col>
                {readMode ? 
                    <Form.Control 
                        value={options.find((o) => {return o.value === field.value})?.label}
                        size={"sm"}
                        disabled
                        plaintext
                    />
                    :
                    <Select
                        placeholder={"Select..."}
                        options={options}
                        isSearchable
                        components={{DropdownIndicator, ClearIndicator}}
                        classNamePrefix={"react-select"}
                        className={classNames({'is-invalid': invalid})}
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: invalid ? "#e63757!important" : 'var(--falcon-input-border-color)'
                            })
                        }}
                        onChange={(e) => {
                            field.onChange(e.value)
                            onChange && onChange()
                        }}
                        value={{
                            value:field.value,
                            label:options.find((o) => {return o.value === field.value})?.label
                        }}
                        {...rest}
                    />
                    }
                {invalid && <Form.Control.Feedback type={"invalid"}>{error.message}</Form.Control.Feedback>}
            </Col>
        </Form.Group>
    )
}
HorizontalFormSelect.defaultProps = {
    readMode: false
}
HorizontalFormSelect.propTypes = {
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
    readMode: PropTypes.bool,
    validation: PropTypes.object
}
export default HorizontalFormSelect
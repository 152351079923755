export const getDecidedFromArrays = (added, changed) => {
    return added.filter(x => x.status !== "Undecided").length + changed.filter(x => x.status !== "Undecided").length
}
export const sortByShowsUp = ((a, b) => {return b.showsUp - a.showsUp})
export const createInitOptions = (possibleValues, currentValue, originalValue) => {
    const options = possibleValues.sort(sortByShowsUp)
        .map((pv) => {
            const label = pv.value === "" ? "<Blank>" : pv.value;  
            return {value: pv.value, label:`${label} (shows up: ${pv.showsUp} times)`}})
    
    if(originalValue && !possibleValues.some(o => o.value === originalValue)) {
        options.push({value: originalValue, label: `${originalValue} (Original Value)`});
    }
    
    if(!possibleValues.some(o => o.value === currentValue)) {
        options.unshift({value: currentValue, label: currentValue});
    }
    return options;
}
import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allAddressUseCodesDropdown} from "../../../relay/Fragments";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const NewOrganizationAddressModal = ({showModal, onClose, addressUseCodes, organizationId}) => {
    const mutation = graphql`
        mutation NewOrganizationAddressModalMutation($organizationId: ID!, $addressUseCodeId: ID!, $addressLine1: String!,
            $addressLine2: String!, $addressLine3: String!, $city: String!, $state: String!, $postalCode: String!,
            $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
            organization {
                createAddress(input: {organizationId: $organizationId, addressUseCodeId: $addressUseCodeId, addressLine1: $addressLine1,
                    addressLine2: $addressLine2, addressLine3: $addressLine3, city: $city, state: $state, postalCode: $postalCode,
                    effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                    organizationAddress {
                        id
                        organization {
                            id
                            addresses {
                                ...OrganizationAddresses_Addresses
                            }
                        }
                    }
                }
            }
        }`
    const addressUses = useFragment(allAddressUseCodesDropdown, addressUseCodes).map((a) => {return {value: a.id, label: a.display}});
    
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        organizationId: organizationId,
        addressUseCodeId: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3:'',
        city:'',
        state:'',
        postalCode:'',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    }
    const schema = yup.object({
        addressUseCodeId: yup.string().required("Use Code is required"),
        addressLine1: yup.string().required("Line 1 is required"),
        city: yup.string().required("City is required"),
        state: yup.string().length(2, "Please use the abbreviation for the state").required("State is required"),
        postalCode: yup.string().max(9).required("Postal Code is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    })
    
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)})
    
    const handleSubmit = (data) => {
        commitMutation({variables: {...data},
            onCompleted: (data) => {
                if(data.organization) {
                    toast.success("Address for Organization created")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"New Address"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={addressUses} controlId={"addressUseCodeId"} label={"Use Code"} />
            <HorizontalFormInput controlId={"addressLine1"} label={"Line 1"} />
            <HorizontalFormInput controlId={"addressLine2"} label={"Line 2"} />
            <HorizontalFormInput controlId={"addressLine3"} label={"Line 3"} />
            <HorizontalFormInput controlId={"city"} label={"City"} />
            <HorizontalFormInput controlId={"state"} label={"State"} />
            <HorizontalFormInput controlId={"postalCode"} label={"Postal Code"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
NewOrganizationAddressModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    addressUseCodes: PropTypes.array.isRequired,
    organizationId: PropTypes.string.isRequired
}
export default NewOrganizationAddressModal
import React from 'react';
import DetailsPageHeader from "../../../components/common/headers/DetailsPageHeader";
import {useLoaderData, useParams} from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {usePreloadedQuery} from "react-relay";
import ContractLineOfServiceInfo from "./ContractLineOfServiceInfo";

const ContractLineOfServiceDetails = () => {
    const loader = useLoaderData();
    const {lineOfServiceId} = useParams();
    const data = usePreloadedQuery(graphql`
        query ContractLineOfServiceDetailsQuery($id: ID!) {
            node(id: $id) {
                ... on LegalContract {
                    id
                    linesOfService {
                        id
                        lineOfService {
                            name
                        }
                        ...ContractLineOfServiceInfo_ContractLineOfService
                    }
                }
            }
        }
    `, loader.contractLineOfServiceDetailsQuery)
    const lineOfService = data.node.linesOfService.find((l) => {return l.id === lineOfServiceId});
    return (
        <>
            <DetailsPageHeader iconText={"Contract Line of Service"} iconType={"pen-nib"} title={lineOfService.lineOfService.name} />
            <ContractLineOfServiceInfo contractLineOfService={lineOfService} />
        </>
    )
}
export default ContractLineOfServiceDetails
import React from 'react';
import {useLoaderData} from 'react-router-dom';
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import OrganizationsTable from "./OrganizationsTable";
const Organizations = () => {
  const loader = useLoaderData();
  const data = usePreloadedQuery(
      graphql`
        query OrganizationsQuery {
          allOrganizations {
            ...OrganizationsTable_Organization
          }
            allNetworkOwners {
                ...NetworkOwnersFragments_AllNetworkOwnersDropdown
            }
        }
      `,loader.organizationsQuery)
  return (
    <OrganizationsTable organizations={data.allOrganizations} networkOwners={data.allNetworkOwners} />
  );
};

export default Organizations;

/**
 * @generated SignedSource<<f743a3ae19158e28fb9495da100048e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine1"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine2"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine3"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressUseCodeId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "billingGroupId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phoneNumber"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postalCode"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BillingGroupMutationGroup",
    "kind": "LinkedField",
    "name": "billingGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "addressLine1",
                "variableName": "addressLine1"
              },
              {
                "kind": "Variable",
                "name": "addressLine2",
                "variableName": "addressLine2"
              },
              {
                "kind": "Variable",
                "name": "addressLine3",
                "variableName": "addressLine3"
              },
              {
                "kind": "Variable",
                "name": "addressUseCodeId",
                "variableName": "addressUseCodeId"
              },
              {
                "kind": "Variable",
                "name": "billingGroupId",
                "variableName": "billingGroupId"
              },
              {
                "kind": "Variable",
                "name": "city",
                "variableName": "city"
              },
              {
                "kind": "Variable",
                "name": "effectiveFrom",
                "variableName": "effectiveFrom"
              },
              {
                "kind": "Variable",
                "name": "effectiveTo",
                "variableName": "effectiveTo"
              },
              {
                "kind": "Variable",
                "name": "phoneNumber",
                "variableName": "phoneNumber"
              },
              {
                "kind": "Variable",
                "name": "postalCode",
                "variableName": "postalCode"
              },
              {
                "kind": "Variable",
                "name": "state",
                "variableName": "state"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "BillingGroupAddressModifyPayload",
        "kind": "LinkedField",
        "name": "createAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingGroupAddress",
            "kind": "LinkedField",
            "name": "billingGroupAddress",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AddressUseCode",
                "kind": "LinkedField",
                "name": "addressUseCode",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "display",
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressLine3",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phoneNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveFrom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveTo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingGroup",
                "kind": "LinkedField",
                "name": "billingGroup",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingGroupAddress",
                    "kind": "LinkedField",
                    "name": "addresses",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewBillingGroupAddressModalMutation",
    "selections": (v12/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v10/*: any*/),
      (v9/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewBillingGroupAddressModalMutation",
    "selections": (v12/*: any*/)
  },
  "params": {
    "cacheID": "594df5a3e4c61a3f34136140982e5355",
    "id": null,
    "metadata": {},
    "name": "NewBillingGroupAddressModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewBillingGroupAddressModalMutation(\n  $billingGroupId: ID!\n  $addressUseCodeId: ID!\n  $addressLine1: String!\n  $addressLine2: String!\n  $addressLine3: String!\n  $city: String!\n  $state: String!\n  $postalCode: String!\n  $phoneNumber: String!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  billingGroup {\n    createAddress(input: {billingGroupId: $billingGroupId, addressUseCodeId: $addressUseCodeId, addressLine1: $addressLine1, addressLine2: $addressLine2, addressLine3: $addressLine3, city: $city, state: $state, postalCode: $postalCode, phoneNumber: $phoneNumber, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      billingGroupAddress {\n        id\n        addressUseCode {\n          display\n          id\n        }\n        addressLine1\n        addressLine2\n        addressLine3\n        city\n        state\n        postalCode\n        phoneNumber\n        effectiveFrom\n        effectiveTo\n        billingGroup {\n          id\n          addresses {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "0c5c97609ef23ff6f2017b0004cdd031";

export default node;

/**
 * @generated SignedSource<<669ddfa10a9b6dc21825b59c5cfdac10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "proposedValue"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "proposedValue",
        "variableName": "proposedValue"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingGroupTabPropertyMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RosterLoadMutationGroup",
        "kind": "LinkedField",
        "name": "rosterLoad",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "RosterLoadBillingGroupPropertyModifyPayload",
            "kind": "LinkedField",
            "name": "modifyRosterLoadBillingGroupProperty",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RosterLoadBillingGroupProperty",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RosterLoadBillingGroup",
                    "kind": "LinkedField",
                    "name": "billingGroup",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RosterLoadBillingGroupProperty",
                        "kind": "LinkedField",
                        "name": "properties",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "BillingGroupTabPropertyFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BillingGroupTabPropertyMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RosterLoadMutationGroup",
        "kind": "LinkedField",
        "name": "rosterLoad",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "RosterLoadBillingGroupPropertyModifyPayload",
            "kind": "LinkedField",
            "name": "modifyRosterLoadBillingGroupProperty",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RosterLoadBillingGroupProperty",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RosterLoadBillingGroup",
                    "kind": "LinkedField",
                    "name": "billingGroup",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RosterLoadBillingGroupProperty",
                        "kind": "LinkedField",
                        "name": "properties",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "propertyName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayOrder",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "required",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isKey",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "proposedValue",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "originalValue",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PossibleValue",
                            "kind": "LinkedField",
                            "name": "possibleValues",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "showsUp",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71abd2dca5fac08299256f8ea5ceda9b",
    "id": null,
    "metadata": {},
    "name": "BillingGroupTabPropertyMutation",
    "operationKind": "mutation",
    "text": "mutation BillingGroupTabPropertyMutation(\n  $id: ID!\n  $proposedValue: StringInput\n) {\n  rosterLoad {\n    modifyRosterLoadBillingGroupProperty(input: {id: $id, proposedValue: $proposedValue}) {\n      property {\n        id\n        billingGroup {\n          id\n          properties {\n            ...BillingGroupTabPropertyFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment BillingGroupTabPropertyFragment on RosterLoadBillingGroupProperty {\n  id\n  propertyName\n  displayOrder\n  required\n  isKey\n  proposedValue\n  originalValue\n  possibleValues {\n    showsUp\n    value\n  }\n}\n"
  }
};
})();

node.hash = "e9adb774aafbda4d50d87032edbe0ec0";

export default node;

/**
 * @generated SignedSource<<a2e891723039f81d43d66da0d5a7d85a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkOwnerInfo_NetworkOwner",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "NetworkOwner",
  "abstractKey": null
};

node.hash = "0c6737ea93cc5d55cc8249985ce2ec53";

export default node;

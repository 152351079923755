import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Form} from 'react-bootstrap';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allRolesDropdown} from "../roles/RolesFragments";
import {FormProvider, useForm} from "react-hook-form";
import InfoPanel from "../../components/common/repeated-page-panels/InfoPanel";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import {useUser} from "../../hooks/useUser";
import {toast} from "react-toastify";
import HorizontalFormMaskedInput from "../../components/common/horizontal-form-inputs/HorizontalFormMaskedInput";

const UserInfo = ({user, roles}) => {
    const userMutation = graphql`
        mutation UserInfoMutation($id: ID!, $userName:String!, $password:String, $firstName: String, $lastName: String,
            $email: String!, $phoneNumber: String!, $externalLoginId: String, $roleIds: [ID!]!) {
            user {
                modify(input: {userId: $id, userName: $userName, password: $password, firstName: $firstName, 
                    lastName: $lastName, email: $email, phoneNumber: $phoneNumber, externalLoginId: $externalLoginId,
                    roleIds: $roleIds}) {
                    user {
                        ...UserInfo_User
                    }
                }
            }
        }
    `
    const userInfo = useFragment(graphql`
        fragment UserInfo_User on User {
            id,
            userName,
            firstName,
            lastName,
            email,
            phoneNumber,
            externalLoginId
            roles {
                id
            }
        }
    `, user);
    const allRoles = useFragment(allRolesDropdown, roles).map((r) => {return {value: r.id, label: r.name}});
    
    const [readMode, setReadMode] = useState(true);
    const [commitMutation, isInFlight] = useMutation(userMutation);
    const methods = useForm({values: {
            userName:userInfo.userName,
            password: null,
            email:userInfo.email,
            phoneNumber: userInfo.phoneNumber, 
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            roleId: userInfo.roles[0]?.id,
            externalLoginId: userInfo.externalLoginId,
    }});
    const {hasPermission} = useUser();
    
    const onSubmit = (data) => {
        commitMutation( {
            variables: {
                id: userInfo.id,
                userName: data.userName,
                password: data.password,
                email: data.email,
                phoneNumber: data.phoneNumber,
                firstName: data.firstName,
                lastName: data.lastName,
                roleIds: [data.roleId],
                externalLoginId: data.externalLoginId
            },
            onCompleted: (data) => {
                toast.success("User has been updated");
                methods.resetField("password", {defaultValue: null});
                setReadMode(true);
            }
        })
    }
    const handleCancelChanges = () => {
        methods.reset();
        setReadMode(true);
    }

    return (
        <FormProvider {...methods}>
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <InfoPanel onUpdateDetailsClick={() => {setReadMode(false)}} 
                           onCancelChangesClick={handleCancelChanges} 
                           name={userInfo.userName} 
                           isInFlight={isInFlight} 
                           readMode={readMode}
                           showUpdate={hasPermission("Users.Update")}>
                    <HorizontalFormInput controlId={"userName"} 
                                         label={"Username"}
                                         readMode={readMode}
                                         validation={{required: "Username is required"}}
                    />
                    <HorizontalFormInput controlId={"password"} 
                                         label={"Password"} 
                                         readMode={readMode} 
                                         type={"password"} 
                    />
                    <HorizontalFormInput controlId={"firstName"} label={"First Name"} readMode={readMode} />
                    <HorizontalFormInput controlId={"lastName"} label={"Last Name"} readMode={readMode} />
                    <HorizontalFormInput controlId={"email"} 
                                         label={"Email"} 
                                         readMode={readMode}
                                         validation={{required: "Email is required"}}
                                         type={"email"}
                    />
                    <HorizontalFormMaskedInput controlId={"phoneNumber"}
                                               label={"Phone Number"}
                                               readMode={readMode}
                                               mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                               placeholder={"(XXX) XXX-XXXX"}
                                               validation={{required:"Phone number is required"}}
                                               type={"tel"}
                    />
                    <HorizontalFormSelect options={allRoles}
                                          controlId={"roleId"}
                                          label={"Role"}
                                          readMode={readMode}
                                          validation={{required: "Role is required"}}
                    />
                    <HorizontalFormInput controlId={"externalLoginId"} label={"External Login Id"} readMode={readMode} />
                </InfoPanel>
            </Form>
        </FormProvider>
    );
};
UserInfo.propTypes = {
    user: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired
}
export default UserInfo;

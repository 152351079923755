/**
 * @generated SignedSource<<976c7020037857157c687902643c509c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Fragments_AllAttachmentCategoriesDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOrder",
      "storageKey": null
    }
  ],
  "type": "AttachmentCategory",
  "abstractKey": null
};

node.hash = "20533967cdb2af5918cd8fb89e034431";

export default node;

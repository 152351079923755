import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useFragment, useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import InfoPanel from "../../../components/common/repeated-page-panels/InfoPanel";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import HorizontalFormCheck from "../../../components/common/horizontal-form-inputs/HorizontalFormCheck";
import {toast} from "react-toastify";
import {useUser} from "../../../hooks/useUser";

const ContractLineOfServiceInfo = ({contractLineOfService}) => {
    const mutation = graphql`
        mutation ContractLineOfServiceInfo_Mutation($id: ID!, $percentageOfBase: DecimalInput,
        $otherServicesDiscountPercentage: DecimalInput, $isComplex: Boolean, $feeScheduleDescription: String, $effectiveFrom: DateOnly, $effectiveTo: DateOnly) {
            legalContract {
                modifyLineOfServiceCurrentVersion(input:{id: $id, percentageOfBase: $percentageOfBase, otherServicesDiscountPercentage: $otherServicesDiscountPercentage
                isComplex: $isComplex, feeScheduleDescription: $feeScheduleDescription, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo})
                {
                    legalContractLineOfService {
                        ...ContractLineOfServiceInfo_ContractLineOfService
                    }
                }
            }
        }
    `
    const contractLineOfServiceInfo = useFragment(graphql`
        fragment ContractLineOfServiceInfo_ContractLineOfService on LegalContractLineOfService {
            id
            contract {
                startDate
                endDate
            }
            lineOfService {
                name
            }
            isComplex
            percentageOfBase
            otherServicesDiscountPercentage
            effectiveFrom
            effectiveTo
            feeScheduleDescription
        }
    `,contractLineOfService)
    const contractStartDate = dayjs(contractLineOfServiceInfo.contract.startDate).toDate()
    const contractEndDate = dayjs(contractLineOfServiceInfo.contract.endDate).toDate()
    const [readMode, setReadMode] = useState(true)
    const defaultValues = {
        isComplex: contractLineOfServiceInfo.isComplex,
        percentageOfBase: contractLineOfServiceInfo.percentageOfBase,
        otherServicesDiscountPercentage: contractLineOfServiceInfo.otherServicesDiscountPercentage,
        effectiveFrom: dayjs(contractLineOfServiceInfo.effectiveFrom).toDate(),
        effectiveTo: dayjs(contractLineOfServiceInfo.effectiveTo).toDate(),
        feeScheduleDescription: contractLineOfServiceInfo.feeScheduleDescription
    }
    const schema = yup.object({
        percentageOfBase: yup.number().nullable(),
        otherServicesDiscountPercentage: yup.number().nullable(),
        effectiveFrom: yup.date().required("Effective From is required")
            .min(contractStartDate, "Effective From cannot be before the contract starts")
            .when('effectiveTo', (effectiveTo) => {
                if(effectiveTo) {
                    return yup.date().max(effectiveTo, "Effective From must be before Effective To")
                }
                return yup.date().max(contractEndDate, "Effective From cannot be after the contract ends")
            }),
        effectiveTo: yup.date().required("Effective To is required")
            .min(contractStartDate, "Effective To cannot be before the contract starts")
            .max(contractEndDate, "Effective To cannot be after the contract ends")
    })
    const methods = useForm({values: defaultValues, resolver: yupResolver(schema)})
    const [commitMutation, isInFlight] = useMutation(mutation);
    const {hasPermission} = useUser();
    const onSubmit = (data) => {
        commitMutation({variables: {id: contractLineOfServiceInfo.id, ...data}, 
            onCompleted: (data) => {
            toast.success("Contract Line of Service was updated");
            setReadMode(true);
        }})
    }
    const handleCancelClick = () => {
        methods.reset();
        setReadMode(true);
    }
    return (
        <FormProvider {...methods}>
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <InfoPanel onUpdateDetailsClick={() => {setReadMode(false)}} 
                           onCancelChangesClick={handleCancelClick} 
                           name={contractLineOfServiceInfo.lineOfService.name} 
                           isInFlight={isInFlight} 
                           readMode={readMode}
                           showUpdate={hasPermission("LegalContractLinesOfService.Update")}
                >
                    <HorizontalFormDate controlId={"effectiveFrom"} 
                                        label={"Effective From"}
                                        readMode={readMode}
                    />
                    <HorizontalFormDate controlId={"effectiveTo"} 
                                        label={"Effective To"} 
                                        readMode={readMode}
                    />
                    <HorizontalFormInput controlId={"percentageOfBase"}
                                         label={"Percentage of Base"}
                                         readMode={readMode}
                                         registerOptions={{valueAsNumber: true}}
                                         type={"number"}
                                         step={"0.0001"}
                    />
                    <HorizontalFormInput controlId={"otherServicesDiscountPercentage"}
                                         label={"Other Services Discount Percentage"}
                                         readMode={readMode}
                                         registerOptions={{valueAsNumber: true}}
                                         type={"number"}
                                         step={"0.0001"}
                    />
                    <HorizontalFormCheck controlId={"isComplex"} 
                                         label={"Is Complex"}
                                         readMode={readMode}
                    />
                    <HorizontalFormInput controlId={"feeScheduleDescription"} label={"Fee Schedule Description"} 
                                         readMode={readMode}
                                         as={"textarea"}
                                         rows={5}
                    />
                </InfoPanel>
            </Form>
        </FormProvider>
    )
}
ContractLineOfServiceInfo.propTypes = {
    contractLineOfService: PropTypes.object.isRequired
}
export default ContractLineOfServiceInfo
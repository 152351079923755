import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useForm} from "react-hook-form";
import NewModal from "../../components/common/modals/NewModal";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";

const NewUserModal = ({showModal, onClose}) => {
    const NewUserMutation = graphql`
        mutation NewUserModalMutation($userName: String!, $email: String!) {
            user {
                create(input: {email: $email, userName: $userName}) {
                    user {
                        id
                    }
                }
            }
        }`
    const methods = useForm({defaultValues: {email: '', username: ''}})
    return (
        <NewModal showModal={showModal} 
                  title={"New User"}
                  onClose={onClose}
                  mutation={NewUserMutation}
                  methods={methods}
                  returnedIdKey={"user.create.user.id"}
                  redirectUrl={"/users/"}
        >
            <HorizontalFormInput controlId={"userName"} label={"Username"} validation={{required: "Username is required"}} />
            <HorizontalFormInput controlId={"email"} label={"Email"} validation={{required: "Email is required"}} type={"email"} />
        </NewModal>
    )
}
NewUserModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}
export default NewUserModal
import React, {useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap'
import CreatableSelect from "react-select/creatable";
import PropTypes from 'prop-types';
import {useController} from "react-hook-form";
import classNames from "classnames";
import DropdownIndicator from "../../../components/common/horizontal-form-inputs/DropdownIndicator";
import {useMutation} from "react-relay";
import ClearIndicator from "../../../components/common/horizontal-form-inputs/ClearIndicator";
import {usePropertyField} from "./usePropertyField";
import Select from "react-select";

const AddField = ({property, controlId, status, mutation}) => {
    const {field, invalid, error, options, isInFlight, handleOnChange, handleOnCreate} = usePropertyField(controlId, property, mutation)
    return (
        <Form.Group as={Row} className={"mb-1"} controlId={controlId + "." + property.propertyName}>
            <Form.Label column xs={7} sm={3}>{property.propertyName}</Form.Label>
            <Col>{status !== "Undecided" || property.isKey ?
                <Form.Control
                    value={field.value}
                    size={"sm"}
                    disabled
                    plaintext
                />
                :
                <CreatableSelect
                    placeholder={"Select..."}
                    options={options}
                    isSearchable
                    isClearable
                    components={{ClearIndicator, DropdownIndicator}}
                    className={classNames({'is-invalid': invalid})}
                    classNamePrefix={"react-select"}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: invalid ? "#e63757!important" : 'var(--falcon-input-border-color)'
                        })
                    }}
                    onChange={(e) => {
                        handleOnChange(e)
                    }}
                    isLoading={isInFlight}
                    onCreateOption={handleOnCreate}
                    value={{
                        value:field.value,
                        label:options.find((o) => {return o.value === field.value})?.label
                    }}
                />}
                {invalid && <Form.Control.Feedback type={"invalid"}>{error.message}</Form.Control.Feedback>}
            </Col>
        </Form.Group>
    )
}
AddField.defaultProps = {}
AddField.propTypes = {}
export default AddField
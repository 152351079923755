import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {LinkCell} from "../../components/common/advance-table/CustomCells";
import {usePreloadedQuery} from "react-relay";
import {useLoaderData} from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {useUser} from "../../hooks/useUser";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";
import NewBillingGroupModal from "./NewBillingGroupModal";

const cols = [
    {
        accessor: 'name',
        Header: 'Name',
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const {name, id } = rowData.row.original
            return <LinkCell name={name} to={"/billing-groups/" + id} hasAvatar />
        }
    },
    {
        accessor:'legalContract.name',
        Header: 'Contract',
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const {legalContract} = rowData.row.original;
            return <LinkCell name={legalContract.name} to={"/contracts/" + legalContract.id} />
        }
    }
]
const BillingGroups = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(
        graphql` query BillingGroupsQuery {
            allBillingGroups {
                id
                name
                legalContract {
                    id
                    name
                }
            }
            allLegalContracts {
                ...ContractFragments_AllContractsDropdown
            }
        }`, loader.billingGroupQuery)
    const {hasPermission} = useUser();
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <AdvanceTableWrapper
                columns={cols}
                data={data.allBillingGroups}
                sortable
                pagination
                perPage={25}
                >
                <Card className="mb-3">
                    <Card.Header>
                        <IndexTableHeader name={"Billing Groups"}
                                          onNewClick={() => {setShowModal(true)}}
                                          showNew={hasPermission("BillingGroups.Create")}
                                          table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter rowCount={data.allBillingGroups.length}
                                            table
                                            rowInfo
                                            navButtons
                                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewBillingGroupModal showModal={showModal} 
                                  onClose={() => {setShowModal(false)}} 
                                  contracts={data.allLegalContracts} 
            />
        </>
    )
}
BillingGroups.defaultProps = {}
BillingGroups.propTypes = {}
export default BillingGroups
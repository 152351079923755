import React from 'react';
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import {useFragment} from "react-relay";
import {linesOfServiceDropdown} from "../../lines-of-service/LinesOfServiceFragments";
import graphql from "babel-plugin-relay/macro";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import NewModal from "../../../components/common/modals/NewModal";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import dayjs from "dayjs";

const NewContractLinesOfServiceModal = ({showModal, onClose, possibleLinesOfService, contractId, contractStartDate, contractEndDate}) => {
    const mutation = graphql`
        mutation NewContractLinesOfServiceModal_Mutation($contractId: ID!, $lineOfServiceId: ID!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
            legalContract {
                addLineOfService(input: {legalContractId: $contractId, lineOfServiceId: $lineOfServiceId, 
                    effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo, isComplex: "false", feeScheduleDescription: ""}) {
                    legalContractLineOfService {
                        id
                        contract {
                            id
                            linesOfService {
                                ...ContractLinesOfServiceFragments_LinesOfService
                            }
                        }
                    }
                }
            }
        }`
    const contractStart = dayjs(contractStartDate).toDate();
    const contractEnd = dayjs(contractEndDate).toDate();
    const los = possibleLinesOfService.map((l => { return {value: l.id, label:l.name}}));
    const schema = yup.object({
        lineOfServiceId: yup.string().required("Line of Service is required"),
        effectiveFrom: yup.date().required("Effective From is required")
            .min(contractStart, "Effective From cannot be before the contract starts")
            .when('effectiveTo', (effectiveTo) => {
                if(effectiveTo) {
                    return yup.date().max(effectiveTo, "Effective From cannot be after Effective To")
                }
                return yup.date().max(contractEnd, "Effective From cannot be after the contract ends")
            }),
        effectiveTo: yup.date().required("Effective To is required")
            .min(contractStart, "Effective To cannot be before the contract starts")
            .max(contractEnd, "Effective To cannot be after the contracts end")
    })
    const methods = useForm({defaultValues: {contractId: contractId, effectiveFrom: contractStart, effectiveTo: contractEnd}, resolver: yupResolver(schema)});
    return (
        <NewModal showModal={showModal} 
                  onClose={onClose}
                  title={"New Line of Service for Contract"}
                  redirectUrl={"/contracts/" + contractId + "/lines-of-service/"}
                  mutation={mutation}
                  returnedIdKey={"legalContract.addLineOfService.legalContractLineOfService.id"}
                  methods={methods}
        >
            <HorizontalFormSelect options={los}
                                  controlId={"lineOfServiceId"}
                                  label={"Line of Service"}
            />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </NewModal>
    )
}
NewContractLinesOfServiceModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    possibleLinesOfService: PropTypes.array.isRequired,
    contractId: PropTypes.string.isRequired,
    contractStartDate: PropTypes.string.isRequired,
    contractEndDate: PropTypes.string.isRequired
}
export default NewContractLinesOfServiceModal
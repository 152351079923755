/**
 * @generated SignedSource<<a2292775a2b03028ecffa14fb45fa640>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "billingGroupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identifierTypeId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identifierUseCodeId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "billingGroupId",
        "variableName": "billingGroupId"
      },
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "identifierTypeId",
        "variableName": "identifierTypeId"
      },
      {
        "kind": "Variable",
        "name": "identifierUseCodeId",
        "variableName": "identifierUseCodeId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "display",
    "storageKey": null
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewBillingGroupIdentifierModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingGroupMutationGroup",
        "kind": "LinkedField",
        "name": "billingGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "BillingGroupIdentifierModifyPayload",
            "kind": "LinkedField",
            "name": "createIdentifier",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingGroupIdentifier",
                "kind": "LinkedField",
                "name": "billingGroupIdentifier",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingGroup",
                    "kind": "LinkedField",
                    "name": "billingGroup",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BillingGroupIdentifier",
                        "kind": "LinkedField",
                        "name": "identifiers",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "BillingGroupIdentifiers_Identifiers"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewBillingGroupIdentifierModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingGroupMutationGroup",
        "kind": "LinkedField",
        "name": "billingGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "BillingGroupIdentifierModifyPayload",
            "kind": "LinkedField",
            "name": "createIdentifier",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingGroupIdentifier",
                "kind": "LinkedField",
                "name": "billingGroupIdentifier",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingGroup",
                    "kind": "LinkedField",
                    "name": "billingGroup",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BillingGroupIdentifier",
                        "kind": "LinkedField",
                        "name": "identifiers",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "IdentifierType",
                            "kind": "LinkedField",
                            "name": "identifierType",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "IdentifierUseCode",
                            "kind": "LinkedField",
                            "name": "identifierUseCode",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "89c817ade910642885ec679cd532b986",
    "id": null,
    "metadata": {},
    "name": "NewBillingGroupIdentifierModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewBillingGroupIdentifierModalMutation(\n  $billingGroupId: ID!\n  $identifierTypeId: ID!\n  $identifierUseCodeId: ID!\n  $value: String!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  billingGroup {\n    createIdentifier(input: {billingGroupId: $billingGroupId, identifierTypeId: $identifierTypeId, identifierUseCodeId: $identifierUseCodeId, value: $value, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      billingGroupIdentifier {\n        id\n        billingGroup {\n          id\n          identifiers {\n            ...BillingGroupIdentifiers_Identifiers\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment BillingGroupIdentifiers_Identifiers on BillingGroupIdentifier {\n  id\n  identifierType {\n    display\n    id\n  }\n  identifierUseCode {\n    display\n    id\n  }\n  value\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "faac8d82a5dda858c5166854be319e31";

export default node;

import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import {useMutation} from "react-relay";
import FormModal from "./FormModal";

const NewModal = ({showModal, onClose, redirectUrl, returnedIdKey, mutation, title, methods, children}) => {
    const [commitMutation, isInFlight] = useMutation(mutation);
    const navigate = useNavigate();
    const onSubmit = (data) => {
        commitMutation({variables: {...data},
        onCompleted: (data) => {
            const id = getIdFromData(data);
            navigate(redirectUrl + id);
        }})
    }
    
    const getIdFromData = (data) => {
        const keys = returnedIdKey.split(".");
        const targetKey = keys.pop();
        let current = data;
        for(let i = 0; i < keys.length; i++) {
            current = current[keys[i]];
            if(!current) {
                throw new Error("Key not found: " + keys[i]);
            }
        }
        return current[targetKey];
    }
    return (
        <FormModal showModal={showModal} 
                   onClose={onClose}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={onSubmit}
                   title={title}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            {children}
        </FormModal>
    )
}
NewModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    returnedIdKey: PropTypes.string.isRequired,
    mutation: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.element)
}
export default NewModal
/**
 * @generated SignedSource<<b14af55fb08249fb7211e88f4b0058bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "legalContractId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "legalContractId",
        "variableName": "legalContractId"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingGroupInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingGroupMutationGroup",
        "kind": "LinkedField",
        "name": "billingGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "BillingGroupModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingGroup",
                "kind": "LinkedField",
                "name": "billingGroup",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BillingGroupInfo_BillingGroup"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BillingGroupInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingGroupMutationGroup",
        "kind": "LinkedField",
        "name": "billingGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "BillingGroupModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingGroup",
                "kind": "LinkedField",
                "name": "billingGroup",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContract",
                    "kind": "LinkedField",
                    "name": "legalContract",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67e030aad6ee8c2d0484e9e41066526b",
    "id": null,
    "metadata": {},
    "name": "BillingGroupInfoMutation",
    "operationKind": "mutation",
    "text": "mutation BillingGroupInfoMutation(\n  $id: ID!\n  $name: String!\n  $legalContractId: ID!\n) {\n  billingGroup {\n    modify(input: {id: $id, name: $name, legalContractId: $legalContractId}) {\n      billingGroup {\n        ...BillingGroupInfo_BillingGroup\n        id\n      }\n    }\n  }\n}\n\nfragment BillingGroupInfo_BillingGroup on BillingGroup {\n  id\n  name\n  legalContract {\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "e5ec7293cf89dc36553cc9966ec74f70";

export default node;

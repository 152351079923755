import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import NewOrganizationContactModal from "./NewOrganizationContactModal";
import {DateCell, LinkCell} from "../../../components/common/advance-table/CustomCells";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import {useUser} from "../../../hooks/useUser";

const defaultColumns = [
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { lastName, firstName, id } = rowData.row.original;
            return (
                <LinkCell name={lastName + ", " + firstName} to={"contact/"+id} />
            );
        }
    },
    {
        accessor: 'effectiveFrom',
        Header: 'Effective From',
        Cell: DateCell
    },
    {
        accessor: 'effectiveTo',
        Header: 'Effective To',
        Cell: DateCell
    },
];

const OrganizationContacts = ({contacts, organizationId}) => {
    const data = useFragment(
        graphql`
            fragment OrganizationContacts_Contacts on OrganizationContact @relay(plural: true) {
                id,
                lastName,
                firstName,
                effectiveFrom,
                effectiveTo
                organization {
                    id
                }
            }
    `, contacts)
    const [showModal, setShowModal] = useState(false);
    const {hasPermission} = useUser();
    return (
        <>
            <AdvanceTableWrapper
                columns={defaultColumns}
                data={data}
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CardTableHeader title={"Contacts"} 
                                         onNewClick={() => {setShowModal(true)}}
                                         showNew={hasPermission("OrganizationContacts.Create")}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewOrganizationContactModal showModal={showModal} 
                                         onHide={() => {setShowModal(false)}} 
                                         organizationId={organizationId}
            />
        </>
    );
};
OrganizationContacts.propTypes = {
    contacts: PropTypes.array.isRequired,
    organizationId: PropTypes.string.isRequired,
}
export default OrganizationContacts;

/**
 * @generated SignedSource<<ba72c7e8935c11a36ae01851ab48fc35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RoleInfo_role"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RolePermissions_rolePermission"
              }
            ],
            "type": "Role",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Permission",
        "kind": "LinkedField",
        "name": "allPermissions",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RolePermissions_activePermission"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Permission",
                "kind": "LinkedField",
                "name": "permissions",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Role",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Permission",
        "kind": "LinkedField",
        "name": "allPermissions",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1bbfb229dd597edd147a1a364bd7ddfc",
    "id": null,
    "metadata": {},
    "name": "RoleDetailsQuery",
    "operationKind": "query",
    "text": "query RoleDetailsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Role {\n      name\n      ...RoleInfo_role\n      ...RolePermissions_rolePermission\n    }\n    id\n  }\n  allPermissions {\n    ...RolePermissions_activePermission\n    id\n  }\n}\n\nfragment RoleInfo_role on Role {\n  id\n  name\n  description\n}\n\nfragment RolePermissions_activePermission on Permission {\n  id\n  name\n}\n\nfragment RolePermissions_rolePermission on Role {\n  id\n  permissions {\n    id\n    name\n  }\n}\n"
  }
};
})();

node.hash = "e92b6dcd2ec7cdf42bf3485e076134b8";

export default node;

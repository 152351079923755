import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import {networkInfo} from 'data/networks/networkDetailData';
import classNames from 'classnames';

const PractitionerInfo = (props) => {


    return (
        <Card className="mb-3">
            <Card.Header>
                <Row className="align-items-center">
                    <Col>
                        <h5 className="mb-0">Identifiers</h5>
                    </Col>
                    <Col xs="auto">
                        <IconButton
                            iconClassName="fs--2 me-1"
                            variant="falcon-default"
                            size="sm"
                            icon="pencil-alt"
                        >
                            Update details
                        </IconButton>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="bg-light border-top">
                <Row>
                    <Col lg xxl={5}>
                        {
                            props.identifiers.map((identifier) => (
                                <Row key={identifier.type}>
                                    <Col xs={7} sm={6}>
                                        <p className="fw-semi-bold mb-1">{identifier.type}</p>
                                    </Col>
                                    <Col>
                                        <p className={"mb-1"}>
                                            {identifier.value}
                                        </p>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="border-top text-end">
                <IconButton
                    iconClassName="fs--2 me-1"
                    variant="falcon-default"
                    size="sm"
                    icon="book"
                >
                    History
                </IconButton>
                {/*<IconButton*/}
                {/*  className="ms-2"*/}
                {/*  iconClassName="fs--2 me-1"*/}
                {/*  variant="falcon-default"*/}
                {/*  size="sm"*/}
                {/*  icon="check"*/}
                {/*>*/}
                {/*  Save changes*/}
                {/*</IconButton>*/}
            </Card.Footer>
        </Card>
    );
};

export default PractitionerInfo;

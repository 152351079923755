import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "react-bootstrap";
import IconButton from "../IconButton";

const CardTableHeader = ({onNewClick, title, showNew}) => {
    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{title}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <div id="orders-actions">
                    {showNew &&
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        onClick={onNewClick}
                    >
                        <span className="d-none d-sm-inline-block ms-1">New</span>
                    </IconButton>
                    }
                </div>
            </Col>
        </Row>
    )
}
CardTableHeader.defaultProps = {
    showNew: true
}
CardTableHeader.propTypes = {
    onNewClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    showNew: PropTypes.bool
}
export default CardTableHeader
import React from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {useController} from "react-hook-form";
import dayjs from "dayjs";

const HorizontalFormDate = ({controlId, label, readMode, validation, ...rest}) => {
    const {field, fieldState: {invalid, error}} = useController(
        {name: controlId,
        rules: validation}
    )
    let className = "form-control form-control-sm"
    if(invalid) {
        className += " is-invalid";
    }
    return (
        <Form.Group as={Row} className={"mb-1"} controlId={controlId}>
            <Form.Label column xs={7} sm={3}>{label}</Form.Label>
            <Col>
                <div className={invalid ? "is-invalid" : ""}>
                {readMode ?
                    <Form.Control
                        value={dayjs(field.value).format("MM/DD/YYYY")}
                        size={"sm"}
                        disabled
                        plaintext
                    />
                    :
                    <DatePicker
                        dateFormat={"MM/dd/yyyy"}
                        className={className}
                        placeholderText={"Select Date"}
                        onChange={field.onChange}
                        selected={field.value}
                        {...rest}
                    />
                }
                </div>  
                {invalid && <Form.Control.Feedback type={"invalid"}>{error.message}</Form.Control.Feedback>}
            </Col>  
        </Form.Group>
    )
}
HorizontalFormDate.defaultProps = {
    readMode: false
}
HorizontalFormDate.propTypes = {
    ...DatePicker.propTypes,
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    readMode: PropTypes.bool,
    validation: PropTypes.object
    
}
export default HorizontalFormDate
import React, {useState} from 'react';
import {Form, Card, Row, Col} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';

const PractitionerInfo = (props) => {
    const [readMode, setReadMode] = useState(true);

    return (
        <Card className="mb-3">
            <Card.Header>
                <Row className="align-items-center">
                    <Col>
                        <h5 className="mb-0">{props.practitioner.last_name}, {props.practitioner.first_name}</h5>
                    </Col>
                    <Col xs="auto">
                        <IconButton
                            iconClassName="fs--2 me-1"
                            variant="falcon-default"
                            size="sm"
                            icon={readMode ? "pencil-alt" : "trash-alt"}
                            onClick={() => setReadMode(!readMode)}
                        >
                            {readMode ? 'Update details' : 'Cancel changes'}
                        </IconButton>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="bg-light border-top">
                <Form>
                    <Row>
                        <Col lg xxl={5}>
                            <Row key="FirstName">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">First Name</p>
                                </Col>
                                <Col>
                                    <Form.Control type={"text"} size={"sm"} readOnly={readMode} plaintext={readMode}
                                                  defaultValue={props.practitioner.first_name}/>
                                </Col>
                            </Row>
                            <Row key="MiddleInit">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">Middle Initial</p>
                                </Col>
                                <Col>
                                    <Form.Control type={"text"} size={"sm"} readOnly={readMode} plaintext={readMode}
                                                  defaultValue={""}/>
                                </Col>
                            </Row>
                            <Row key="LastName">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">Last Name</p>
                                </Col>
                                <Col>
                                    <Form.Control type={"text"} size={"sm"} readOnly={readMode} plaintext={readMode}
                                                  defaultValue={props.practitioner.last_name}/>
                                </Col>
                            </Row>
                            <Row key="Suffix">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">Suffix</p>
                                </Col>
                                <Col>
                                    <Form.Control type={"text"} size={"sm"} readOnly={readMode} plaintext={readMode}
                                                  defaultValue={props.practitioner.suffix}/>
                                </Col>
                            </Row>
                            <Row key="qual">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">Professional Designation</p>
                                </Col>
                                <Col>
                                    <Form.Control type={"text"} size={"sm"} readOnly={readMode} plaintext={readMode}
                                                  defaultValue={props.practitioner.qualification}/>
                                </Col>
                            </Row>
                            <Row key="gender">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">Gender</p>
                                </Col>
                                <Col>
                                    <Form.Control type={"text"} size={"sm"} readOnly={readMode} plaintext={readMode}
                                                  defaultValue={props.practitioner.gender}/>
                                </Col>
                            </Row>
                            <Row key="dob">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">Date of Birth</p>
                                </Col>
                                <Col>
                                    <Form.Control type={"text"} size={"sm"} readOnly={readMode} plaintext={readMode}
                                                  defaultValue={props.practitioner.birthdate}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg xxl={{span: 5, offset: 1}} className="mt-4 mt-lg-0">
                            <Row key="Ethnicity">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">Ethnicity</p>
                                </Col>
                                <Col>
                                    <p className={"mb-1"}>{props.practitioner.ethnicity}</p>
                                </Col>
                            </Row>
                            <Row key="Language1">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">Language 1</p>
                                </Col>
                                <Col>
                                    <Form.Control type={"text"} size={"sm"} readOnly={readMode} plaintext={readMode}
                                                  defaultValue={props.practitioner.languages[0]}/>
                                </Col>
                            </Row>
                            <Row key="Language2">
                                <Col xs={7} sm={6}>
                                    <p className="fw-semi-bold mb-1">Language 2</p>
                                </Col>
                                <Col>
                                    <Form.Control type={"text"} size={"sm"} readOnly={readMode} plaintext={readMode}
                                                  defaultValue={props.practitioner.languages[1]}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
            <Card.Footer className="border-top text-end">
                {/*<IconButton*/}
                {/*  iconClassName="fs--2 me-1"*/}
                {/*  variant="falcon-default"*/}
                {/*  size="sm"*/}
                {/*  icon="dollar-sign"*/}
                {/*>*/}
                {/*  Refund*/}
                {/*</IconButton>*/}
                <IconButton
                    className="ms-2"
                    iconClassName="fs--2 me-1"
                    variant="falcon-default"
                    size="sm"
                    icon="check"
                >
                    Save changes
                </IconButton>
            </Card.Footer>
        </Card>
    );
};

export default PractitionerInfo;

/**
 * @generated SignedSource<<6185e8f3257b58ea5e0a77ce70c8feef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "effectiveFrom",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "effectiveTo",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v9 = [
  (v2/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingGroupDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BillingGroupInfo_BillingGroup"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingGroupIdentifier",
                "kind": "LinkedField",
                "name": "identifiers",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BillingGroupIdentifiers_Identifiers"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingGroupAddress",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BillingGroupAddress_Address"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "BillingGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContractFragments_AllContractsDropdown"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "IdentifierType",
        "kind": "LinkedField",
        "name": "allIdentifierTypes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Fragments_AllIdentifierTypes"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "IdentifierUseCode",
        "kind": "LinkedField",
        "name": "allIdentifierUseCodes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Fragments_AllIdentifierUseCodes"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AddressUseCode",
        "kind": "LinkedField",
        "name": "allAddressUseCodes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Fragments_AllAddressUseCodesDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BillingGroupDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContract",
                "kind": "LinkedField",
                "name": "legalContract",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingGroupIdentifier",
                "kind": "LinkedField",
                "name": "identifiers",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IdentifierType",
                    "kind": "LinkedField",
                    "name": "identifierType",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IdentifierUseCode",
                    "kind": "LinkedField",
                    "name": "identifierUseCode",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingGroupAddress",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddressUseCode",
                    "kind": "LinkedField",
                    "name": "addressUseCode",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressLine1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressLine2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addressLine3",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "County",
                    "kind": "LinkedField",
                    "name": "county",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "BillingGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "IdentifierType",
        "kind": "LinkedField",
        "name": "allIdentifierTypes",
        "plural": true,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "IdentifierUseCode",
        "kind": "LinkedField",
        "name": "allIdentifierUseCodes",
        "plural": true,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AddressUseCode",
        "kind": "LinkedField",
        "name": "allAddressUseCodes",
        "plural": true,
        "selections": (v9/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b166747fd7a4544a21f67a00dc048307",
    "id": null,
    "metadata": {},
    "name": "BillingGroupDetailsQuery",
    "operationKind": "query",
    "text": "query BillingGroupDetailsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on BillingGroup {\n      name\n      ...BillingGroupInfo_BillingGroup\n      identifiers {\n        ...BillingGroupIdentifiers_Identifiers\n        id\n      }\n      addresses {\n        ...BillingGroupAddress_Address\n        id\n      }\n    }\n  }\n  allLegalContracts {\n    ...ContractFragments_AllContractsDropdown\n    id\n  }\n  allIdentifierTypes {\n    ...Fragments_AllIdentifierTypes\n    id\n  }\n  allIdentifierUseCodes {\n    ...Fragments_AllIdentifierUseCodes\n    id\n  }\n  allAddressUseCodes {\n    ...Fragments_AllAddressUseCodesDropdown\n    id\n  }\n}\n\nfragment BillingGroupAddress_Address on BillingGroupAddress {\n  id\n  addressUseCode {\n    display\n    id\n  }\n  addressLine1\n  addressLine2\n  addressLine3\n  city\n  state\n  postalCode\n  county {\n    id\n    name\n    state\n  }\n  phoneNumber\n  effectiveFrom\n  effectiveTo\n}\n\nfragment BillingGroupIdentifiers_Identifiers on BillingGroupIdentifier {\n  id\n  identifierType {\n    display\n    id\n  }\n  identifierUseCode {\n    display\n    id\n  }\n  value\n  effectiveFrom\n  effectiveTo\n}\n\nfragment BillingGroupInfo_BillingGroup on BillingGroup {\n  id\n  name\n  legalContract {\n    name\n    id\n  }\n}\n\nfragment ContractFragments_AllContractsDropdown on LegalContract {\n  id\n  name\n}\n\nfragment Fragments_AllAddressUseCodesDropdown on AddressUseCode {\n  id\n  display\n}\n\nfragment Fragments_AllIdentifierTypes on IdentifierType {\n  id\n  display\n}\n\nfragment Fragments_AllIdentifierUseCodes on IdentifierUseCode {\n  id\n  display\n}\n"
  }
};
})();

node.hash = "5fa311b0f601b64a49868408de14f868";

export default node;

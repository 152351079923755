/**
 * @generated SignedSource<<bb0ee08acd799e1923985d90327575ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Fragments_AllContactPointSystemCodesDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display",
      "storageKey": null
    }
  ],
  "type": "ContactPointSystemCode",
  "abstractKey": null
};

node.hash = "a32a7d8218b1c9424c1f2621d1797dd4";

export default node;

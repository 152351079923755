import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {toast} from "react-toastify";
import InfoPanel from "../../components/common/repeated-page-panels/InfoPanel";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import {FormProvider, useForm} from "react-hook-form";
import {useUser} from "../../hooks/useUser";

const RoleInfo = ({role}) => {
    const UpdateRoleMutation = graphql`
        mutation RoleInfo_RoleMutation($id: ID!,$name: String, $description: String) {
            role {
                modify(input: {roleId: $id, name: $name, description: $description}) {
                    role {
                        ...RoleInfo_role
                    }
                }
            }
        }`
    const roleInfo = useFragment(
        graphql`
            fragment RoleInfo_role on Role {
                id
                name
                description
            }`
    , role)
    const [commitMutation, isInFlight] = useMutation(UpdateRoleMutation)
    const [readMode, setReadMode] = useState(true);
    const methods = useForm({
        values: {
            name: roleInfo.name,
            description: roleInfo.description
        }
    })
    const {hasPermission} = useUser()
    
    const onSubmit = (data) => {
        commitMutation({
            variables: {
                id: roleInfo.id,
                name: data.name,
                description: data.description
            },
            onCompleted: (data) => {
                toast.success("Role has been updated");
                setReadMode(true);
            }})
    }
    const handleCancelClick = () => {
        methods.reset();
        setReadMode(true);
    }
    
    return (
        <FormProvider {...methods}>
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <InfoPanel name={roleInfo.name}
                           isInFlight={isInFlight}
                           readMode={readMode}
                           onCancelChangesClick={handleCancelClick}
                           onUpdateDetailsClick={() => {setReadMode(false)}}
                           showUpdate={hasPermission("Roles.Update")}
                >
                    <HorizontalFormInput controlId={"name"}
                                         label={"Name"}
                                         readMode={readMode}
                                         validation={{required: "Name is required"}}
                    />
                    <HorizontalFormInput controlId={"description"}
                                         label={"Description"}
                                         readMode={readMode}
                                         validation={{required: "Description is required"}}
                    />
                </InfoPanel>
            </Form>
        </FormProvider>
    );
};

RoleInfo.propTypes = {
    role: PropTypes.object.isRequired
}

export default RoleInfo;

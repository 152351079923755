import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {addEditAndDeleteCells, DateCell} from "../../../components/common/advance-table/CustomCells";
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {useUser} from "../../../hooks/useUser";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import NewOrganizationAddressModal from "./NewOrganizationAddressModal";
import EditOrganizationAddressModal from "./EditOrganizationAddressModal";
import DeleteOrganizationAddressModal from "./DeleteOrganizationAddressModal";

const columns = [
    {
        accessor: "addressUseCode.display",
        Header: "Use"
    },
    {
        accessor:"addressLine1",
        Header: "Address",
        Cell: (rowData) => {
            const address = rowData.row.original
            let addressLine = address.addressLine1
            if(address.addressLine2) {
                addressLine = addressLine + " " + address.addressLine2
            }
            if(address.addressLine3) {
                addressLine = addressLine + " " + address.addressLine3
            }
            return <>{addressLine + " " + address.city + ", " + address.state + " " + address.postalCode}</>
        }
    },
    {
        accessor:"effectiveFrom",
        Header: "Effective From",
        Cell: DateCell
    },
    {
      accessor: "effectiveTo",
      Header: "Effective To",
      Cell: DateCell  
    }
]
const OrganizationAddresses = ({addresses, organizationId, addressUseCodes}) => {
    const data = useFragment(graphql`
    fragment OrganizationAddresses_Addresses on OrganizationAddress @relay(plural: true) {
        id
        addressUseCode {
            id
            display
        }
        addressLine1
        addressLine2
        addressLine3
        city
        state
        postalCode
        longitude
        latitude
        county {
            id
            name
            state
        }
        effectiveFrom,
        effectiveTo
    }`, addresses);
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({});
    
    const handleEditClick = (data) => {
        setSelectedAddress(data);
        setShowEditModal(true);
    }
    
    const handleDeleteClick = (data) => {
        setSelectedAddress(data);
        setShowDeleteModal(true);
    }
    
    const cols = useMemo(() => {
        const canEdit = hasPermission("OrganizationAddresses.Update");
        const canDelete = hasPermission("OrganizationAddresses.Delete");
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick)
    }, [hasPermission])
    
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Addresses"}
                                         table
                                         showNew={hasPermission("OrganizationAddresses.Create")}
                        />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                                      rowClassName="align-middle white-space-nowrap"
                                      tableProps={{
                                          size: 'sm',
                                          striped: true,
                                          className: 'fs--1 mb-0 overflow-hidden'}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewOrganizationAddressModal onClose={() => {setShowNewModal(false)}}
                                         addressUseCodes={addressUseCodes}
                                         showModal={showNewModal}
                                         organizationId={organizationId}
            />
            <EditOrganizationAddressModal showModal={showEditModal} 
                                          onClose={() => {setShowEditModal(false)}}
                                          selectedAddress={selectedAddress}
                                          addressUseCodes={addressUseCodes}
            />
            <DeleteOrganizationAddressModal onClose={() => {setShowDeleteModal(false)}}
                                            selectedAddress={selectedAddress}
                                            showModal={showDeleteModal}
            />
        </>
    )
}
OrganizationAddresses.propTypes = {}
export default OrganizationAddresses
import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import FormModal from "../../../components/common/modals/FormModal";
import {allAttachmentCategoriesDropdown} from "../../../relay/Fragments";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import {toast} from "react-toastify";

const NewContractAttachmentModal = ({showModal, onClose, contractId, allAttachmentCategories}) => {
    const mutation = graphql`
        mutation NewContractAttachmentModalMutation($legalContractId: ID!, $fileName: String!, $size: Long!, $lastModifiedTimestampUtc: DateTime!, $attachmentCategoryId:ID!) {
            legalContract {
                addAttachment(input: {legalContractId: $legalContractId, attachmentCategoryId: $attachmentCategoryId, fileName: $fileName, size: $size, lastModifiedTimestampUtc: $lastModifiedTimestampUtc})
                {
                    attachment {
                        id
                    }
                    uploadUrl
                }
            }
        }`
    const [commitMutation, isInFlight] = useMutation(mutation);

    const reportStatusMutation = graphql`
        mutation NewContractAttachmentModalReportStatusMutation($legalContractId: ID!, $attachmentId: ID!, $uploadSuccessful:Boolean!) {
            legalContract {
                reportAttachmentUploadStatus(input: {legalContractId: $legalContractId, attachmentId: $attachmentId, uploadSuccessful: $uploadSuccessful})
                {
                    legalContract {
                        id
                        attachments {
                            ...ContractAttachments_Attachments
                        }
                    }
                }
            }
        }`;
    const [commitReportStatusMutation] = useMutation(reportStatusMutation);
    
    const categories = useFragment(allAttachmentCategoriesDropdown, allAttachmentCategories).map((id) => {return {value: id.id, label:id.name}});
    const defaultValues = {
        legalContractId: contractId,
        attachmentCategoryId: '',
        fileName: ''
    }
    const schema = yup.object({
        attachmentCategoryId: yup.string().required("Category is required")
    })
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});


    const handleSubmit = (data) => {
        let fileUploaded = data.fileName[0];
        let fileName = fileUploaded.name;
        commitMutation({
            variables: {
                legalContractId: data.legalContractId,
                attachmentCategoryId: data.attachmentCategoryId,
                fileName: fileName,
                size: fileUploaded.size,
                lastModifiedTimestampUtc: new Date(fileUploaded.lastModified)
            },
            onCompleted: (returnedData) => {
                if (returnedData.legalContract) {
                    let attachmentId = returnedData.legalContract.addAttachment.attachment.id;

                    fetch(returnedData.legalContract.addAttachment.uploadUrl, {
                        method: 'PUT', headers: {
                            "Content-Type": "application/octet-stream",
                            "x-ms-blob-type": "BlockBlob",
                            "Date": new Date().toUTCString()
                        }, body: data.fileName[0], mode: "cors"
                    }).then(response => {
                        let uploadSuccessful = response.ok;

                        commitReportStatusMutation({
                            variables: {
                                legalContractId: data.legalContractId,
                                attachmentId: attachmentId,
                                uploadSuccessful: uploadSuccessful
                            },
                            onCompleted: (returnedReportStatusData) => {
                                if (uploadSuccessful) {
                                    toast.success("Attachment " + fileName + " added");
                                } else {
                                    toast.error("Attachment " + fileName + " could not be added");
                                }
                                methods.reset();
                                onClose();
                            }
                        });
                    });
                }
            }
        })
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"New Attachment"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormInput controlId={"fileName"} label={"File Name"} type={'file'}/>
            <HorizontalFormSelect options={categories} controlId={"attachmentCategoryId"} label={"Category"} />
        </FormModal>
    )
}
NewContractAttachmentModal.propTypes = {
    showModal:PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    contractId: PropTypes.string.isRequired,
    allAttachmentCategories:PropTypes.array.isRequired
}
export default NewContractAttachmentModal
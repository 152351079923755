import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import allNetworkOwnersDropdown from "../network-owners/NetworkOwnersFragments";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import NewModal from "../../components/common/modals/NewModal";
import {useForm} from "react-hook-form";

const NewOrganizationModal = ({showModal, onHide, networkOwners}) => {
    const NewOrganizationMutation = graphql`
        mutation NewOrganizationModalMutation($primaryName: String!,$networkOwnerId: ID!) {
            organization {
                create(input: {primaryName: $primaryName, networkOwnerId: $networkOwnerId}) {
                    organization {
                        id
                    }
                }
            }
        }`
    const allNetworkOwners = useFragment(allNetworkOwnersDropdown, networkOwners).map(no => {return {value: no.id, label: no.name}});
    const methods = useForm({defaultValues: {primaryName: '', networkOwnerId: null}})
    
    return (
        <NewModal showModal={showModal} 
                  onClose={onHide} 
                  mutation={NewOrganizationMutation} 
                  methods={methods}
                  title={"New Organization"} 
                  returnedIdKey={"organization.create.organization.id"}
                  redirectUrl={"/organizations/"}
        >
            <HorizontalFormInput controlId={"primaryName"}
                                 label={"Primary Name"}
                                 validation={{required: "Primary Name is required"}}
            />
            <HorizontalFormSelect options={allNetworkOwners}
                                  controlId={"networkOwnerId"}
                                  label={"Network Owner"}
                                  validation={{required: "Network Owner is required"}}
            />
        </NewModal>
    )
}
NewOrganizationModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    networkOwners: PropTypes.array.isRequired
}
export default NewOrganizationModal
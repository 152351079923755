import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {toast} from "react-toastify";
import {Button, Modal} from "react-bootstrap";

const DeleteOrganizationContactPointModal = ({showModal, onClose, selectedContactPoint}) => {
    const mutation = graphql`
    mutation DeleteOrganizationContactPointModalMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on Organization {
                    id
                    contactPoints {
                        ...OrganizationContactPoints_ContactPoints
                    }
                }
            }
        }
    }`
    const [commitMutation, isInFlight] = useMutation(mutation);
    
    const handleDeleteClick = () => {
       commitMutation({variables: {id: selectedContactPoint.id},
       onCompleted: (data) => {
           if(data.deleteNode) {
               toast.success("Contact Point has been deleted")
               onClose();
           }
       }}) 
    }
    return (
        <Modal show={showModal} onHide={onClose} centered size={"lg"} aria-labelledby={"contained-modal-title-vcenter"}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Contact Point?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete Contact Point: {selectedContactPoint.contactPointSystemCode?.display} {selectedContactPoint.value}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"falcon-default"} onClick={handleDeleteClick}>{isInFlight ? "Deleting" : "Delete"}</Button>
            </Modal.Footer>
        </Modal>
    )
}
DeleteOrganizationContactPointModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedContactPoint: PropTypes.object.isRequired
}
export default DeleteOrganizationContactPointModal
import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {addEditAndDeleteCells, DateCell} from "../../../components/common/advance-table/CustomCells";
import {useUser} from "../../../hooks/useUser";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import CardDropdown from "../../../components/common/CardDropdown";
import DropdownItem from "react-bootstrap/DropdownItem";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import NewOrganizationIdentifierModal from "./NewOrganizationIdentifierModal";
import EditOrganizationIdentifierModal from "./EditOrganizationIdentifierModal";
import DeleteOrganizationIdentifierModal from "./DeleteOrganizationIdentifierModal";

const columns = [
    {
        accessor:"identifierType.display",
        Header: "Identifier Type",
        cellProps: {
            
        }
    },
    {
        accessor:"identifierUseCode.display",
        Header:"Identifier Use",
        
    },
    {
        accessor:"value",
        Header:"Value"
    },
    {
        accessor:"effectiveFrom",
        Header:"Effective From",
        Cell: DateCell
    },
    {
        accessor:"effectiveTo",
        Header:"Effective To",
        Cell:DateCell
    }
]
const OrganizationIdentifiers = ({identifiers, organizationId, identifierTypes, identifierUseCodes}) => {
    const data = useFragment(graphql`
        fragment OrganizationIdentifiers_Identifiers on OrganizationIdentifier @relay(plural: true) {
            id
            identifierType {
                display
                id
            }
            identifierUseCode {
                display
                id
            }
            value
            effectiveFrom
            effectiveTo
        }
    `, identifiers)
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedIdentifier, setSelectedIdentifier] = useState({});

    const handleEditClick = (data) => {
        setSelectedIdentifier(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedIdentifier(data);
        setShowDeleteModal(true);
    }
    
    const cols = useMemo(() => {
        const canEdit = hasPermission("OrganizationIdentifiers.Update")
        const canDelete = hasPermission("OrganizationIdentifiers.Delete")
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick)
    },[hasPermission])
    
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
                                 >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}} 
                                         title={"Identifiers"}
                                         table
                                         showNew={hasPermission("OrganizationIdentifiers.Create")}
                        />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                                      rowClassName="align-middle white-space-nowrap"
                                      tableProps={{
                                          size: 'sm',
                                          striped: true,
                                          className: 'fs--1 mb-0 overflow-hidden'}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewOrganizationIdentifierModal showModal={showNewModal}
                                            onClose={() => {setShowNewModal(false)}}
                                            organizationId={organizationId}
                                            identifierTypes={identifierTypes}
                                            identifierUseCodes={identifierUseCodes}
            />
            <EditOrganizationIdentifierModal showModal={showEditModal}
                                             onClose={() => {setShowEditModal(false)}}
                                             selectedIdentifier={selectedIdentifier}
                                             identifierTypes={identifierTypes}
                                             identifierUseCodes={identifierUseCodes}
            />
            <DeleteOrganizationIdentifierModal showModal={showDeleteModal}
                                               onClose={() => {setShowDeleteModal(false)}}
                                               selectedIdentifier={selectedIdentifier}
            />
        </>
    )
}
OrganizationIdentifiers.propTypes = {}
export default OrganizationIdentifiers
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { networkInfo } from 'data/networks/networkDetailData';
import classNames from 'classnames';

const PractitionerCredentialsInfo = () => {
    return (
        <Card className="mb-3">
            <Card.Header>
                <Row className="align-items-center">
                    <Col>
                        <h5 className="mb-0">Credentialing</h5>
                    </Col>
                    <Col xs="auto">
                        <IconButton
                            iconClassName="fs--2 me-1"
                            variant="falcon-default"
                            size="sm"
                            icon="pencil-alt"
                        >
                            Update details
                        </IconButton>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="bg-light border-top">
                <Row>
                    <Col lg xxl={9}>
                        <Row key="delegated">
                            <Col xs={8} sm={7}>
                                <p className="fw-semi-bold mb-1">Delegated</p>
                            </Col>
                            <Col>
                                <p className={"mb-1"}>No</p>
                            </Col>
                        </Row>
                        <Row key="cred_active">
                            <Col xs={8} sm={7}>
                                <p className="fw-semi-bold mb-1">Credentialed since</p>
                            </Col>
                            <Col>
                                <p className={"mb-1"}>1/12/2015</p>
                            </Col>
                        </Row>
                        <Row key="cred_expires">
                            <Col xs={8} sm={7}>
                                <p className="fw-semi-bold mb-1">Credentialing expires</p>
                            </Col>
                            <Col>
                                <p className={"mb-1"}>1/12/2025</p>
                            </Col>
                        </Row>
                        <Row key="cred_by">
                            <Col xs={8} sm={7}>
                                <p className="fw-semi-bold mb-1">Credentialed by</p>
                            </Col>
                            <Col>
                                <p className={"mb-1"}>Christy Garrett</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
            {/*<Card.Footer className="border-top text-end">*/}
            {/*  <IconButton*/}
            {/*    iconClassName="fs--2 me-1"*/}
            {/*    variant="falcon-default"*/}
            {/*    size="sm"*/}
            {/*    icon="dollar-sign"*/}
            {/*  >*/}
            {/*    Refund*/}
            {/*  </IconButton>*/}
            {/*  <IconButton*/}
            {/*    className="ms-2"*/}
            {/*    iconClassName="fs--2 me-1"*/}
            {/*    variant="falcon-default"*/}
            {/*    size="sm"*/}
            {/*    icon="check"*/}
            {/*  >*/}
            {/*    Save changes*/}
            {/*  </IconButton>*/}
            {/*</Card.Footer>*/}
        </Card>
    );
};

export default PractitionerCredentialsInfo;

import React, { useContext, useEffect } from 'react';
import {RouterProvider} from 'react-router-dom';
import is from 'is_js';
import AppContext from 'context/Context';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import NmsRoutes from "./routes/NmsRoutes";
import {Spinner} from "react-bootstrap";

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);
  
  return (
    <>
      <RouterProvider router={NmsRoutes} fallbackElement={<Spinner />} />
    </>
  );
};

export default App;

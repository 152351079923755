import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from "react-bootstrap";

const MovableTableHeader = ({selectedRowIds, direction, name, apply}) => {
    const buttonText = direction === "sending" ? "Add selected to Active" : "Remove selected from Active";
    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{name}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                    <div className="d-flex">
                        <Button
                            type="button"
                            variant="falcon-default"
                            size="sm"
                            className="ms-2"
                            onClick={apply}
                            disabled={!Object.keys(selectedRowIds).length > 0}
                        >
                            {buttonText}
                        </Button>
                    </div>
                
            </Col>
        </Row>
    )
}
MovableTableHeader.propTypes = {
    selectedRowIds: PropTypes.object,
    direction: PropTypes.oneOf(['sending', 'receiving']),
    name: PropTypes.string,
    apply: PropTypes.func
}
export default MovableTableHeader
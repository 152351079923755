/**
 * @generated SignedSource<<d43aa5a83b700e0525ffd4ff6b736d96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "display",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteOrganizationContactPointModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteNodePayload",
        "kind": "LinkedField",
        "name": "deleteNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationContactPoint",
                    "kind": "LinkedField",
                    "name": "contactPoints",
                    "plural": true,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OrganizationContactPoints_ContactPoints"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Organization",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteOrganizationContactPointModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteNodePayload",
        "kind": "LinkedField",
        "name": "deleteNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationContactPoint",
                    "kind": "LinkedField",
                    "name": "contactPoints",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactPointSystemCode",
                        "kind": "LinkedField",
                        "name": "contactPointSystemCode",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactPointUseCode",
                        "kind": "LinkedField",
                        "name": "contactPointUseCode",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "effectiveFrom",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "effectiveTo",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Organization",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f9a3611cec994c88df17b20bc3a114d",
    "id": null,
    "metadata": {},
    "name": "DeleteOrganizationContactPointModalMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteOrganizationContactPointModalMutation(\n  $id: ID!\n) {\n  deleteNode(input: {id: $id}) {\n    owner {\n      __typename\n      ... on Organization {\n        id\n        contactPoints {\n          ...OrganizationContactPoints_ContactPoints\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment OrganizationContactPoints_ContactPoints on OrganizationContactPoint {\n  id\n  contactPointSystemCode {\n    id\n    display\n  }\n  contactPointUseCode {\n    id\n    display\n  }\n  value\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "91e8782ee5de0e903ee2eace245e5fb3";

export default node;

import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import NewModal from "../../../components/common/modals/NewModal";

const NewOrganizationContactModal = ({organizationId, showModal, onHide}) => {
    const NewContactMutation = graphql`
        mutation NewOrganizationContactModalMutation($organizationId: ID!, $lastName: String!, $firstName: String!,
            $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
            organization {
                createContact(input: {organizationId: $organizationId, lastName: $lastName, firstName: $firstName,
                effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                    organizationContact {
                        id
                        organization {
                            id
                            contacts {
                                ...OrganizationContacts_Contacts
                            }
                        }
                    }
                }
            }
        }`
    let today = new Date();
    today.setHours(0, 0 ,0);
    const endOfTime = new Date(9999,11,31, 0,0,0);
    const schema = yup.object({
        lastName: yup.string().required("Last Name is required"),
        firstName: yup.string().required("First Name is required"),
        effectiveFrom: yup.date().required("Effective From is required")
            .when('effectiveTo', (effectiveTo) => {
                if(effectiveTo) {
                    return yup.date()
                        .max(effectiveTo, "Effective From must be before Effective To")
                }
            }),
        effectiveTo: yup.date().required("Effective To is required")
            
    })
    const methods = useForm({defaultValues: {organizationId: organizationId, lastName:'', firstName:'', effectiveFrom:today, effectiveTo: endOfTime}, resolver: yupResolver(schema)})
    return (
        <NewModal showModal={showModal} 
                  onClose={onHide} 
                  title={"New Contact"} 
                  mutation={NewContactMutation} 
                  returnedIdKey={"organization.createContact.organizationContact.id"} 
                  redirectUrl={"/organizations/" + organizationId + "/contact/"} 
                  methods={methods}
        >
            <HorizontalFormInput controlId={"firstName"} 
                                 label={"First Name"}
            />
            <HorizontalFormInput controlId={"lastName"} 
                                 label={"Last Name"}
            />
            <HorizontalFormDate controlId={"effectiveFrom"}
                                label={"Effective From"}
            />
            <HorizontalFormDate controlId={"effectiveTo"}
                                label={"Effective To"}
            />
        </NewModal>
   )
}
NewOrganizationContactModal.propTypes = {
    organizationId: PropTypes.string.isRequired,
    showModal: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}
export default NewOrganizationContactModal
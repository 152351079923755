import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  lmsStatistics,
  avgEnrollments,
  topCourses,
  courseEnrollments,
  marketingExpensesData
} from 'data/dashboard/lms';

import LmsStats from '../lms/lms-stats/LmsStats';
import Networks from "../../../pages/networks/Networks";
const NetworkOwnerDashboard = () => {
  return (
    <>
      <LmsStats data={lmsStatistics} />
      <Row className="g-3 mb-3">
        <Col lg={12} xl={12}>
          <Networks />
        </Col>
      </Row>
    </>
  );
};

export default NetworkOwnerDashboard;

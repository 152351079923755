import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {Button, Modal} from "react-bootstrap";
import IconButton from "../../../components/common/IconButton";
import {useMutation} from "react-relay";
import {toast} from "react-toastify";

const DeleteContractAttachmentModal = ({showModal, onClose, attachmentId, contractId, fileName}) => {
    const mutation = graphql`
        mutation DeleteContractAttachmentModal_Mutation($id: ID!) {
            deleteNode(input: {id: $id}) {
                id
                owner {
                    id
                    ... on LegalContract {
                        attachments {
                            ...ContractAttachments_Attachments
                        }
                    }
                }
            }
        }`
    const [commitMutation, isInFlight] = useMutation(mutation)
    const handleDeleteClick = () => {
        commitMutation({variables: {id: attachmentId, contractId: contractId}, onCompleted: (data) =>
            {
                toast.success("Attachment has been deleted");
                onClose();
            }})
    }
    return (
        <Modal show={showModal} onHide={onClose} centered size={"lg"} aria-labelledby={"contained-modal-title-vcenter"}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Attachment?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete Attachment {fileName}?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"falcon-default"} onClick={handleDeleteClick}>Delete</Button>
            </Modal.Footer>
        </Modal>
    )
}
DeleteContractAttachmentModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    fileName: PropTypes.string.isRequired,
    attachmentId: PropTypes.string.isRequired,
    contractId: PropTypes.string.isRequired
}
export default DeleteContractAttachmentModal
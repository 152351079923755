import React from 'react';
import PropTypes from 'prop-types';
import {usePreloadedQuery} from "react-relay";
import {useLoaderData} from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import BillingGroupInfo from "./BillingGroupInfo";
import {Col, Row} from "react-bootstrap";
import {useUser} from "../../hooks/useUser";
import BillingGroupIdentifiers from "./identifiers/BillingGroupIdentifiers";
import DeletePanel from "../../components/common/repeated-page-panels/DeletePanel";
import BillingGroupAddress from "./addresses/BillingGroupAddress";

const BillingGroupDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
    query BillingGroupDetailsQuery($id: ID!) {
        node(id: $id) {
            id
            ... on BillingGroup {
                name
                ...BillingGroupInfo_BillingGroup
                identifiers {
                    ...BillingGroupIdentifiers_Identifiers
                }
                addresses {
                    ...BillingGroupAddress_Address
                }
            }
        }
        allLegalContracts {
            ...ContractFragments_AllContractsDropdown
        }
        allIdentifierTypes {
            ...Fragments_AllIdentifierTypes
        }
        allIdentifierUseCodes {
            ...Fragments_AllIdentifierUseCodes
        }
        allAddressUseCodes {
            ...Fragments_AllAddressUseCodesDropdown
        }
    }`, loader.billingGroupDetailsQuery);
    const deleteMutation = graphql`
    mutation BillingGroupDetailsDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            id
        }}`
    const {hasPermission} = useUser();
    return (
        <>
            <DetailsPageHeader iconText={"Billing Group"} iconType={"file-invoice-dollar"} title={data.node.name} />
            <Row>
                <Col>
                    <BillingGroupInfo billingGroup={data.node} contracts={data.allLegalContracts} />
                </Col>
            </Row>
            <Row>
                {hasPermission("BillingGroupIdentifiers.Read") && 
                    <Col md={6}>
                        <BillingGroupIdentifiers identifierUseCodes={data.allIdentifierUseCodes} 
                                                 identifierTypes={data.allIdentifierTypes}
                                                 identifiers={data.node.identifiers}
                                                 billingGroupId={data.node.id} 
                        />
                </Col>}
                {hasPermission("BillingGroupAddresses.Read") &&
                <Col md={6}>
                    <BillingGroupAddress addresses={data.node.addresses} 
                                         billingGroupId={data.node.id} 
                                         addressUseCodes={data.allAddressUseCodes} 
                    />
                </Col>}
            </Row>
            {hasPermission("BillingGroups.Delete") && 
            <DeletePanel mutation={deleteMutation} redirectUrl={"/billing-groups"} thingToDelete={"Billing Group"} id={data.node.id}>
                In order to delete a Billing Group, there must be no related data
            </DeletePanel>}
        </>
    )
}
export default BillingGroupDetails
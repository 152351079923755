import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allContactPointSystemCodesDropdown, allContactPointUseCodesDropdown} from "../../../relay/Fragments";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";

const NewOrganizationContactPointModal = ({showModal, onClose, organizationId, contactPointSystemCodes, contactPointUseCodes}) => {
    const mutation = graphql`
        mutation NewOrganizationContactPointModalMutation($organizationId: ID!, $contactPointSystemCodeId: ID!,
            $contactPointUseCodeId: ID!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!, $value: String!) {
            organization {
                createContactPoint(input: {organizationId: $organizationId, contactPointSystemCodeId: $contactPointSystemCodeId,
                contactPointUseCodeId: $contactPointUseCodeId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo, value: $value}) {
                    organizationContactPoint {
                        id
                        organization {
                            id
                            contactPoints {
                                ...OrganizationContactPoints_ContactPoints
                            }
                        }
                    }
                }
            }
        }`
    const systemCodes = useFragment(allContactPointSystemCodesDropdown, contactPointSystemCodes).map((cpsc) => {return {value:cpsc.id, label: cpsc.display}});
    const useCodes = useFragment(allContactPointUseCodesDropdown, contactPointUseCodes).map((uc) => {return {value: uc.id, label: uc.display}});
    
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        organizationId: organizationId,
        contactPointSystemCodeId: '',
        contactPointUseCodeId: '',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31),
        value: ''
    }
    const schema = yup.object({
        contactPointSystemCodeId: yup.string().required("System Code is required"),
        contactPointUseCodeId: yup.string().required("Use Code is required"),
        value: yup.string().required("Value is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    
    const handleSubmit = (data) => {
        commitMutation({variables: {...data},
            onCompleted: (data) => {
                if(data.organization) {
                    toast.success("Contact Point for Organization created")
                    methods.reset();
                    onClose();
                }    
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"New Contact Point"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={systemCodes} controlId={"contactPointSystemCodeId"} label={"System Code"}/>
            <HorizontalFormSelect options={useCodes} controlId={"contactPointUseCodeId"} label={"Use Code"} />
            <HorizontalFormInput controlId={"value"} label={"Value"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
NewOrganizationContactPointModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    organizationId: PropTypes.string.isRequired,
    contactPointSystemCodes: PropTypes.array.isRequired,
    contactPointUseCodes: PropTypes.array.isRequired
}
export default NewOrganizationContactPointModal
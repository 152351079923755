import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, FormLabel, Row} from "react-bootstrap";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../components/common/horizontal-form-inputs/HorizontalFormDate";

const DetailsTab = ({fileName, downloadUrl, linesOfService}) => {
    return (
        <Card>
            <Card.Body className={"bg-light"}>
                <Row className={"mb-1"}>
                    <Col xs={7} sm={3}>
                        <FormLabel className={"col-form-label"} htmlFor={"fileName"}>Download File</FormLabel>
                    </Col>
                    <Col>
                        <a id={"fileName"} href={downloadUrl}>{fileName}</a>
                    </Col>
                </Row>
                <HorizontalFormInput controlId={"node.legalContractLinesOfService[0].linesOfService.contract.name"} label={"Contract"} readMode={true} />
                <Row className={"mb-1"}>
                    <Col xs={7} sm={3}>
                        <FormLabel className={"col-form-label"} htmlFor={"linesOfService"}>Lines of Service</FormLabel>
                    </Col>
                    <Col>
                        <p id={"linesOfService"} className={"form-control-plaintext"}>
                        {linesOfService.map((l, index) => {
                            return l.lineOfService.name
                        }).join(', ')}
                        </p>
                    </Col>
                </Row>
                <HorizontalFormInput controlId={"node.createdBy.userName"} label={"Created By"} readMode={true} />
                <HorizontalFormDate controlId={"node.createdOn"} label={"Created On"} readMode={true} />
            </Card.Body>
        </Card>
    )
}
DetailsTab.defaultProps = {}
DetailsTab.propTypes = {}
export default DetailsTab
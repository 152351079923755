import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {useUser} from "../../../hooks/useUser";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import NewContractTermModal from "./NewContractTermModal";
import EditContractTermModal from "./EditContractTermModal";
import CardDropdown from "../../../components/common/CardDropdown";
import DropdownItem from "react-bootstrap/DropdownItem";
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import DeleteContractTermModal from "./DeleteContractTermModal";

const defaultColumns = [{
        accessor: 'type.description',
        Header: "Term Type",
    },
    {
        accessor: 'value',
        Header: "Value"
    }]

const ContractTerms = ({terms, contractId, possibleTermTypes}) => {
    const data = useFragment(graphql`
        fragment ContractTerms_Terms on LegalContractTerm @relay(plural: true) {
            id
            type {
                id
                description
                valueType
            }
            value
        }
    `, terms)
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState({});
    
    const availTermTypes = possibleTermTypes.filter(tt => !data.some(x => x.type.id === tt.id))
    const cols = useMemo(() => {
        const canEdit = hasPermission("LegalContractTerms.Update")
        const canDelete = hasPermission("LegalContractTerms.Delete")
        const col = {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
        }
        if(canEdit && canDelete) {
            return defaultColumns.concat([{
                    ...col,
                    Cell: (rowData) => {
                        return <CardDropdown>
                            <DropdownItem as={"button"} onClick={() => {handleEditClick(rowData.row.original)}}>Edit</DropdownItem>
                            <DropdownItem as={"button"} onClick={() => {handleDeleteClick(rowData.row.original)}}>Delete</DropdownItem>
                        </CardDropdown>
                    }}])
        }
        if(canEdit) {
            return defaultColumns.concat([{
                ...col,
                Cell: (rowData) => {
                    return <CardDropdown>
                        <DropdownItem as={"button"} onClick={() => {handleEditClick(rowData.row.original)}}>Edit</DropdownItem>
                    </CardDropdown>
                }}])
        }
        if(canDelete) {
           return defaultColumns.concat([{
               ...col,
               Cell: (rowData) => {
                   return <CardDropdown>
                       <DropdownItem as={"button"} onClick={() => {handleDeleteClick(rowData.row.original)}}>Delete</DropdownItem>
                   </CardDropdown>
               }}]) 
        }
        return defaultColumns
    }, [hasPermission])

    const handleEditClick = (data) => {
        setSelectedTerm(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedTerm(data);
        setShowDeleteModal(true);
    }
    return (
        <>
            <AdvanceTableWrapper columns={cols} 
                                 data={data} 
                                 sortable 
                                 pagination 
                                 perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Contract Terms"}
                                         table
                                         showNew={hasPermission("LegalContractTerms.Create") && availTermTypes.length > 0}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable table
                                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                                      rowClassName="align-middle white-space-nowrap"
                                      tableProps={{
                                          size: 'sm',
                                          striped: true,
                                          className: 'fs--1 mb-0 overflow-hidden'}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewContractTermModal contractId={contractId} 
                                  possibleTermTypes={availTermTypes} 
                                  showModal={showNewModal} 
                                  onClose={() => {setShowNewModal(false)}} 
            />
            <EditContractTermModal showModal={showEditModal} 
                                   onClose={() => {setShowEditModal(false)}} 
                                   selectedTerm={selectedTerm} 
                                   possibleTermTypes={possibleTermTypes}
            />
            <DeleteContractTermModal showModal={showDeleteModal}
                                     onClose={() => {setShowDeleteModal(false)}}
                                     selectedTermId={selectedTerm?.id}
                                     selectedTermType={selectedTerm?.type?.description}
            />
        </>
    )
}
ContractTerms.propTypes = {    
    terms: PropTypes.array.isRequired,
    contractId: PropTypes.string.isRequired,
    possibleTermTypes: PropTypes.array.isRequired
}
export default ContractTerms
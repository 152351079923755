import React, {useState} from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import FormModal from "../../components/common/modals/FormModal";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormCheck from "../../components/common/horizontal-form-inputs/HorizontalFormCheck";
import {contractLineOfService} from "../contracts/ContractLineOfService/ContractLinesOfServiceFragments";
const NewRosterLoadModal = ({showModal, onClose, legalContractLinesOfService}) => {
    const mutation = graphql`
    mutation NewRosterLoadModalMutation($fileName: String!, $legalContractLineOfServiceIds: [ID!]!) {
        rosterLoad {
            create(input: {fileName: $fileName, legalContractLineOfServiceIds: $legalContractLineOfServiceIds}) {
                rosterLoad {
                    id
                }
                uploadUrl
            }
        }
    }`
    const reportStatusMutation = graphql`
    mutation NewRosterLoadModalReportStatusMutation($rosterLoadId: ID!, $uploadSuccessful: Boolean!) {
        rosterLoad {
            reportRosterUploadStatus(input: {rosterLoadId: $rosterLoadId, uploadSuccessful: $uploadSuccessful}) {
                rosterLoad {
                    id
                }
            }
        }
    }`
    const linesOfService = useFragment(contractLineOfService, legalContractLinesOfService)
    const navigate = useNavigate();
    const [commitMutation, isInFlight] = useMutation(mutation);
    const [commitReportStatusMutation, isReportInFlight] = useMutation(reportStatusMutation);
    const [fetchInFlight, setFetchInFlight] = useState(false);
    const defaultValues = {
        fileName:'',
        legalContractLineOfServiceIds: []
    }
    const methods = useForm({defaultValues: defaultValues});
    const handleSubmit = (data) => {
        let fileUploaded = data.fileName[0];
        let fileName = fileUploaded.name;
        commitMutation({
            variables: {
                legalContractLineOfServiceIds: data.legalContractLineOfServiceIds,
                fileName: fileName,
            },
            onCompleted: (returnedData) => {
                if(returnedData.rosterLoad) {
                    let rosterLoadId = returnedData.rosterLoad.create.rosterLoad.id;
                    setFetchInFlight(true);
                    fetch(returnedData.rosterLoad.create.uploadUrl, {
                        method: 'PUT', headers: {
                            "Content-Type": "application/octet-stream",
                            "x-ms-blob-type": "BlockBlob",
                            "Date": new Date().toUTCString()
                        }, body: data.fileName[0], mode: "cors"
                    }).then(response => {
                        setFetchInFlight(false);
                        let uploadSuccessful = response.ok;
                        commitReportStatusMutation({
                            variables: {
                                rosterLoadId: rosterLoadId,
                                uploadSuccessful: uploadSuccessful
                            },
                            onCompleted: (returnedStatusData) => {
                                if(returnedStatusData.rosterLoad && uploadSuccessful) {
                                    navigate("/roster-loads/" + rosterLoadId);
                                }
                                else {
                                    toast.error("Roster load could be processed");
                                }
                            }
                        })
                    })
                }
            }
        })
    }
    return (
        <FormModal showModal={showModal}
                   methods={methods}
                   onClose={onClose}
                   title={"New Roster Load"}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
                   onSubmit={handleSubmit}
                   isInFlight={isInFlight || isReportInFlight || fetchInFlight}>
            <HorizontalFormInput controlId={"fileName"} type={'file'} label={"File"} />
            {linesOfService.map(x => {
                return <HorizontalFormCheck key={x.id} controlId={"legalContractLineOfServiceIds"} label={x.lineOfService.name} value={x.id} />
            })}
        </FormModal>
    )
}
NewRosterLoadModal.defaultProps = {}
NewRosterLoadModal.propTypes = {}
export default NewRosterLoadModal
/**
 * @generated SignedSource<<2f68e20b9619e4996d0030db22bd27f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "legalContractId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "npi"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BillingGroupMutationGroup",
    "kind": "LinkedField",
    "name": "billingGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "legalContractId",
                "variableName": "legalContractId"
              },
              {
                "kind": "Variable",
                "name": "name",
                "variableName": "name"
              },
              {
                "kind": "Variable",
                "name": "npi",
                "variableName": "npi"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "BillingGroupModifyPayload",
        "kind": "LinkedField",
        "name": "create",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingGroup",
            "kind": "LinkedField",
            "name": "billingGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewBillingGroupModalMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewBillingGroupModalMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "8dd1bddb71f94587fba3f8eeac72aa96",
    "id": null,
    "metadata": {},
    "name": "NewBillingGroupModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewBillingGroupModalMutation(\n  $name: String!\n  $legalContractId: ID!\n  $npi: String!\n) {\n  billingGroup {\n    create(input: {name: $name, legalContractId: $legalContractId, npi: $npi}) {\n      billingGroup {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "e898e18a357d9eb2ace2aa1a246375b1";

export default node;

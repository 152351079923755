/**
 * @generated SignedSource<<9900aa3a5b25f3003370ee0227b03757>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Network",
  "kind": "LinkedField",
  "name": "networks",
  "plural": true,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NetworkOwnerDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NetworkOwnerInfo_NetworkOwner"
              },
              (v4/*: any*/)
            ],
            "type": "NetworkOwner",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NetworkOwnerDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "type": "NetworkOwner",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cff62792d337209a8cbe790dabc2052d",
    "id": null,
    "metadata": {},
    "name": "NetworkOwnerDetailsQuery",
    "operationKind": "query",
    "text": "query NetworkOwnerDetailsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on NetworkOwner {\n      name\n      ...NetworkOwnerInfo_NetworkOwner\n      networks {\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment NetworkOwnerInfo_NetworkOwner on NetworkOwner {\n  id\n  name\n}\n"
  }
};
})();

node.hash = "67f010d9d90e5df4fff9b59706daf812";

export default node;

import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {useUser} from "../../../hooks/useUser";
import {addEditAndDeleteCells, DateCell} from "../../../components/common/advance-table/CustomCells";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import EditBillingGroupIdentifierModal from "./EditBillingGroupIdentifierModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";
import NewBillingGroupIdentifierModal from "./NewBillingGroupIdentifierModal";

const columns = [
    {accessor: "identifierType.display", Header: 'Identifier Type'},
    {accessor: "identifierUseCode.display", Header:"Identifier Use"},
    {accessor: "value", Header: "Value"},
    {accessor: "effectiveFrom", Header:"Effective From", Cell: DateCell},
    {accessor: "effectiveTo", Header:"Effective To", Cell: DateCell}
]
const BillingGroupIdentifiers = ({identifiers, billingGroupId, identifierTypes, identifierUseCodes}) => {
    const data = useFragment(graphql`
    fragment BillingGroupIdentifiers_Identifiers on BillingGroupIdentifier @relay(plural:true) {
        id
        identifierType {
            display
            id
        }
        identifierUseCode {
            display
            id
        }
        value
        effectiveFrom
        effectiveTo
    }`,identifiers);
    const mutation = graphql`mutation BillingGroupIdentifiersDeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            owner {
                ... on BillingGroup {
                    id
                    identifiers {
                        id
                    }
                }}}}`;
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedIdentifier, setSelectedIdentifier] = useState({});
    
    const handleEditClick = (data) => {
        setSelectedIdentifier(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedIdentifier(data);
        setShowDeleteModal(true);
    }
    
    const cols = useMemo(() => {
        const canEdit = hasPermission("BillingGroupIdentifiers.Update");
        const canDelete = hasPermission("BillingGroupIdentifiers.Delete");
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick);
    }, [hasPermission])
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
                                 >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader title={"Identifiers"}
                                         onNewClick={() => {setShowNewModal(true)}}
                                         table
                                         showNew={hasPermission("BillingGroupIdentifiers.Create")} 
                        />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                      rowClassName={"align-middle white-space-nowrap"}
                                      tableProps={{
                                          size:"sm",
                                          striped:true,
                                          className: 'fs--1 mb-0 overflow-hidden'}} 
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewBillingGroupIdentifierModal showModal={showNewModal}
                                  onClose={() => {setShowNewModal(false)}}
                                  billingGroupId={billingGroupId}
                                  identifierTypes={identifierTypes}
                                  identifierUseCodes={identifierUseCodes}
            />
            <EditBillingGroupIdentifierModal showModal={showEditModal}
                                             onClose={() => setShowEditModal(false)}
                                             selectedIdentifier={selectedIdentifier}
                                             identifierTypes={identifierTypes}
                                             identifierUseCodes={identifierUseCodes}
            />
            {selectedIdentifier &&
            <DeleteModal showModal={showDeleteModal}
                         onClose={() => {setShowDeleteModal(false)}}
                         mutation={mutation}
                         thingToDelete={"Identifier"}
                         id={selectedIdentifier ? selectedIdentifier.id : ''}
            >
                <p>Are you sure you want to delete Identifier: {selectedIdentifier?.identifierType?.display} {selectedIdentifier?.value}</p>
            </DeleteModal>}
        </>
    )
}
BillingGroupIdentifiers.defaultProps = {}
BillingGroupIdentifiers.propTypes = {}
export default BillingGroupIdentifiers
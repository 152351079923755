import React, {useState} from 'react';
import PropTypes from "prop-types";
import { Form} from 'react-bootstrap';
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {FormProvider, useForm} from "react-hook-form";
import InfoPanel from "../../components/common/repeated-page-panels/InfoPanel";
import allNetworkOwnersDropdown from "../network-owners/NetworkOwnersFragments";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";

const NetworkInfo = ({network, networkOwners}) => {
  const networkInfo = useFragment(graphql`
    fragment NetworkInfo_Network on Network {
      id,
      name,
      networkOwner {
        id,
        name
      }
    }
  `, network)
  const allNetworkOwners = useFragment(allNetworkOwnersDropdown, networkOwners).map(no => {return {value: no.id, label: no.name}});
  const methods = useForm({values: {name:networkInfo.name, networkOwnerId: networkInfo.networkOwner.id}});
  const [readMode, setReadMode] = useState(true);
  const onSubmit = (data) => {
    
  }
  const handleCancelChanges = () => {
    
  }
  return (
    <FormProvider {...methods}>
      <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <InfoPanel name={networkInfo.name}
                   readMode={readMode}
                   showUpdate={false}
                   onUpdateDetailsClick={() => {setReadMode(false)}}
                   onCancelChangesClick={handleCancelChanges}
                   isInFlight={false}
        >
          <HorizontalFormInput controlId={"name"} 
                               label={"Name"} 
                               readMode={readMode}
                               validation={{required: "Name is required"}}
          />
          <HorizontalFormSelect options={allNetworkOwners} 
                                controlId={"networkOwnerId"} 
                                label={"Network Owner"} 
                                readMode={readMode}
                                validation={{required: "Network Owner is required"}}
          />
        </InfoPanel>
      </Form>
    </FormProvider>
  );
};
NetworkInfo.propTypes = {
  network: PropTypes.object,
  networkOwners: PropTypes.array
}
export default NetworkInfo;

import {useState} from 'react'
import {useController} from "react-hook-form";
import {createInitOptions} from "../helpers";
import {useMutation} from "react-relay";

export const usePropertyField = (controlId, property, mutation) => {
    let validationRules = {}
    if(property.required) {
        validationRules.required = `${property.propertyName} is required`;
    }
    const {field, fieldState: {invalid, error}} = useController(
        {name:controlId + ".proposedValue", rules: {...validationRules}});
    const [options, setOptions] = useState(createInitOptions(property.possibleValues, field.value, property.originalValue));
    const [commitMutation, isInFlight] = useMutation(mutation);
    
    const handleOnChange = (e) => {
        const val = e == null ? e : e.value
        commitMutation({variables: {
            id: property.id,
                proposedValue: val
            }, onCompleted: () => {
                field.onChange(val)
            }})
    }
    
    const handleOnCreate = (value) => {
        commitMutation({variables: {
            id: property.id,
                proposedValue: value
        }, onCompleted : () => {
                const newOption = {value: value, label: value};
                setOptions([...options,newOption]);
                field.onChange(value);
            }})
    }
    return {field, invalid, error, options, handleOnChange, handleOnCreate, isInFlight}
}
export const facilitiesData = [
    {
        name: 'Conway Regional Medical Center',
        avatar: {
            name: 'Conway Regional'
        },
        email: 'main@conwayregional.com',
        phone: '(501) 200-1851',
        address: '2392 College St, Conway, Arkansas 72365',
        joined: '03/30/2015'
    },
    {
        name: 'Conway Regional Greenbriar Family Medicine',
        avatar: {
            name: 'Conway Regional'
        },
        email: 'main@conwayregional.com',
        phone: '(501) 679-4536',
        address: '110 N. Broadview  Greenbriar, Arkansas 72058',
        joined: '03/30/2015'
    },
    {
        name: 'Kids for the Future of Helena',
        avatar: {
            name: 'Conway Regional'
        },
        organization: "Kids for the Future, Inc.",
        email: 'main@conwayregional.com',
        phone: '(501) 679-4536',
        address: '110 N. Broadview  Greenbriar, Arkansas 72058',
        joined: '03/30/2015'
    }
]
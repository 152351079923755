/**
 * @generated SignedSource<<d95707a44171f8f3112210b0cd363768>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Fragments_AllIdentifierUseCodes",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display",
      "storageKey": null
    }
  ],
  "type": "IdentifierUseCode",
  "abstractKey": null
};

node.hash = "662fe3beff5a40585f9869f9d7b418f5";

export default node;

export const licenseInfo = [
    {
        id: 1,
        label: 'ID',
        value: 'dcfasyo_Dfdjl',
        active: true
    },
    {
        id: 2,
        label: 'Payer',
        value: 'Arkansas Superior Select, Inc.',
        active: true
    },
    {
        id: 3,
        label: 'Network',
        value: 'Vantos',
        active: true
    },
    {
        id: 4,
        label: 'Lines of Service',
        value: 'Arkansas Medicare, Arkansas Medicaid',
        active: true
    },
    {
        id: 5,
        label: 'Effective From',
        value: '2/5/2019',
        active: true
    },
    {
        id: 6,
        label: 'Effective To',
        value: '<current>',
        active: true
    }
];
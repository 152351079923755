import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import InfoPanel from "../../components/common/repeated-page-panels/InfoPanel";
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import allNetworkOwnersDropdown from "../network-owners/NetworkOwnersFragments";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import {toast} from "react-toastify";
import {FormProvider, useForm} from "react-hook-form";
import {useUser} from "../../hooks/useUser";

const OrganizationInfo = ({organization, networkOwners}) => {
  const UpdateOrganizationMutation = graphql`
    mutation OrganizationInfo_OrganizationMutation($id: ID!, $primaryName: String!, $networkOwnerId: ID!) {
      organization {
        modify(input: {organizationId: $id, primaryName: $primaryName, networkOwnerId: $networkOwnerId}) {
          organization {
            ...OrganizationInfo_Organization
          }
        }
      }
    }`
  const organizationInfo = useFragment(graphql`
        fragment OrganizationInfo_Organization on Organization {
          id
          primaryName
          networkOwner {
            name
            id
          }
        }`
      , organization)
  const allNetworkOwners = useFragment(allNetworkOwnersDropdown, networkOwners).map((no) => {return {value: no.id, label: no.name}});
  const [readMode, setReadMode] = useState(true);
  const [commitMutation, isInFlight] = useMutation(UpdateOrganizationMutation);
  const methods = useForm({values: {
      primaryName: organizationInfo.primaryName,
      networkOwnerId: organizationInfo.networkOwner.id
    }})
  const {hasPermission} = useUser();
  
  const onSubmit = (data) => {
    commitMutation({variables: {
        id: organizationInfo.id,
        ...data
      }, onCompleted: () => {
        toast.success("Organization has been updated");
        setReadMode(true);
      }})
  }
  const handleCancelClick = () => {
    methods.reset();
    setReadMode(true);
  }
  
  return (
      <FormProvider {...methods}>
          <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
            <InfoPanel readMode={readMode}
                       onCancelChangesClick={handleCancelClick}
                       name={organizationInfo.primaryName}
                       isInFlight={isInFlight}
                       onUpdateDetailsClick={() => {setReadMode(false)}}
                       showUpdate={hasPermission("Organizations.Update")}
            >
              <HorizontalFormInput controlId={"primaryName"}
                                   label={"Primary Name"}
                                   readMode={readMode}
                                   validation={{required: "Primary Name is required"}}
              />
              <HorizontalFormSelect controlId={"networkOwnerId"}
                                    label={"Network Owner"}
                                    readMode={readMode}
                                    validation={{required: "Network Owner is required"}}
                                    options={allNetworkOwners}
              />
            </InfoPanel>
          </Form>
      </FormProvider>
  );
};
OrganizationInfo.propTypes = {
  organization: PropTypes.object.isRequired,
  networkOwners: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default OrganizationInfo;

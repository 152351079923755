import React from 'react';
import PropTypes from 'prop-types';
import {useController} from "react-hook-form";
import {Form, Row, Col} from "react-bootstrap";
import MaskedInput, {conformToMask} from "react-text-mask";

const HorizontalFormMaskedInput = ({controlId, label, mask, readMode, validation, ...rest}) => {
    const {field, fieldState: {invalid, error}} = useController(
        {name: controlId, rules: validation});
    let className = "form-control form-control-sm"
    if(invalid) {
        className += " is-invalid";
    }
    let conformedValue = conformToMask(field.value ? field.value : '', mask, {guide: false});
    return (
        <Form.Group as={Row} className={"mb-1"} controlId={controlId}>
            <Form.Label column xs={7} sm={3}>{label}</Form.Label>
            <Col>
                <div className={invalid ? "is-invalid" : ""}>
                    {readMode ? 
                        <Form.Control
                            value={conformedValue.conformedValue}
                            size={"sm"}
                            disabled
                            plaintext
                        />
                        :
                        <MaskedInput 
                            className={className}
                            mask={mask}
                            onChange={field.onChange}
                            value={field.value}
                            {...rest}
                        />
                    }
                </div>
                {invalid && <Form.Control.Feedback type={"invalid"}>{error.message}</Form.Control.Feedback>}
            </Col>
        </Form.Group>
    )
}
HorizontalFormMaskedInput.defaultProps = {
    readMode: false
}
HorizontalFormMaskedInput.propTypes = {
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    mask: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
    validation: PropTypes.object,
    readMode: PropTypes.bool
}
export default HorizontalFormMaskedInput
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useFragment, useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import InfoPanel from "../../../components/common/repeated-page-panels/InfoPanel";
import {useUser} from "../../../hooks/useUser";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import dayjs from "dayjs";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
const OrganizationContactInfo = ({contact}) => {
    const organizationContactMutation = graphql`
        mutation OrganizationContactInfoMutation($id:ID!, $effectiveFrom:DateOnly!, 
            $effectiveTo:DateOnly!, $firstName:String!, $lastName:String!) {
            organization {
                modifyContact(input: {organizationContactId: $id, effectiveFrom: $effectiveFrom,
                effectiveTo: $effectiveTo, firstName: $firstName, lastName: $lastName}) {
                    organizationContact {
                        ...OrganizationContactInfo_Contact
                    }
                }
            }
        }
    `
    const organizationContactInfo = useFragment(graphql`
        fragment OrganizationContactInfo_Contact on OrganizationContact {
            id
            effectiveTo
            effectiveFrom
            firstName
            lastName
        }
    `, contact);
    const defaultValues = {
        firstName: organizationContactInfo.firstName,
        lastName: organizationContactInfo.lastName,
        effectiveFrom: dayjs(organizationContactInfo.effectiveFrom).toDate(),
        effectiveTo: dayjs(organizationContactInfo.effectiveTo).toDate()
    }
    const schema = yup.object({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        effectiveFrom: yup.date().required("Effective From is required")
            .when('effectiveTo', (effectiveTo) => {
                if(effectiveTo) {
                    return yup.date().max(effectiveTo, "Effective From must be before Effective To")
                }
            }),
        effectiveTo: yup.date().required("Effective To is required")
    });
    const [readMode, setReadMode] = useState(true);
    const [commitMutation, isInFlight] = useMutation(organizationContactMutation);
    const methods = useForm({values:defaultValues, resolver: yupResolver(schema)});
    const {hasPermission} = useUser();
    
    const handleSubmitClick = (data) => {
        commitMutation({variables: {
                id: organizationContactInfo.id,
                ...data
            },
        onCompleted: (data) => {
            if(data.organization) {
                toast.success("Contact has been updated")
                setReadMode(true);
            }
        }})
    }
    const handleCancelClick = (e) => {
        methods.reset();
        setReadMode(true);
    }
    return (
        <FormProvider {...methods}>
            <Form noValidate onSubmit={methods.handleSubmit(handleSubmitClick)}>
                <InfoPanel onUpdateDetailsClick={() => {setReadMode(false)}} 
                           onCancelChangesClick={handleCancelClick} 
                           name={organizationContactInfo.lastName + ", " + organizationContactInfo.firstName} 
                           isInFlight={isInFlight} 
                           readMode={readMode}
                           showUpdate={hasPermission("OrganizationContacts.Update")}
                >
                    <HorizontalFormInput controlId={"firstName"} 
                                         label={"First Name"} 
                                         readMode={readMode}
                    />
                    <HorizontalFormInput controlId={"lastName"} 
                                         label={"Last Name"} 
                                         readMode={readMode}
                    />
                    <HorizontalFormDate controlId={"effectiveFrom"} 
                                        label={"Effective From"} 
                                        readMode={readMode} 
                    />
                    <HorizontalFormDate controlId={"effectiveTo"} 
                                        label={"Effective To"} 
                                        readMode={readMode} 
                    />
                </InfoPanel>
            </Form>
        </FormProvider>
    )
}
OrganizationContactInfo.propTypes = {
    contact: PropTypes.object.isRequired
}
export default OrganizationContactInfo
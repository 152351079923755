/**
 * @generated SignedSource<<4e55655fc9eebcb8559e4f22f9e9a529>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "primaryName",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContractsTable_Contract"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Network",
        "kind": "LinkedField",
        "name": "allNetworks",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NetworkFragments_allNetworkDropdown"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "allOrganizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationsFragments_AllOrganizationsDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ContractsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContract",
        "kind": "LinkedField",
        "name": "allLegalContracts",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Network",
            "kind": "LinkedField",
            "name": "network",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Network",
        "kind": "LinkedField",
        "name": "allNetworks",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "allOrganizations",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "85e84e50200ba1158875b48524b072c5",
    "id": null,
    "metadata": {},
    "name": "ContractsQuery",
    "operationKind": "query",
    "text": "query ContractsQuery {\n  allLegalContracts {\n    ...ContractsTable_Contract\n    id\n  }\n  allNetworks {\n    ...NetworkFragments_allNetworkDropdown\n    id\n  }\n  allOrganizations {\n    ...OrganizationsFragments_AllOrganizationsDropdown\n    id\n  }\n}\n\nfragment ContractsTable_Contract on LegalContract {\n  id\n  name\n  startDate\n  endDate\n  network {\n    id\n    name\n  }\n  organization {\n    id\n    primaryName\n  }\n}\n\nfragment NetworkFragments_allNetworkDropdown on Network {\n  id\n  name\n}\n\nfragment OrganizationsFragments_AllOrganizationsDropdown on Organization {\n  id\n  primaryName\n}\n"
  }
};
})();

node.hash = "2213428b321cb6784b5f782c95ba9a08";

export default node;

import React from 'react';
import PropTypes from 'prop-types';
import {Accordion} from "react-bootstrap";
import {useWatch} from "react-hook-form";
import StatusBadge from "../components/StatusBadge";
import AddField from "../components/AddField";
import AcceptReject from "../components/AcceptReject";
import graphql from "babel-plugin-relay/macro";


const BillingGroupAdd = ({billingGroupAdded, billingGroupIndex, changePropertyMutation, changeStatusMutation}) => {
    const billingGroupKey = "node.addedBillingGroups[" + billingGroupIndex + "]"
    const billingGroupNameIndex = billingGroupAdded.properties.findIndex((p) => {return p.propertyName === "Name"})
    const billingGroupName = useWatch({name: `${billingGroupKey}.properties[${billingGroupNameIndex}].proposedValue`})
    const nameProposedValues = billingGroupAdded.properties[billingGroupNameIndex].possibleValues.sort((a, b) => {return b.showsUp - a.showsUp});
    const keyId = billingGroupAdded.properties.findIndex((p) => {return p.isKey});
    const billingGroupKeyValue = useWatch({name: `${billingGroupKey}.properties[${keyId}].proposedValue`})
    const name = billingGroupName ? billingGroupName : nameProposedValues[0].value;
    const status = useWatch({name: billingGroupKey + ".status"});
    return (
            <Accordion.Item eventKey={billingGroupIndex.toString()}>
                <Accordion.Header>
                    Add: {name} : {billingGroupKeyValue}
                    <StatusBadge status={status} />
                </Accordion.Header>
                <Accordion.Body>
                    {billingGroupAdded.properties.map((p, index) => {
                        return <AddField 
                            key={index}
                            property={p}
                            controlId={`node.addedBillingGroups[${billingGroupIndex}].properties[${index}]`}
                            status={status}
                            mutation={changePropertyMutation}
                        />
                    }).sort((a, b) => {return a.props.property.displayOrder - b.props.property.displayOrder})}
                    <AcceptReject controlId={billingGroupKey} mutation={changeStatusMutation} />
                </Accordion.Body>
            </Accordion.Item>
    )
}
BillingGroupAdd.defaultProps = {}
BillingGroupAdd.propTypes = {}
export default BillingGroupAdd
/**
 * @generated SignedSource<<4863bb255280cfc5f69edd04924b7118>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "legalContractLineOfServiceIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RosterLoadMutationGroup",
    "kind": "LinkedField",
    "name": "rosterLoad",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "fileName",
                "variableName": "fileName"
              },
              {
                "kind": "Variable",
                "name": "legalContractLineOfServiceIds",
                "variableName": "legalContractLineOfServiceIds"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "RosterLoadCreatePayload",
        "kind": "LinkedField",
        "name": "create",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RosterLoad",
            "kind": "LinkedField",
            "name": "rosterLoad",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uploadUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewRosterLoadModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewRosterLoadModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f16d3971a2461c8aac6d52f6c08d76ff",
    "id": null,
    "metadata": {},
    "name": "NewRosterLoadModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewRosterLoadModalMutation(\n  $fileName: String!\n  $legalContractLineOfServiceIds: [ID!]!\n) {\n  rosterLoad {\n    create(input: {fileName: $fileName, legalContractLineOfServiceIds: $legalContractLineOfServiceIds}) {\n      rosterLoad {\n        id\n      }\n      uploadUrl\n    }\n  }\n}\n"
  }
};
})();

node.hash = "6abf27db3bcf229d90002db0c5856bb0";

export default node;

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import {Navigate, useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';
import {useUser} from "../../hooks/useUser";

const LoginForm = ({hasLabel, layout}) => {
    const {user, login} = useUser()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const remoteError = searchParams.get("remoteError");
    useEffect(() => {
        if (remoteError != null) {
            toast.error("Error logging in: " + remoteError, {theme: 'colored'});
        }
    }, [remoteError])
    const message = searchParams.get("message");
    useEffect(() => {
        if (message != null) {
            toast.error(message, {theme: 'colored'});
        }
    }, [message])
    // State
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    // Handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        await login(formData.username, formData.password);
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    if(user != null) {
        return <Navigate to={"/"} replace />;
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Username</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Username' : ''}
                    value={formData.username}
                    name="username"
                    onChange={handleFieldChange}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Password</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? 'Password' : ''}
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                />
            </Form.Group>

            <Row className="justify-content-between align-items-center">
                {/*Only commenting out because we might need this later */}
                {/*<Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>*/}
            </Row>

            <Form.Group>
                <Button
                    type="submit"
                    color="primary"
                    className="mt-3 w-100"
                    disabled={!formData.username || !formData.password}
                >
                    Log in
                </Button>
            </Form.Group>
        </Form>
    );
};

LoginForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default LoginForm;

import React from 'react';
import PropTypes from 'prop-types';
import NewModal from "../../components/common/modals/NewModal";
import {useForm} from "react-hook-form";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../components/common/horizontal-form-inputs/HorizontalFormDate";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import allOrganizationsDropdown from "../organizations/OrganizationsFragments";
import {allNetworksDropdown} from "../networks/NetworkFragments";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

const NewContractModal = ({showModal, onClose, organizations, networks}) => {
    const NewContractMutation = graphql`
        mutation NewContractModalMutation($name: String, $networkId: ID!, $organizationId: ID!, $startDate: DateOnly!, $endDate: DateOnly!) {
            legalContract {
                create(input: {name: $name, networkId: $networkId, organizationId: $organizationId, startDate: $startDate, endDate: $endDate}) {
                    legalContract {
                        id
                    }
                }
            }
        }
    `
    const allOrganizations = useFragment(allOrganizationsDropdown, organizations).map(o => {return {value:o.id, label:o.primaryName}});
    const allNetworks = useFragment(allNetworksDropdown, networks).map(n => {return {value: n.id, label: n.name}});
    let today = new Date();
    today.setHours(0,0,0,0);
    const endOfTime = new Date(9999, 11,31,0,0,0,0);
    const schema = yup.object({
        name: yup.string(),
        networkId: yup.string().required("Network is required"),
        organizationId: yup.string().required("Organization is required"),
        startDate: yup.date().required("Start Date is required")
            .when('endDate', (endDate) => {
                if(endDate) {
                    return yup.date().max(endDate, "Start Date must be before End Date")
                }
            }),
        endDate: yup.date().required("End Date is required")
    })
    const defaultValues = {
        name: '',
        networkId: null,
        organizationId: null,
        startDate: today,
        endDate: endOfTime
    }
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)})
    
    return (
        <NewModal showModal={showModal}
                  onClose={onClose}
                  title={"New Contract"} 
                  methods={methods}
                  redirectUrl={"/contracts/"}
                  mutation={NewContractMutation}
                  returnedIdKey={"legalContract.create.legalContract.id"}
        >
            <HorizontalFormInput controlId={"name"} label={"Name"}/>
            <HorizontalFormSelect options={allOrganizations} 
                                  controlId={"organizationId"}
                                  label={"Organization"}
            />
            <HorizontalFormSelect options={allNetworks}
                                  controlId={"networkId"} 
                                  label={"Network"}
            />
            <HorizontalFormDate controlId={"startDate"} label={"Start Date"}/>
            <HorizontalFormDate controlId={"endDate"} label={"End Date"}/>
        </NewModal>
    )
}
NewContractModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    organizations: PropTypes.array.isRequired,
    networks:PropTypes.array.isRequired
}
export default NewContractModal
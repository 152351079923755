import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allContactPointSystemCodesDropdown, allContactPointUseCodesDropdown} from "../../../relay/Fragments";
import dayjs from "dayjs";
import * as yup from 'yup'
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal"
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const EditOrganizationContactPointModal = ({showModal, onClose, selectedContactPoint, contactPointSystemCodes, contactPointUseCodes}) => {
    const mutation = graphql`
        mutation EditOrganizationContactPointModalMutation($organizationContactPointId: ID!, $contactPointSystemCodeId: ID!,
        $contactPointUseCodeId: ID!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!, $value: String!) {
            organization {
                modifyContactPoint(input: {organizationContactPointId: $organizationContactPointId, contactPointSystemCodeId: $contactPointSystemCodeId,
                contactPointUseCodeId: $contactPointUseCodeId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo, value: $value}) {
                    organizationContactPoint {
                        id
                        organization {
                            id
                            contactPoints {
                                ...OrganizationContactPoints_ContactPoints
                            }
                        }
                    }
                }
            }
        }`
    const systemCodes = useFragment(allContactPointSystemCodesDropdown, contactPointSystemCodes).map((sc) => {return {value: sc.id, label: sc.display}});
    const useCodes = useFragment(allContactPointUseCodesDropdown, contactPointUseCodes).map((uc) => {return {value: uc.id, label: uc.display}});
    const [commitMutation, isInFlight] = useMutation(mutation);
    const effectiveFrom = selectedContactPoint?.effectiveFrom ? dayjs(selectedContactPoint.effectiveFrom).toDate() : '';
    const effectiveTo = selectedContactPoint?.effectiveTo ? dayjs(selectedContactPoint.effectiveTo).toDate() : '';
    const defaultValues = {
        organizationContactPointId: selectedContactPoint.id,
        contactPointSystemCodeId: selectedContactPoint?.contactPointSystemCode?.id,
        contactPointUseCodeId: selectedContactPoint?.contactPointUseCode?.id,
        effectiveFrom: effectiveFrom,
        effectiveTo: effectiveTo,
        value: selectedContactPoint?.value
    }
    const schema = yup.object({
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be before Effective From"),
        contactPointSystemCodeId: yup.string().required("System Code is required"),
        contactPointUseCodeId: yup.string().required("Use Code is required"),
        value: yup.string().required("Value is required")
    });
    const methods = useForm({values: defaultValues, resolver: yupResolver(schema)});
    
    const handleSubmit = (data) => {
        commitMutation({variables: {...data},
            onCompleted: (data) => {
                if(data.organization) {
                    toast.success("Contact Point has been updated")
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"Edit Contact Point"}
                   methods={methods}
                   onSubmit={handleSubmit}
                   isInFlight={isInFlight}
        >
            <HorizontalFormSelect options={systemCodes} controlId={"contactPointSystemCodeId"} label={"System Code"} />
            <HorizontalFormSelect options={useCodes} controlId={"contactPointUseCodeId"} label={"Use Code"} />
            <HorizontalFormInput controlId={"value"} label={"Value"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
EditOrganizationContactPointModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    contactPointSystemCodes: PropTypes.array.isRequired,
    contactPointUseCodes: PropTypes.array.isRequired,
    selectedContactPoint: PropTypes.object.isRequired
}
export default EditOrganizationContactPointModal
import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useFragment, useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import MovableTables from "../../components/common/advance-table/MovableTables";
import {SortByName} from "../../helpers/utils";
import {toast} from "react-toastify";

const RolePermissions = ({permissions, role}) => {
    const updatePermissionsMutation = graphql`
    mutation RolePermissionsMutation($id: ID!, $addPermissions: [ID!]!, $removePermissions: [ID!]!) {
        role {
            modifyPermissions(input: {roleId: $id, addPermissionIds: $addPermissions, removePermissionIds: $removePermissions}) {
                role {
                    ...RolePermissions_rolePermission
                }
            }
        }
    }`
    const rolePermissions = useFragment(
        graphql`
            fragment RolePermissions_rolePermission on Role {
                id
                permissions {
                    id
                    name
                }
            }
        `, role)
    const allPermissions = useFragment(
        graphql`
            fragment RolePermissions_activePermission on Permission @relay(plural: true) {
                id
                name
            }
        `, permissions)
    const columns = useMemo( () =>[
        {
            accessor: 'name',
            Header: 'Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            }
        }
    ], []);
    const [commitPermissionUpdate, isUpdating] = useMutation(updatePermissionsMutation);
    const rolePermissionIds = rolePermissions.permissions.map(p => p.id);
    const filteredAllPermissions = allPermissions.filter(p => !rolePermissionIds.includes(p.id)).sort(SortByName)
    const handleSaveChangesClick = (addedToAvailable, addedToActive) => {
        commitPermissionUpdate({variables: {
            id: rolePermissions.id,
                addPermissions: Object.keys(addedToActive),
                removePermissions: Object.keys(addedToAvailable)
            }, onCompleted: () => {
                toast.success("Permissions saved")
            }})
    }
    return (
                <MovableTables overallTitle={"Permissions"} leftName={"Available Permissions"} leftData={filteredAllPermissions} 
                               rightName={"Active Permissions"} rightData={rolePermissions.permissions}
                               columns={columns} onSaveChanges={handleSaveChangesClick} />
            
    );
};
RolePermissions.propTypes = {
    role: PropTypes.object.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default RolePermissions;

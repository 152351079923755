/**
 * @generated SignedSource<<4447ba44a33af79ae3f8caf44c44c47f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdOn",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RosterLoadsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RosterLoad",
        "kind": "LinkedField",
        "name": "allRosterLoads",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RosterLoadsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RosterLoad",
        "kind": "LinkedField",
        "name": "allRosterLoads",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f1bf391d89b61be4db8127d3829f202",
    "id": null,
    "metadata": {},
    "name": "RosterLoadsQuery",
    "operationKind": "query",
    "text": "query RosterLoadsQuery {\n  allRosterLoads {\n    id\n    fileName\n    status\n    createdBy {\n      userName\n      id\n    }\n    createdOn\n  }\n}\n"
  }
};
})();

node.hash = "1cc843d152e8b9000938676b5ff200e4";

export default node;

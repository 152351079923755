import React from 'react';
import PropTypes from 'prop-types';
import {Accordion, Table} from "react-bootstrap";
import {useWatch} from "react-hook-form";
import StatusBadge from "../components/StatusBadge";
import ChangeTableRow from "../components/ChangeTableRow";
import AcceptReject from "../components/AcceptReject";

const BillingGroupChange = ({billingGroupChanged, billingGroupIndex, changePropertyMutation, changeStatusMutation}) => {
    const key = "node.changedBillingGroups[" + billingGroupIndex + "]"
    const properties = useWatch({name: `${key}.properties`});
    const nameIndex = properties.findIndex((p) => {return p.propertyName === "Name"});
    const npiIndex = properties.findIndex((p) => {return p.isKey});
    const name = properties[nameIndex].originalValue;
    const npi = properties[npiIndex].proposedValue;
    const status = useWatch({name: `${key}.status`});
    return (
            <Accordion.Item eventKey={billingGroupIndex.toString()}>
            <Accordion.Header>Change: {name + ": " + npi}
                <StatusBadge status={status} />
            </Accordion.Header>
            <Accordion.Body>
               <Table size={"sm"} striped className={"fs--1 mb-0"}>
                   <thead className="bg-200 text-900 text-nowrap align-middle">
                    <tr>
                        <th scope={"col"}>Property Name</th>
                        <th scope={"col"}>Original Value</th>
                        <th scope={"col"}>New Value</th>
                    </tr>
                   </thead>
                   <tbody>
                   {billingGroupChanged.properties.map((p, index) => {
                       return <ChangeTableRow
                           key={index}
                           controlId={`${key}.properties[${index}]`} 
                           property={p}
                           status={status}
                           mutation={changePropertyMutation}
                       />
                   }).sort((a, b) => {return a.props.property.displayOrder - b.props.property.displayOrder})}
                   </tbody>
               </Table>
                <AcceptReject controlId={key} mutation={changeStatusMutation} />
            </Accordion.Body>
            </Accordion.Item>
    )
}
BillingGroupChange.defaultProps = {}
BillingGroupChange.propTypes = {}
export default BillingGroupChange
import React from 'react';
import DetailsPageHeader from "../../../components/common/headers/DetailsPageHeader";
import {Row, Col} from "react-bootstrap";
import OrganizationContactInfo from "./OrganizationContactInfo";
import {useLoaderData, useParams} from "react-router-dom";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {useUser} from "../../../hooks/useUser";
import DeletePanel from "../../../components/common/repeated-page-panels/DeletePanel";

const OrganizationContactDetails = () => {
    const loader = useLoaderData();
    const {contactId} = useParams();
    const data = usePreloadedQuery(graphql`
        query OrganizationContactDetailsQuery($id: ID!) {
            node(id: $id) {
                ... on Organization {
                    id
                    contacts {
                        id
                        ...OrganizationContactInfo_Contact
                    }
                }
            }
        }
    `, loader.organizationContactDetailsQuery)
    const deleteMutation = graphql`
        mutation OrganizationContactDetails_DeleteMutation($id: ID!) {
            deleteNode(input: {id: $id}) {
                owner {
                ... on Organization {
                        id
                        contacts {
                            ...OrganizationContacts_Contacts
                        }
                    }
                }
            }
        }
    `
    const {hasPermission} = useUser();
    const contact = data.node.contacts.find((c) => {return c.id === contactId})
    return (
        <>
            <DetailsPageHeader iconText={"Organization"} iconType={"building"} title={"Organization Contact"} />
            <Row>
                <Col>
                    {contact && <OrganizationContactInfo contact={contact} />}    
                </Col>
            </Row>
            {hasPermission("OrganizationContacts.Delete") && 
                <DeletePanel mutation={deleteMutation} 
                             redirectUrl={"/organizations/" + data.node.id} 
                             thingToDelete={"Contact"} 
                             id={contact?.id}>
                    In order to delete a contact, there must be no related data
                </DeletePanel>
            }
        </>
    )
}
export default OrganizationContactDetails
import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useForm} from "react-hook-form";
import NewModal from "../../components/common/modals/NewModal";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";

const NewNetworkOwnerModal = ({showModal, onClose}) => {
    const NewNetworkOwnerMutation = graphql`
        mutation NewNetworkOwnerModal_Mutation($name: String!) {
            networkOwner {
                create(input: {name: $name}) {
                    networkOwner {
                        id
                    }
                }
            }
        }`
    const methods = useForm({defaultValues: {name: ''}});
    return (
        <NewModal showModal={showModal} 
                  title={"New Network Owner"}
                  mutation={NewNetworkOwnerMutation}
                  methods={methods}
                  returnedIdKey={"networkOwner.create.networkOwner.id"}
                  redirectUrl={"/network-owners/"}
                  onClose={onClose}
        >
            <HorizontalFormInput controlId={"name"} label={"Name"} validation={{required: "Name is required"}} />
        </NewModal>
    )
}
NewNetworkOwnerModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}
export default NewNetworkOwnerModal
import React from 'react';
import {Navigate, useLocation, useRouteError} from "react-router-dom";

const UnauthorizedError = (props) => {
    const location = useLocation();
    const error = useRouteError();
    if(error.cause?.status === 401) {
        return <Navigate to={"/login"} state={{from: location}} replace />
    }
    throw error;
}

export default UnauthorizedError
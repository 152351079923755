import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {LinkCell} from "../../components/common/advance-table/CustomCells";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import NewRosterLoadModal from "./NewRosterLoadModal";
import {useUser} from "../../hooks/useUser";

const cols = [
    {
        accessor: "fileName",
        Header: "Filename",
        Cell: rowData => {
            const {fileName, id} = rowData.row.original;
            return <LinkCell name={fileName} to={'/roster-loads/' + id} hasAvatar />
        }
    },
    {
        accessor: "status",
        Header: "Status"
    },
    {
        accessor: "createdOn",
        Header: "When Created",
        
    },
    {
        accessor: "createdBy.username",
        Header: "Who Created"
    }

]
const RosterLoadTable = ({rosterLoad, linesOfService, allowNew}) => {
    const rosterLoads = useFragment(
        graphql`
        fragment RosterLoadTable_RosterLoad on RosterLoad @relay(plural:true) {
            id
            fileName
            createdBy {
                userName
            }
            createdOn
            status
        }`, rosterLoad)
    const [showNewModal, setShowNewModal] = useState(false);
    const {hasPermission} = useUser();
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                data={rosterLoads}
                                sortable
                                pagination
                                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                        title={"Contract Roster Loads"}
                                        table
                                        showNew={allowNew && hasPermission("RosterLoads.Create")}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable table
                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                      size: 'sm',
                                      striped: true,
                                      className: 'fs--1 mb-0 overflow-hidden'}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewRosterLoadModal showModal={showNewModal} 
                                onClose={() => {setShowNewModal(false)}} 
                                legalContractLinesOfService={linesOfService} 
            />
            </>
    )
}
RosterLoadTable.defaultProps = {}
RosterLoadTable.propTypes = {}
export default RosterLoadTable
import React, {useMemo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable} from "react-table";
import {IndeterminateCheckbox} from "./AdvanceTableWrapper";
import AdvanceTable from "./AdvanceTable";
import MovableTableHeader from "./MovableTableHeader";
import AdvanceTablePagination from "./AdvanceTablePagination";
import {Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "../IconButton";

const MovableTables = ({columns, leftData, rightData, leftName, rightName, overallTitle, onSaveChanges}) => {
    const [movedToRight, setMovedToRight] = useState({});
    const [movedToLeft, setMovedToLeft] = useState({});
    //We set this to false when we want to manually move pages in the pagination
    const [resetPage, setResetPage] = useState(true);
    useEffect(() => {
        //If the data updates, we need to clear everything out, and reset the table
        setMovedToLeft({});
        setMovedToRight({});
        setResetPage(true);
    }, [leftData, rightData])
    let canSave = Object.keys(movedToLeft).length > 0 || Object.keys(movedToRight).length > 0;
    const editedLeft = useMemo(() => {return calculateLeft()},[movedToRight, movedToLeft,leftData, rightData]);
    const editedRight = useMemo(() => {return calculateRight()}, [movedToRight, movedToLeft, leftData, rightData]);
    const leftTable = useTable({autoResetPage: resetPage, columns, data: editedLeft}, useGlobalFilter, useSortBy, usePagination, useRowSelect, hooks => {hooks.visibleColumns.push(columns => [
        {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
                <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
            )
        },
        {
            id: 'edited',
            Header: 'Edited',
            accessor:'edited',
            Cell: (row) => {
                const {edited} = row.row.original
                const value = edited ? <FontAwesomeIcon icon={"pencil-alt"} /> : null
                return (<div>{value}</div>)
            }
        },
        ...columns
    ]);})
    const rightTable = useTable({autoResetPage: resetPage, columns, data: editedRight},useGlobalFilter, useSortBy, usePagination, useRowSelect, hooks => {hooks.visibleColumns.push(columns => [
        {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
                <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
            )
        },
        {
            id: 'edited',
            Header: 'Edited',
            accessor:'edited',
            Cell: (row) => {
                const {edited} = row.row.original
                const value = edited ? <FontAwesomeIcon icon={"pencil-alt"} /> : null
                return (<div>{value}</div>)
            }
        },
        ...columns
    ]);})
    //To use this in useMemo, it can't be in the const style
    function calculateLeft() {
        //eslint-disable-next-line
        const leftNotMoved = leftData.filter(l => !movedToRight.hasOwnProperty(l.id)).map(l => {return {...l, edited: false}});
        //eslint-disable-next-line
        const rightAdded = rightData.filter(r => movedToLeft.hasOwnProperty(r.id)).map(r => {return {...r, edited: true}});
        return leftNotMoved.concat(rightAdded);
    }
    
    function calculateRight() {
        // eslint-disable-next-line
        const rightNotMoved = rightData.filter(r => !movedToLeft.hasOwnProperty(r.id)).map(r => {return {...r, edited: false}});
        // eslint-disable-next-line
        const leftAdded = leftData.filter(l => movedToRight.hasOwnProperty(l.id)).map(l => {return {...l, edited: true}});
        return rightNotMoved.concat(leftAdded);
    }
    const onMoveToRight = () => {
        let newMovedToRight = {...movedToRight}
        let newMovedToLeft = {...movedToLeft}
         leftTable.selectedFlatRows.forEach(l => {
             //eslint-disable-next-line
             if(!movedToLeft.hasOwnProperty(l.original.id)) {
                 newMovedToRight = {...newMovedToRight, [l.original.id]: true}
             }
             else {
                 delete newMovedToLeft[l.original.id]
             }
            });
        setResetPage(false);
        rightTable.gotoPage(rightTable.pageCount - 1);
        setMovedToRight(newMovedToRight)
        setMovedToLeft(newMovedToLeft);
    }
    const onMoveToLeft = () => {
        let newMovedToLeft = {...movedToLeft}
        let newMovedToRight = {...movedToRight}
        rightTable.selectedFlatRows.forEach(r => {
            //eslint-disable-next-line
            if(!movedToRight.hasOwnProperty(r.original.id)) {
                newMovedToLeft = {...newMovedToLeft, [r.original.id]: true}
            }
            else {
                delete newMovedToRight[r.original.id]
            }
        });
        setResetPage(false);
        leftTable.gotoPage(leftTable.pageCount - 1);
        setMovedToLeft(newMovedToLeft);
        setMovedToRight(newMovedToRight);
    }
    const handleCancelChangesClick = () => {
        setMovedToRight({});
        setMovedToLeft({});
    }
    const handleSaveChangesClick = () => {
        onSaveChanges(movedToLeft, movedToRight);
    }
    return (
        <Card className={"mb-3"}>
            <Card.Header>
                <Row className={"align-items-center"}>
                    <Col>
                        <h5 className={"mb-0"}>{overallTitle}</h5>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className={"bg-light border-top"}>
                <Row>
                    <Col xs={6}>
                <Card className={"mb-3"}>
                    <Card.Header>
                        <MovableTableHeader name={leftName}
                                            direction={"sending"}
                                            apply={onMoveToRight}
                                            {...leftTable.state}/>
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable headerClassName="bg-200 text-900 text-nowrap align-middle"
                                      rowClassName="align-middle white-space-nowrap"
                                      tableProps={{
                                          size: 'sm',
                                          striped: true,
                                          className: 'fs--1 mb-0 overflow-hidden'
                                      }}
                                      {...leftTable}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination {...leftTable} {...leftTable.state} />
                    </Card.Footer>
                </Card>
            </Col>
                    <Col>
                <Card className={"mb-3"}>
                    <Card.Header>
                        <MovableTableHeader name={rightName}
                                            direction={"receiving"}
                                            apply={onMoveToLeft}
                                            {...rightTable.state}/>
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable headerClassName="bg-200 text-900 text-nowrap align-middle"
                                      rowClassName="align-middle white-space-nowrap"
                                      tableProps={{
                                          size: 'sm',
                                          striped: true,
                                          className: 'fs--1 mb-0 overflow-hidden'
                                      }}
                                      {...rightTable}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination {...rightTable} {...rightTable.state} />
                    </Card.Footer>
                </Card>
            </Col>
                </Row>
            </Card.Body>
            <Card.Footer className={"border-top text-end"}>
                <IconButton
                    iconClassName="fs--2 me-1"
                    variant="falcon-default"
                    size="sm"
                    icon={"trash-alt"}
                    onClick={handleCancelChangesClick}
                    disabled={!canSave}
                >
                    Cancel changes
                </IconButton>
                <IconButton
                    className="ms-2"
                    iconClassName="fs--2 me-1"
                    variant="falcon-default"
                    size="sm"
                    icon="check"
                    onClick={handleSaveChangesClick}
                    disabled={!canSave}
                >
                    Save changes
                </IconButton>
            </Card.Footer>
        </Card>
    )
}
MovableTables.defaultProps = {
    leftName: 'Available',
    rightName: 'Active',
    overallTitle: '',
}
MovableTables.propTypes = {
    columns : PropTypes.array.isRequired,
    leftData: PropTypes.array.isRequired,
    rightData: PropTypes.array.isRequired,
    leftName: PropTypes.string,
    rightName: PropTypes.string,
    overallTitle: PropTypes.string,
    onSaveChanges: PropTypes.func.isRequired
}
export default MovableTables
import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {FormProvider, useForm} from "react-hook-form";
import InfoPanel from "../../components/common/repeated-page-panels/InfoPanel";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormDate from "../../components/common/horizontal-form-inputs/HorizontalFormDate";
import dayjs from "dayjs";
import {allNetworksDropdown} from "../networks/NetworkFragments";
import allOrganizationsDropdown from "../organizations/OrganizationsFragments";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import {useUser} from "../../hooks/useUser";
const ContractInfo = ({contract, networks, organizations}) => {
    const UpdateContractMutation = graphql`
        mutation ContractInfo_ContractMutation($id: ID!, $name: String, $networkId: ID!, $organizationId: ID!, $startDate: DateOnly!, $endDate: DateOnly!) {
            legalContract {
                modify(input: {id: $id, name: $name, networkId: $networkId, organizationId: $organizationId, startDate: $startDate, endDate: $endDate}) {
                    legalContract {
                        ...ContractInfo_Contract
                    }
                }
            }
        }`
    const contractInfo = useFragment(graphql`
        fragment ContractInfo_Contract on LegalContract {
            id
            name
            startDate
            endDate
            network {
                name
                id
            }
            organization {
                primaryName
                id
            }
        }
    `, contract)
    const allNetworks = useFragment(allNetworksDropdown, networks).map((n) => {return {value: n.id, label: n.name}});
    const allOrganizations = useFragment(allOrganizationsDropdown, organizations).map((o) => {return {value: o.id, label: o.primaryName}});
    const schema = yup.object({
        name: yup.string(),
        networkId: yup.string().required("Network is required"),
        organizationId: yup.string().required("Organization is required"),
        startDate: yup.date().required("Start Date is required")
            .when('endDate', (endDate) => {
                if(endDate) {
                    return yup.date().max(endDate, "Start Date must be before End Date")
                }
            }),
        endDate: yup.date().required("End Date is required")
    })
    const defaultValues = {
        name: contractInfo.name,
        networkId: contractInfo.network.id,
        organizationId: contractInfo.organization.id,
        startDate: dayjs(contractInfo.startDate).toDate(),
        endDate: dayjs(contractInfo.endDate).toDate()
    }
    const [readMode, setReadMode] = useState(true);
    const [commitMutation, isInFlight] = useMutation(UpdateContractMutation);
    const methods = useForm({values: defaultValues, resolver: yupResolver(schema)})
    const {hasPermission} = useUser();
    const onSubmit = (data) => {
        commitMutation({variables: {
            id: contractInfo.id,
                ...data
            }, onCompleted: () => {
                toast.success("Contract has been updated")
                setReadMode(true)
            }})
    }
    const handleCancelClick = () => {
        methods.reset();
        setReadMode(true);
    }
  return (
      <FormProvider {...methods}>
          <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
              <InfoPanel name={contractInfo.name} 
                         readMode={readMode} 
                         isInFlight={isInFlight} 
                         onCancelChangesClick={handleCancelClick} 
                         onUpdateDetailsClick={() => {setReadMode(false)}}
                         showUpdate={hasPermission("Contracts.Update")}
              >
                  <HorizontalFormInput controlId={"name"} 
                                       label={"Name"} 
                                       readMode={readMode} 
                  />
                  <HorizontalFormSelect options={allOrganizations} 
                                        controlId={"organizationId"} 
                                        label={"Organization"} 
                                        readMode={readMode}
                  />
                  <HorizontalFormSelect options={allNetworks} 
                                        controlId={"networkId"} 
                                        label={"Network"}
                                        readMode={readMode}
                  />
                  <HorizontalFormDate controlId={"startDate"}
                                      label={"Contract Start Date"}
                                      readMode={readMode}
                  />
                  <HorizontalFormDate controlId={"endDate"}
                                      label={"Contract End Date"}
                                      readMode={readMode}
                  />
              </InfoPanel>
          </Form>
      </FormProvider>
  );
};

export default ContractInfo;

import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {allAddressUseCodesDropdown} from "../../../relay/Fragments";
import {useFragment, useMutation} from "react-relay";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import HorizontalFormMaskedInput from "../../../components/common/horizontal-form-inputs/HorizontalFormMaskedInput";

const NewBillingGroupAddressModal = ({showModal, onClose, billingGroupId, addressUseCodes}) => {
    const mutation = graphql`
    mutation NewBillingGroupAddressModalMutation($billingGroupId: ID!, $addressUseCodeId: ID!, $addressLine1:String!, $addressLine2: String!,
    $addressLine3: String!, $city: String!,$state: String!, $postalCode: String!, $phoneNumber: String!, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        billingGroup {
            createAddress(input: {billingGroupId: $billingGroupId, addressUseCodeId: $addressUseCodeId, addressLine1: $addressLine1, addressLine2: $addressLine2,
            addressLine3: $addressLine3, city: $city, state: $state, postalCode: $postalCode, phoneNumber: $phoneNumber,
                effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                billingGroupAddress {
                    id
                    addressUseCode {
                        display
                        id
                    }
                    addressLine1
                    addressLine2
                    addressLine3
                    city
                    state
                    postalCode
                    phoneNumber
                    effectiveFrom
                    effectiveTo
                    billingGroup {
                        id
                        addresses {
                            id
                        }
                    }
                }
            }
        }
    }`
    const addressUses = useFragment(allAddressUseCodesDropdown, addressUseCodes).map((a) => {return {value: a.id, label: a.display}});
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        billingGroupId: billingGroupId,
        addressUseCodeId: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        postalCode: '',
        phoneNumber: '',
        effectiveFrom: new Date(),
        effectiveTo: new Date(9999,11,31)
    }
    const schema = yup.object({
        addressUseCodeId: yup.string().required("Use Code is required"),
        addressLine1: yup.string().required("Line 1 is required"),
        city: yup.string().required("City is required"),
        state: yup.string().length(2, "Please use the abbreviation for the state").required("State is required"),
        postalCode: yup.string().max(9).required("Postal Code is required"),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    
    const handleSubmit = (data) => {
        commitMutation({variables: {...data},
            onCompleted: (data) => {
                if(data.billingGroup) {
                    toast.success("Address for Billing Group successfully created")
                    methods.reset();
                    onClose();
                }
            }})
    }
    
    return (
        <FormModal showModal={showModal} 
                   onClose={onClose}
                   title={"New Address"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={addressUses} controlId={"addressUseCodeId"} label={"Address Use Code"} />
            <HorizontalFormInput controlId={"addressLine1"} label={"Line 1"} />
            <HorizontalFormInput controlId={"addressLine2"} label={"Line 2"} />
            <HorizontalFormInput controlId={"addressLine3"} label={"Line 3"} />
            <HorizontalFormInput controlId={"city"} label={"City"} />
            <HorizontalFormInput controlId={"state"} label={"State"} />
            <HorizontalFormInput controlId={"postalCode"} label={"Postal Code"} />
            <HorizontalFormMaskedInput controlId={"phoneNumber"} 
                                       label={"Phone Number"} 
                                       mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                       placeholder={"(XXX) XXX-XXXX"}
                                       type={"tel"}
            />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
NewBillingGroupAddressModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    billingGroupId: PropTypes.string.isRequired,
    addressUseCodes: PropTypes.array.isRequired
}
export default NewBillingGroupAddressModal
import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {Card} from "react-bootstrap";
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import NewOrganizationModal from "./NewOrganizationModal";
import {useUser} from "../../hooks/useUser";
import {LinkCell} from "../../components/common/advance-table/CustomCells";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";

const OrganizationsTable = ({organizations, networkOwners}) => {
    const orgs = useFragment(
        graphql`
            fragment OrganizationsTable_Organization on Organization @relay(plural: true) {
                id,
                primaryName,
                networkOwner {
                    name
                    id
                }
            }
        `, organizations)
    const [showNewModal, setShowNewModal] = useState(false);
    const {hasPermission} = useUser()
    const columns = useMemo( () => [
        {
            accessor: 'primaryName',
            Header: 'Primary Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { primaryName, id } = rowData.row.original;
                return <LinkCell name={primaryName} to={"/organizations/" + id} hasAvatar />
            }
        },
        {
            accessor: 'networkOwner.name',
            Header: 'Network Owner',
            Cell: rowData => {
                const {networkOwner} = rowData.row.original;
                return <LinkCell name={networkOwner.name} to={"/network-owners/" + networkOwner.id} />
            }
        },
    ],[]);
    return (<>
            <AdvanceTableWrapper
                columns={columns}
                data={orgs}
                sortable
                pagination
                perPage={25}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <IndexTableHeader name={"Organizations"} 
                                          onNewClick={() => {setShowNewModal(true)}} 
                                          showNew={hasPermission("Organizations.Create")}
                                          table
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableFooter rowCount={orgs.length}
                                            table
                                            rowInfo
                                            navButtons
                                            rowsPerPageSelection
                        />
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewOrganizationModal showModal={showNewModal} onHide={() => {setShowNewModal(false)}} networkOwners={networkOwners} />
        </>
    )
}
OrganizationsTable.propTypes = {
    organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
    networkOwners: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default OrganizationsTable

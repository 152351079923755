import React, {Suspense} from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import RelayEnvironment from "./relay/RelayEnvironment";
import {RelayEnvironmentProvider} from 'react-relay';
import {Spinner} from "react-bootstrap";
import {CloseButton} from "./components/common/Toast";
import {toast, ToastContainer} from "react-toastify";
import {UserProvider} from "./hooks/useUser";

const container = document.getElementById('main');
const root = createRoot(container);


root.render(
  <React.StrictMode>
          <RelayEnvironmentProvider environment={RelayEnvironment}>
              <Suspense fallback={<Spinner/>}>
                  <Main>
                      <App/>
                  </Main>
              </Suspense>
          </RelayEnvironmentProvider>
          <ToastContainer
              closeButton={CloseButton}
              icon={false}
              position={toast.POSITION.BOTTOM_RIGHT}
              autoClose={5000}
          />
  </React.StrictMode>
);

import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {practitionersData} from "../../data/practitioners/practitionersData";
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, first_name, last_name } = rowData.row.original;
      return (
        <Link to={"/practitioners/" + id}>
          <Flex alignItems="center">
            <Avatar size="xl" name={last_name + ", " + first_name} className="me-2" />
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{last_name + ", " + first_name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'npi_number', 
    Header: 'NPI'
  },
  {
    accessor: 'organization',
    Header: 'Organization'
  },
  {
    accessor: 'address',
    Header: 'Address'
  },
  {
    accessor: 'city',
    Header: 'City'
  },
  {
    accessor: 'state',
    Header: 'State'
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: () => {
      return (
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item href="#!">Edit</Dropdown.Item>
            <Dropdown.Item href="#!">Term</Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const Practitioners = ({perPage, ...props}) => {
  
  const [practitioners] = useState(Object.prototype.hasOwnProperty.call(props, 'data') ? props.data : practitionersData);
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={practitioners}
      selection
      sortable
      pagination
      perPage={perPage || 10}
    >
      <Card className="mb-3">
        <Card.Header>
          <IndexTableHeader name={"Providers"} onNewClick={() => {}} table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter 
              table
              rowInfo
              rowCount={practitioners.length} 
              navButtons 
              rowsPerPageSelection 
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Practitioners;

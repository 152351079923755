import React from 'react';
import PropTypes from 'prop-types';
import {Accordion, Card, Form} from "react-bootstrap";
import BillingGroupAdd from "./BillingGroupAdd";
import BillingGroupChange from "./BillingGroupChange";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";

const BillingGroupTab = ({billingGroupsAdded, billingGroupsChanged}) => {
    const billingGroupFragment = graphql`
    fragment BillingGroupTabFragment on RosterLoadBillingGroup @relay(plural : true) {
        id
        status
        properties {
            id
            propertyName
            displayOrder
            required
            isKey
            proposedValue
            originalValue
            possibleValues {
                showsUp
                value
            }
        }
    }`
    const propertyFragment = graphql`
    fragment BillingGroupTabPropertyFragment on RosterLoadBillingGroupProperty @relay(plural: true) {
        id
        propertyName
        displayOrder
        required
        isKey
        proposedValue
        originalValue
        possibleValues {
            showsUp
            value
        }
    }`
    const changePropertyMutation = graphql`
        mutation BillingGroupTabPropertyMutation($id: ID!, $proposedValue: StringInput) {
            rosterLoad {
                modifyRosterLoadBillingGroupProperty(input: {id: $id, proposedValue: $proposedValue}) {
                    property {
                        id
                        billingGroup {
                            id
                            properties {
                                ...BillingGroupTabPropertyFragment
                            }
                        }
                    }
                }
            }
        }`
    const changeBillingGroupStatusMutation = graphql`
    mutation BillingGroupTabStatusMutation($id: ID!, $status:String!) {
        rosterLoad {
            modifyRosterLoadBillingGroup(input: {billingGroupId: $id, status: $status}) {
                rosterLoadBillingGroup {
                    id
                    status
                }
            }
        }
    }`
    const validateStatus = (values, formValues) => {
        return values.status !== "Undecided";
    }
    const {fields} = useFieldArray({name: "node.addedBillingGroups", rules: {validate: validateStatus}});
    const {fields: changedFields} = useFieldArray({name: "node.changedBillingGroups"});
    const handleCommit = () => {
        
    }
    return (
        <Card>
            <Card.Body className={"bg-light"}>
                {fields && fields.length > 0 && 
                    <Accordion defaultActiveKey={fields.some(s => s.status === "Undecided") ? fields.findIndex(s => s.status === "Undecided").toString() : "0"} className={"mb-4"}>
                        {fields.map((field, index) => (
                            <BillingGroupAdd billingGroupIndex={index} 
                                             billingGroupAdded={field} 
                                             key={field.id} 
                                             changePropertyMutation={changePropertyMutation}
                                             changeStatusMutation={changeBillingGroupStatusMutation}
                            />
                        ))}
                    </Accordion>
                }
                {changedFields && changedFields.length > 0 && 
                    <Accordion defaultActiveKey={"0"} className={"mb-4"}>
                        {changedFields.map((field, index) => {
                        return <BillingGroupChange billingGroupChanged={field} 
                                                   billingGroupIndex={index} 
                                                   key={field.id}
                                                   changePropertyMutation={changePropertyMutation}
                                                   changeStatusMutation={changeBillingGroupStatusMutation}
                        />
                })}
                    </Accordion>
                }
            </Card.Body>
        </Card>
    )
}
BillingGroupTab.defaultProps = {}
BillingGroupTab.propTypes = {}
export default BillingGroupTab
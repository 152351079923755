import React from 'react';
import {useWatch} from "react-hook-form";
import {Nav} from "react-bootstrap";
import SoftBadge from "../../../components/common/SoftBadge";
import {getDecidedFromArrays} from "../helpers";

const TabNav = () => {
    const watchedArrays = useWatch({name: ["node.addedBillingGroups", "node.changedBillingGroups"]});
    const billingGroupsDecided = getDecidedFromArrays(watchedArrays[0], watchedArrays[1]);
    return (
        <Nav variant={"tabs"}>
            <Nav.Item><Nav.Link eventKey={"rosterLoadDetails"}>Roster Load Details</Nav.Link></Nav.Item>
        <Nav.Item>
            <Nav.Link eventKey={"billingGroups"}>Billing Groups <SoftBadge pill bg={"primary"}>{billingGroupsDecided} / {watchedArrays[0].length + watchedArrays[1].length}</SoftBadge> </Nav.Link>
        </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey={"providers"}>Providers <SoftBadge pill bg={"primary"}>3/3</SoftBadge></Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey={"locations"}>Practice Locations <SoftBadge pill bg={"primary"}>4/6</SoftBadge></Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link eventKey={"relationships"}>Relationships <SoftBadge pill bg={"primary"}>10/10</SoftBadge></Nav.Link>
    </Nav.Item>
</Nav>
    )
}
export default TabNav
import graphql from "babel-plugin-relay/macro"

export const contractLineOfService = graphql`
    fragment ContractLinesOfServiceFragments_LinesOfService on LegalContractLineOfService @relay(plural: true) {
        id
        lineOfService {
            id
            name
        }
        otherServicesDiscountPercentage
        percentageOfBase
        isComplex
        effectiveFrom
        effectiveTo
    }
`
import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {allContractsDropdown} from "../contracts/ContractFragments";
import {useFragment} from "react-relay";
import {useForm} from "react-hook-form";
import NewModal from "../../components/common/modals/NewModal";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormSelect from "../../components/common/horizontal-form-inputs/HorizontalFormSelect";

const NewBillingGroupModal = ({showModal, onClose, contracts}) => {
    const NewBillingGroupMutation = graphql`
        mutation NewBillingGroupModalMutation($name: String!, $legalContractId: ID!, $npi: String!) {
            billingGroup {
                create(input: {name: $name, legalContractId: $legalContractId, npi: $npi}) {
                    billingGroup {
                        id
                    }
                }
            }
        }`
    const allContracts = useFragment(allContractsDropdown, contracts).map(c => {return {value: c.id, label: c.name}});
    const defaultValues = {
        name:'',
        legalContractId: null,
        npi: ''
    }
    const methods = useForm({defaultValues: defaultValues})
    return (
        <NewModal showModal={showModal} 
                  onClose={onClose} 
                  title={"New Billing Group"}
                  methods={methods}
                  redirectUrl={"/billing-groups/"}
                  mutation={NewBillingGroupMutation}
                  returnedIdKey={"billingGroup.create.billingGroup.id"}
        >
            <HorizontalFormInput controlId={"name"} label={"Name"} validation={{required: "Name is required"}} />
            <HorizontalFormSelect options={allContracts} controlId={"legalContractId"} label={"Contract"} validation={{required: "Contract is required"}} />
            <HorizontalFormInput controlId={"npi"} label={"Npi"} validation={{required: "Npi is required"}} />
        </NewModal>
    )
}
NewBillingGroupModal.defaultProps = {}
NewBillingGroupModal.propTypes = {}
export default NewBillingGroupModal
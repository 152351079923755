import React from 'react';
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import graphql from "babel-plugin-relay/macro";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import FormModal from "../../../components/common/modals/FormModal";
import {useMutation} from "react-relay";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
const NewContractTermModal = ({showModal, onClose, possibleTermTypes, contractId}) => {
    const mutation = graphql`
        mutation NewContractTermModal_Mutation($contractId: ID!, $termTypeId: ID!, $value: String!) {
            legalContract {
                addTerm(input: {legalContractId: $contractId, termTypeId: $termTypeId, value: $value}) {
                    legalContractTerm {
                        id
                        contract {
                            id
                            terms {
                                ...ContractTerms_Terms
                            }
                        }
                    }
                }
            }
        }`
    const [commitMutation, isInFlight] = useMutation(mutation);
    const termTypes = possibleTermTypes.map((l => { return {value: l.id, label:l.description}}));
    const schema = yup.object({
        termTypeId: yup.string().required("Term Type is required"),
        value: yup.mixed()
        .when('termTypeId', () => {
            const valueType = possibleTermTypes.find(tt => tt.id === selectedTermTypeId)?.valueType;
            if(valueType === "DAYS") {
                return yup.number().typeError("Days must be a number").required("Days is required")
            }
            if(valueType === "DATE") {
                return yup.date().typeError("Date must be a valid date").required("Date is required")
            }
            return yup.string().required("Value is required");
        })
    })
    const methods = useForm({values: {contractId: contractId, termTypeId: possibleTermTypes.length > 0 ? possibleTermTypes[0].id : ''}, resolver: yupResolver(schema), mode:"onSubmit"});
    const selectedTermTypeId = methods.watch("termTypeId");
    const valueType = possibleTermTypes.find(tt => tt.id === selectedTermTypeId)?.valueType;
    
    const booleanOptions = [{value: "Yes", label: "Yes"}, {value: "No", label: "No"}];
    
    function convertValueToString(valueType, value) {
        if (valueType === "DAYS") {
            return value.toString();
        }
        if (valueType === "DATE") {
            return dayjs.utc(value).format('MM/DD/YYYY');
        }
        return value;
    }
    
    const handleSubmit = (data) => {
        commitMutation({
            variables: {contractId: data.contractId, termTypeId: data.termTypeId, value: convertValueToString(valueType, data.value)},
            onCompleted: (data) => {
                toast.success("Term for Contract created");
                onClose();
            }
        })
    }
    
    return (
        <FormModal showModal={showModal} 
                   onClose={onClose} 
                   title={"New Contract Term"} 
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
                   buttonText={"Create"}
                   inFlightButtonText={"Creating"}
        >
            <HorizontalFormSelect options={termTypes}
                                  controlId={"termTypeId"}
                                  label={"Term Type"}
                                  onChange={(e) => {
                                      methods.resetField("value");
                                  }}
            />
            { valueType === "BOOL" && <HorizontalFormSelect options={booleanOptions} controlId={"value"} label={"Value"} /> }
            { valueType === "DAYS" && <HorizontalFormInput type={"number"} controlId={"value"} label={"Days"} /> }
            { valueType === "DATE" && <HorizontalFormDate controlId={"value"} label={"Date"} /> }
        </FormModal>
    )
}
NewContractTermModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    possibleTermTypes: PropTypes.array.isRequired,
    contractId: PropTypes.string.isRequired
}
export default NewContractTermModal
/**
 * @generated SignedSource<<6f7c79cbfcf434bb0770d5f5c125ea54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "allOrganizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationsTable_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkOwner",
        "kind": "LinkedField",
        "name": "allNetworkOwners",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NetworkOwnersFragments_AllNetworkOwnersDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganizationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "allOrganizations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NetworkOwner",
            "kind": "LinkedField",
            "name": "networkOwner",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkOwner",
        "kind": "LinkedField",
        "name": "allNetworkOwners",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ffaa1e7560c49bccc4643a769135dba",
    "id": null,
    "metadata": {},
    "name": "OrganizationsQuery",
    "operationKind": "query",
    "text": "query OrganizationsQuery {\n  allOrganizations {\n    ...OrganizationsTable_Organization\n    id\n  }\n  allNetworkOwners {\n    ...NetworkOwnersFragments_AllNetworkOwnersDropdown\n    id\n  }\n}\n\nfragment NetworkOwnersFragments_AllNetworkOwnersDropdown on NetworkOwner {\n  id\n  name\n}\n\nfragment OrganizationsTable_Organization on Organization {\n  id\n  primaryName\n  networkOwner {\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "f6d900f3c265f04ab55b7fff25235b62";

export default node;

import {useEffect, useState} from "react";
import {getItemFromStore, setItemToStore} from "../helpers/utils";

export const useLocalStorage = (keyName, defaultValue) => {
    const [storedValue, setStoredValue] = useState(getItemFromStore(keyName, defaultValue));
    
    useEffect(() => {
        window.addEventListener('storage', handleLocalStorageChange);
        return () => {
            window.removeEventListener('storage', handleLocalStorageChange);
        }
    },[])
    const handleLocalStorageChange = () => {
        setStoredValue(getItemFromStore(keyName,null))
    }
    const setValue = (newValue) => {
        setItemToStore(keyName, JSON.stringify(newValue));
        setStoredValue(newValue);
    }
    return [storedValue, setValue];
}

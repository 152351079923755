/**
 * @generated SignedSource<<dd9e443793d861d81f5672d60511c4c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "roleId",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleInfo_RoleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RoleMutationGroup",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RoleModifyRolePayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RoleInfo_role"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RoleInfo_RoleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RoleMutationGroup",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RoleModifyRolePayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d0c1a1b8a6a2f7e9e321316ad4fe0f4",
    "id": null,
    "metadata": {},
    "name": "RoleInfo_RoleMutation",
    "operationKind": "mutation",
    "text": "mutation RoleInfo_RoleMutation(\n  $id: ID!\n  $name: String\n  $description: String\n) {\n  role {\n    modify(input: {roleId: $id, name: $name, description: $description}) {\n      role {\n        ...RoleInfo_role\n        id\n      }\n    }\n  }\n}\n\nfragment RoleInfo_role on Role {\n  id\n  name\n  description\n}\n"
  }
};
})();

node.hash = "781a51504b96bc0949b447ad1c052ef9";

export default node;

/**
 * @generated SignedSource<<c2453aa58cb3aba39fe96f1158767304>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NetworkOwnerInfo_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkOwnerMutationGroup",
        "kind": "LinkedField",
        "name": "networkOwner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "NetworkOwnerModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NetworkOwner",
                "kind": "LinkedField",
                "name": "networkOwner",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "NetworkOwnerInfo_NetworkOwner"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NetworkOwnerInfo_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkOwnerMutationGroup",
        "kind": "LinkedField",
        "name": "networkOwner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "NetworkOwnerModifyPayload",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NetworkOwner",
                "kind": "LinkedField",
                "name": "networkOwner",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f3eb16b0051dbcd3fc526e492441df9",
    "id": null,
    "metadata": {},
    "name": "NetworkOwnerInfo_Mutation",
    "operationKind": "mutation",
    "text": "mutation NetworkOwnerInfo_Mutation(\n  $id: ID!\n  $name: String!\n) {\n  networkOwner {\n    modify(input: {id: $id, name: $name}) {\n      networkOwner {\n        ...NetworkOwnerInfo_NetworkOwner\n        id\n      }\n    }\n  }\n}\n\nfragment NetworkOwnerInfo_NetworkOwner on NetworkOwner {\n  id\n  name\n}\n"
  }
};
})();

node.hash = "c7dacb81e7542ef2afa199d055b02fd3";

export default node;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import NewContractLinesOfServiceModal from "./NewContractLinesOfServiceModal";
import {BooleanCell, DateCell, LinkCell} from "../../../components/common/advance-table/CustomCells";
import {linesOfServiceDropdown} from "../../lines-of-service/LinesOfServiceFragments";
import {useUser} from "../../../hooks/useUser";
import {contractLineOfService} from "./ContractLinesOfServiceFragments";

const columns = [
    {
        accessor: 'lineOfService.name',
        Header: 'Name',
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const {lineOfService, id} = rowData.row.original
            return <LinkCell name={lineOfService.name} to={"lines-of-service/" + id}/>
        }
    },
    {
        accessor: 'isComplex',
        Header: 'Is Complex',
        cellProps: {
            className: 'py-2'
        },
        Cell: BooleanCell
    },
    {
        accessor: 'effectiveFrom',
        Header: 'Effective From',
        cellProps: {
            className: 'py-2'
        },
        Cell: DateCell
    },
    {
        accessor: 'effectiveTo',
        Header: 'Effective To',
        cellProps: {
            className: 'py-2'
        },
        Cell: DateCell
    },
    {
        accessor: 'percentageOfBase',
        Header: 'Percentage of Base',
        cellProps: {
            className: 'py-2'
        }
    },
    {
        accessor: 'otherServicesDiscountPercentage',
        Header: 'Other Services Discount Percentage',
        cellProps: {
            className: 'py-2'
        }
    }
];
const ContractLinesOfService = ({linesOfService, contractId, possibleLinesOfService, contractStartDate, contractEndDate}) => {
    const los = useFragment(contractLineOfService, linesOfService)
    const [showModal, setShowModal] = useState(false)
    const {hasPermission} = useUser();
    const plos = useFragment(linesOfServiceDropdown, possibleLinesOfService);
    const availLinesOfService = plos.filter(plos => !los.some(x => x.lineOfService.id === plos.id))
    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={los}
                sortable
                pagination
                perPage={10}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowModal(true)}}
                                         title={"Lines of Service"} 
                                         table
                                         showNew={hasPermission("LegalContractLinesOfService.Create") && availLinesOfService.length > 0}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable table 
                                      headerClassName="bg-200 text-900 text-nowrap align-middle" 
                                      rowClassName="align-middle white-space-nowrap" 
                                      tableProps={{
                                          size: 'sm', 
                                          striped: true, 
                                          className: 'fs--1 mb-0 overflow-hidden'}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewContractLinesOfServiceModal showModal={showModal}
                                            onClose={() => {setShowModal(false)}}
                                            contractId={contractId}
                                            possibleLinesOfService={availLinesOfService}
                                            contractStartDate={contractStartDate}
                                            contractEndDate={contractEndDate}
            />
        </>
    )
}
ContractLinesOfService.propTypes = {
    linesOfService: PropTypes.array.isRequired,
    contractId: PropTypes.string.isRequired,
    possibleLinesOfService: PropTypes.array.isRequired,
    contractStartDate: PropTypes.string.isRequired,
    contractEndDate: PropTypes.string.isRequired
}
export default ContractLinesOfService
import React from 'react';
import PropTypes from 'prop-types';
import {useUser} from "../../hooks/useUser";
import {Navigate, Outlet, useLocation} from "react-router-dom";

const ProtectedRoute = ({permissionName, redirect, children}) => {
    const {user, hasPermission} = useUser();
    const location = useLocation();
    if(user === undefined || user === null) {
        return <Navigate to={"/login"} state={{from: location}} replace />
    }
    if(permissionName !== undefined && !hasPermission(permissionName)) {
        return <Navigate to={redirect !== undefined ? redirect : '/'} state={{from: location}} replace />
    }
    return children ? children : <Outlet />
}
ProtectedRoute.propTypes = {
    permissionName: PropTypes.string,
    children: PropTypes.node
}
export default ProtectedRoute
import React from 'react';
import PropTypes from 'prop-types';
import {Form, Modal} from "react-bootstrap";
import {FormProvider} from "react-hook-form";
import IconButton from "../IconButton";

const FormModal = ({showModal, onClose, title, methods, onSubmit, isInFlight, buttonText, inFlightButtonText, children}) => {
    const handleCloseClick = () => {
        methods.reset();
        onClose();
    }
    return (
        <Modal show={showModal} onHide={handleCloseClick} centered size={"lg"} aria-labelledby={"contained-modal-title-vcenter"}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
                <FormProvider {...methods}>
                    <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                        <Modal.Body>
                            {children}
                        </Modal.Body>
                        <Modal.Footer>
                            <IconButton
                                className="ms-2"
                                iconClassName="fs--2 me-1"
                                variant="falcon-default"
                                size="sm"
                                icon="check"
                                type={"submit"}
                            >
                                {isInFlight ? inFlightButtonText : buttonText}
                            </IconButton>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
        </Modal>
    )
}
FormModal.defaultProps = {
    buttonText: "Save",
    inFlightButtonText: "Saving"
}
FormModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    methods: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isInFlight: PropTypes.bool.isRequired,
    buttonText: PropTypes.string,
    inFlightButtonText: PropTypes.string
}
export default FormModal
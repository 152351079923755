import React from 'react';
import PropTypes from 'prop-types';
import {UserProvider} from "../hooks/useUser";
import {Outlet, useLoaderData} from "react-router-dom";

const AuthLayout = () => {
    const data = useLoaderData();
    return (
        <UserProvider userData={data}>
            <Outlet />
        </UserProvider>
    )
}
export default AuthLayout
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import IconButton from "../IconButton";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useMutation} from "react-relay";

const DeletePanel = ({thingToDelete, id, mutation, redirectUrl, children}) => {
    const [showModal, setShowModal] = useState(false)
    const [commitMutation, isInFlight] = useMutation(mutation);
    const navigate = useNavigate()
    const handleDeleteClick = () => {
        commitMutation({variables: {id: id}, 
            onCompleted: (data) => {
                if(!Object.values(data).every((x) => (x === null))) {
                    toast.success(thingToDelete + " has been deleted");
                    navigate(redirectUrl, {replace: true});
                }
            }})
    }
    return (
        <>
        <Row>
            <Col>
                <Card className={"mb-3"}>
                    <Card.Body>
                        <Card.Title>
                            Delete {thingToDelete}
                        </Card.Title>
                        <Card.Text>{children}</Card.Text>
                        <IconButton
                            iconClassName="fs--2 me-1"
                            variant="falcon-default"
                            size="sm"
                            icon="trash"
                            title={"Delete " +thingToDelete}
                            onClick={() => {setShowModal(true)}}
                        >
                            Delete
                        </IconButton>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered size={"lg"} aria-labelledby={"contained-modal-title-vcenter"}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete {thingToDelete}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this {thingToDelete}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"falcon-default"} onClick={handleDeleteClick}>{isInFlight ? "Deleting..." : "Delete"}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
DeletePanel.propTypes = {
    thingToDelete: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    mutation: PropTypes.object.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
}
export default DeletePanel
import React from 'react';
import Networks from "../networks/Networks";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import NetworkOwnerInfo from "./NetworkOwnerInfo";
import {useLoaderData} from "react-router-dom";
import {usePreloadedQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";

const NetworkOwnerDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
        query NetworkOwnerDetailsQuery($id: ID!) {
            node(id: $id) { 
                ... on NetworkOwner {
                    name
                    ...NetworkOwnerInfo_NetworkOwner
                    networks {
                        id
                    }
                }
            }
        }
    `, loader.networkOwnerDetailsQuery)
  return (
    <>
        <DetailsPageHeader iconText={"Network Owner"} iconType={"user-circle"} title={data.node.name} />
        <NetworkOwnerInfo networkOwner={data.node} />
        {/*<Networks />*/}
    </>
  );
};

export default NetworkOwnerDetails;

import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import NewModal from "../../components/common/modals/NewModal";
import {useForm} from "react-hook-form";

const NewRoleModal = ({showModal, onHide}) => {
    const NewRoleModalMutation = graphql`
        mutation NewRoleModalMutation($name: String!, $description: String!) {
            role {
                create(input: {name: $name, description: $description, permissionIds: []}) {
                    role {
                        id
                        name
                    }
                }
            }
        }`
    const methods = useForm({defaultValues: {name: '', description: ''}})
    return (
        <NewModal showModal={showModal} title={"New Role"}
                  mutation={NewRoleModalMutation}
                  redirectUrl={"/roles/"}
                  returnedIdKey={"role.create.role.id"}
                  onClose={onHide} methods={methods}>
            <HorizontalFormInput controlId={"name"}
                                 label={"Name"}
                                 validation={{required: "Name is required"}}
            />
            <HorizontalFormInput controlId={"description"}
                                 label={"Description"}
                                 validation={{required: "Description is required"}}
            />
        </NewModal>
    )
}
NewRoleModal.defaultProps = {
    showModal: false
}
NewRoleModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}
export default NewRoleModal
import React from 'react';
import {useParams} from 'react-router-dom';
import {licensesData} from "../../data/licenses/licensesData";
import PayerDetailsHeader from "./PayerDetailsHeader";
import Licenses from "../licenses/Licenses";
import OrganizationLog from "../organizations/OrganizationLog";


const PayerDetails = (props) => {
    let params = useParams();
    const licenses = licensesData.filter(l => l.payerId === params.id);
    console.log('licenses', licenses);
    return (
        <>
            <PayerDetailsHeader />
            <Licenses licenses={licenses}/>
            <OrganizationLog />
        </>
    );
};

export default PayerDetails;

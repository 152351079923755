import React from 'react';
import PropTypes from 'prop-types';
import {Card} from "react-bootstrap";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import IndexTableHeader from "../../components/common/headers/IndexTableHeader";
import {DateCell, LinkCell} from "../../components/common/advance-table/CustomCells";
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {useUser} from "../../hooks/useUser";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";
const defaultColumns = [
    {
        accessor: 'name',
        Header: 'Contract Name',
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const {name, id} = rowData.row.original
            return <LinkCell name={name} to={"/contracts/" + id} hasAvatar />
        }
    },
    {
        accessor: 'network.name',
        Header: 'Network',
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { network } = rowData.row.original;
            return <LinkCell name={network.name} to={"/networks/" + network.id} />
        }
    },
    {
        accessor: 'organization.name',
        Header: 'Organization',
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { organization } = rowData.row.original;
            return <LinkCell name={organization.primaryName} to={"/organizations/" + organization.id} />
        }
    },
    {
        accessor: 'startDate',
        Header: 'Start Date',
        Cell: DateCell
    },
    {
        accessor: 'endDate',
        Header: 'End Date',
        Cell: DateCell
    }
];
const ContractsTable = ({contracts, columnsOverride, onNewClick}) => {
    const {hasPermission} = useUser();
    const legalContracts = useFragment(
        graphql`
            fragment ContractsTable_Contract on LegalContract @relay(plural: true) {
                id
                name
                startDate
                endDate
                network {
                    id
                    name
                }
                organization {
                    id
                    primaryName
                }
            }
        `, contracts)
    return (
        <>
        <AdvanceTableWrapper
            columns={columnsOverride || defaultColumns}
            data={legalContracts}
            sortable
            pagination
            perPage={25}
        >
            <Card className="mb-3">
                <Card.Header>
                    <IndexTableHeader onNewClick={onNewClick} name={"Contracts"} showNew={hasPermission("Contracts.Create")} table />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter rowCount={legalContracts.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
        </>
    )
}
ContractsTable.propTypes = {
    contracts: PropTypes.array.isRequired,
    onNewClick: PropTypes.func.isRequired,
    columnsOverride: PropTypes.array
}
export default ContractsTable
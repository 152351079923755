/**
 * @generated SignedSource<<c09373b43343d93ff51e46a32c0981d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "networkId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LegalContractMutationGroup",
    "kind": "LinkedField",
    "name": "legalContract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "endDate",
                "variableName": "endDate"
              },
              {
                "kind": "Variable",
                "name": "name",
                "variableName": "name"
              },
              {
                "kind": "Variable",
                "name": "networkId",
                "variableName": "networkId"
              },
              {
                "kind": "Variable",
                "name": "organizationId",
                "variableName": "organizationId"
              },
              {
                "kind": "Variable",
                "name": "startDate",
                "variableName": "startDate"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "LegalContractModifyPayload",
        "kind": "LinkedField",
        "name": "create",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalContract",
            "kind": "LinkedField",
            "name": "legalContract",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewContractModalMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewContractModalMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "2c0dde1c677b011a0ccf2838320a3957",
    "id": null,
    "metadata": {},
    "name": "NewContractModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewContractModalMutation(\n  $name: String\n  $networkId: ID!\n  $organizationId: ID!\n  $startDate: DateOnly!\n  $endDate: DateOnly!\n) {\n  legalContract {\n    create(input: {name: $name, networkId: $networkId, organizationId: $organizationId, startDate: $startDate, endDate: $endDate}) {\n      legalContract {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "cf79c4ff1b0efaba3ee21680d2300b24";

export default node;

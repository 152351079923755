/**
 * @generated SignedSource<<a9b6ffca12747bfeda83636d2482e250>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attachmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "legalContractId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uploadSuccessful"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "attachmentId",
        "variableName": "attachmentId"
      },
      {
        "kind": "Variable",
        "name": "legalContractId",
        "variableName": "legalContractId"
      },
      {
        "kind": "Variable",
        "name": "uploadSuccessful",
        "variableName": "uploadSuccessful"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewContractAttachmentModalReportStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "LegalContractModifyPayload",
            "kind": "LinkedField",
            "name": "reportAttachmentUploadStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContract",
                "kind": "LinkedField",
                "name": "legalContract",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContractAttachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ContractAttachments_Attachments"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewContractAttachmentModalReportStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LegalContractMutationGroup",
        "kind": "LinkedField",
        "name": "legalContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "LegalContractModifyPayload",
            "kind": "LinkedField",
            "name": "reportAttachmentUploadStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegalContract",
                "kind": "LinkedField",
                "name": "legalContract",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LegalContractAttachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "size",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastModifiedTimestampUtc",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "downloadUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AttachmentCategory",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4521efcf38d57e4d912fb87b7005c048",
    "id": null,
    "metadata": {},
    "name": "NewContractAttachmentModalReportStatusMutation",
    "operationKind": "mutation",
    "text": "mutation NewContractAttachmentModalReportStatusMutation(\n  $legalContractId: ID!\n  $attachmentId: ID!\n  $uploadSuccessful: Boolean!\n) {\n  legalContract {\n    reportAttachmentUploadStatus(input: {legalContractId: $legalContractId, attachmentId: $attachmentId, uploadSuccessful: $uploadSuccessful}) {\n      legalContract {\n        id\n        attachments {\n          ...ContractAttachments_Attachments\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ContractAttachments_Attachments on LegalContractAttachment {\n  id\n  fileName\n  size\n  lastModifiedTimestampUtc\n  downloadUrl\n  category {\n    id\n    name\n  }\n}\n"
  }
};
})();

node.hash = "1e1a79a050b2fa6d6d08b6ce600df01b";

export default node;

/**
 * @generated SignedSource<<a8e7a4ebccdd6c05e21a6739b2ad48de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Network",
  "kind": "LinkedField",
  "name": "allNetworks",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "NetworkOwner",
      "kind": "LinkedField",
      "name": "networkOwner",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NetworksQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkOwner",
        "kind": "LinkedField",
        "name": "allNetworkOwners",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NetworkOwnersFragments_AllNetworkOwnersDropdown"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NetworksQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkOwner",
        "kind": "LinkedField",
        "name": "allNetworkOwners",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6dbe3c35e91884e59d68dfff2b5efe13",
    "id": null,
    "metadata": {},
    "name": "NetworksQuery",
    "operationKind": "query",
    "text": "query NetworksQuery {\n  allNetworks {\n    id\n    name\n    networkOwner {\n      id\n      name\n    }\n  }\n  allNetworkOwners {\n    ...NetworkOwnersFragments_AllNetworkOwnersDropdown\n    id\n  }\n}\n\nfragment NetworkOwnersFragments_AllNetworkOwnersDropdown on NetworkOwner {\n  id\n  name\n}\n"
  }
};
})();

node.hash = "5f45799c7aec814bbab12e3912f35a9c";

export default node;

export const userRoutes = {
  label: 'Network Space',
  labelDisable: true,
  children: [
    {
      name: 'Roles',
      icon: 'key',
      to: '/roles',
      active: true,
      permission: 'Roles.Read'
    },
    {
      name: 'Users',
      icon: 'users',
      to: '/users',
      active: true,
      permission: 'Users.Read'
    },
    {
      name: 'Network Owners',
      icon: 'user-circle',
      to: '/network-owners',
      active: true,
      permission: 'NetworkOwners.Read'
    },
    {
      name: 'Payers',
      icon: 'user-friends',
      active: true,
      to: '/payers',
      permission: 'Payers.Read'
    },
    {
      name: 'Networks',
      icon: 'network-wired',
      to: '/networks',
      active: true,
      permission: 'Networks.Read'
    },
    {
      name: 'Organizations',
      icon: 'star',
      to: '/organizations',
      active: true,
      permission: 'Organizations.Read'
    },
    {
      name: 'Contracts',
      icon: 'pen-nib',
      to: '/contracts',
      active: true,
      permission: 'Contracts.Read'
    },
    {
      name: 'Providers',
      icon: 'heart',
      to: '/practitioners',
      active: true
    },
    {
      name: 'Facilities',
      icon: 'building',
      to: '/facilities',
      active: true,
      permission: 'Facilities.Read'
    },
    {
      name: 'Locations',
      icon: 'map-pin',
      to: '/locations',
      active: true,
      permission:'Locations.Read'
    },
    {
      name: 'Licensed LOS',
      icon: 'bookmark',
      to: '/licenses',
      active: true,
      permission: 'Licenses.Read'
    },
    {
      name: 'Billing Groups',
      icon: 'file-invoice-dollar',
      to: '/billing-groups',
      active: true,
      permission: 'BillingGroups.Read'
    },
    {
      name: 'Roster Loads',
      icon: 'upload',
      to: '/roster-loads',
      active: true,
      permission: 'RosterLoads.Read'
    }
  ]
};

export default [
  userRoutes
];

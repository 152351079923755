/**
 * @generated SignedSource<<be8947179f9c0ecd04d7e17b71501038>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewOrganizationContactModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMutationGroup",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "OrganizationContactModifyPayload",
            "kind": "LinkedField",
            "name": "createContact",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationContact",
                "kind": "LinkedField",
                "name": "organizationContact",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationContact",
                        "kind": "LinkedField",
                        "name": "contacts",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "OrganizationContacts_Contacts"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewOrganizationContactModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMutationGroup",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "OrganizationContactModifyPayload",
            "kind": "LinkedField",
            "name": "createContact",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationContact",
                "kind": "LinkedField",
                "name": "organizationContact",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationContact",
                        "kind": "LinkedField",
                        "name": "contacts",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d0e938964eed595902530e2b61e1052d",
    "id": null,
    "metadata": {},
    "name": "NewOrganizationContactModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewOrganizationContactModalMutation(\n  $organizationId: ID!\n  $lastName: String!\n  $firstName: String!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  organization {\n    createContact(input: {organizationId: $organizationId, lastName: $lastName, firstName: $firstName, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      organizationContact {\n        id\n        organization {\n          id\n          contacts {\n            ...OrganizationContacts_Contacts\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment OrganizationContacts_Contacts on OrganizationContact {\n  id\n  lastName\n  firstName\n  effectiveFrom\n  effectiveTo\n  organization {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "8ff659fd72867531560fd92ec209bea4";

export default node;

/**
 * @generated SignedSource<<4c5209a2f4e8606bdb898fa9b2c851c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "NetworkOwnersFragments_AllNetworkOwnersDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "NetworkOwner",
  "abstractKey": null
};

node.hash = "b90640230d29ab370e5a93e4dae87576";

export default node;

import React from 'react';
import {useParams} from 'react-router-dom';


import {licensesData} from "../../data/licenses/licensesData";

import {Col, Row} from "react-bootstrap";

import Identifiers from "../../components/identifiers/Identifiers";
import PractitionerDetailsHeader from "./PractitionerDetailsHeader";
import PractitionerInfo from "./PractitionerInfo";
import PractitionerCredentialsInfo from "./PractitionerCredentialsInfo";
import Locations from "../locations/Locations";
import Networks from "../networks/Networks";
import Licenses from "../licenses/Licenses";
import OrganizationLog from "../organizations/OrganizationLog";

const practitioner = {
    "id": 3,
    "first_name": "Brandon",
    "last_name": "Thurow",
    "qualification": "MD",
    "gender": "Male",
    "birthdate": "4/4/1969",
    "ssn": "401-84-7884",
    "npi_number": 1576562,
    "address": "0 Barby Point",
    "state": "AR",
    "city": "North Little Rock",
    "zip_code": "72199",
    "organization": "Access Medical Clinic, LLC",
    "languages" : [
        "(EN) English",
        "(ES) Spanish"
    ],
    "ethnicity" : "",
    "locations" : [
        {
            "name": 'Access Medical Clinic',
            "avatar": {
                "name": 'Access Medical Clinic'
            },
            "email": 'main@accessmedicalclinic.com',
            "phone": '(501) 200-1851',
            "address": '106 Highway 62 W  Salem, AR 72576',
            "county": "Fulton",
            "joined": '03/30/2015',
            "specialties": 'Emergency Medicine, Family Medicine, Hematology & Oncology',
            "hours": {
                "monday": "08:30AM-05:00PM",
                "tuesday": "08:30AM-05:00PM",
                "wednesday": "08:30AM-05:00PM",
                "thursday": "08:30AM-05:00PM",
                "friday": "08:30AM-05:00PM",
                "saturday": "closed",
                "sunday": "closed"
            }
        }
    ],
    "identifiers": [
        {
            "type": "NPI",
            "value": "1033346358",
            "effectiveFrom" : '2020-12-01'
        },
        {
            "type": "TIN",
            "value": "xxx-xx-7884",
            "effectiveFrom" : '2020-12-01'
        },
        {
            "type": "Medicare",
            "value": "125213452",
            "effectiveFrom" : '2020-12-01'
        },
        {
            "type": "Medicaid",
            "value": "11155666",
            "effectiveFrom" : '2020-12-01'
        },
    ]
};


const PractitionerDetails = () => {
    let params = useParams();
    const licenses = licensesData.filter(l => l.payerId === params.id);
    return (
        <>
            <PractitionerDetailsHeader practitioner={practitioner}/>
            <PractitionerInfo practitioner={practitioner}/>
            <Row>
                <Col className={"md-4"}>
                    <Identifiers identifiers={practitioner.identifiers} />
                </Col>
                <Col className={"md-6"}>
                    <PractitionerCredentialsInfo/>
                </Col>
            </Row>
            <Locations locations={practitioner.locations} withTaxonomies={true} />
            {/*<Networks/>*/}
            <Licenses/>
            <OrganizationLog/>
        </>
    );
};

export default PractitionerDetails;

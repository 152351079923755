export const licensesData = [
    {
        id: 1,
        effectiveFrom: '2/5/2019',
        effectiveTo: '<current>',
        network: 'Vantos',
        linesOfService: 'Arkansas Medicare, Arkansas Medicaid',
        payer: 'Arkansas Superior Select, Inc.',
        payerId: '1'
    },
    {
        id: 2,
        effectiveFrom: '12/15/2018',
        effectiveTo: '<current>',
        network: 'Vantos',
        linesOfService: 'Commercial BH',
        payer: 'Empower',
        payerId: '2'
    }
]
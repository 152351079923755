/**
 * @generated SignedSource<<d8838e2ed691ffce79a462270a1adf8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OrganizationsFragments_AllOrganizationsDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryName",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

node.hash = "44520a561935d828071974971cbe175f";

export default node;

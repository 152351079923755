import React from 'react';
import {components} from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <FontAwesomeIcon icon={"times"} size={"xs"} />
        </components.ClearIndicator>
    )
}
export default ClearIndicator
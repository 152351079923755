import React from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Row} from "react-bootstrap";
import {useFormContext} from "react-hook-form";

const HorizontalFormInput = ({controlId, label, readMode, validation, registerOptions, ...rest}) => {
    const {register, formState: {errors}} = useFormContext()
    return (
        <Form.Group as={Row} className={"mb-1"} controlId={controlId}>
            <Form.Label column xs={7} sm={3}>{label}</Form.Label>
            <Col>
                <Form.Control
                    size={"sm"}
                    plaintext={readMode}
                    readOnly={readMode}
                    isInvalid={!!errors[controlId]}
                    {...register(controlId, {...validation, ...registerOptions})}
                    {...rest}
                />
                {!!errors[controlId] && <Form.Control.Feedback type={"invalid"}>{errors[controlId].message}</Form.Control.Feedback>}
            </Col>
        </Form.Group>
    )
}
HorizontalFormInput.defaultProps = {
    ...Form.Control.defaultProps,
    readMode:false
}
HorizontalFormInput.propTypes = {
    ...Form.Control.propTypes,
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    readMode:PropTypes.bool,
    validation: PropTypes.object,
    registerOptions: PropTypes.object
}
export default HorizontalFormInput
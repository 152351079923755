import React from 'react';
import Flex from "../Flex";
import {Link} from "react-router-dom";
import Avatar from "../Avatar";
import {conformToMask} from "react-text-mask";
import CardDropdown from "../CardDropdown";
import DropdownItem from "react-bootstrap/DropdownItem";
import {utcTimestampToDisplayString} from "../../../helpers/utils";

export const DateCell = ({value}) => {
    let date = value.split('-')
    return (
        <>
            {value === "9999-12-31" ? "current" : date[1] + "/" + date[2] + "/" + date[0]}
        </>
    )
}

export const UtcTimestampCell = ({value}) => {
    return (
        <>
            {utcTimestampToDisplayString(value)}
        </>
    )
}

export const LinkCell = ({to, name, hasAvatar}) => {
    return <Link to={to}>
        <Flex alignItems="center">
            {hasAvatar && <Avatar size="l" name={name} className="me-2" />}
            <div className="flex-1">
                <h5 className="mb-0 fs--1">{name}</h5>
            </div>
        </Flex>
    </Link>
}

export const PhoneCell = ({value}) => {
    const mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const formatted = conformToMask(value ? value : '', mask, {guide: false})
    return <>{formatted.conformedValue}</>
}

export const BooleanCell = ({value}) => {
    return <>{value.toString()}</>
}

export const addEditAndDeleteCells = (canEdit, canDelete, columns, onEditClick, onDeleteClick) => {
    const col = {
        accessor: 'none',
        Headers: '',
        disableSortBy: true,
        cellProps: {
            className: 'text-end'
        }
    }
    if(canEdit && canDelete) {
        return columns.concat([{
            ...col,
            Cell: (rowData) => {
                return <CardDropdown>
                    <DropdownItem as={"button"} onClick={() => {onEditClick(rowData.row.original)}}>Edit</DropdownItem>
                    <DropdownItem as={"button"} onClick={() => {onDeleteClick(rowData.row.original)}}>Delete</DropdownItem>
                </CardDropdown>
            }
        }])
    }
    if(canEdit) {
        return columns.concat([{
            ...col,
            Cell: (rowData) => {
                return <CardDropdown>
                    <DropdownItem as={"button"} onClick={() => {onEditClick(rowData.row.original)}}>Edit</DropdownItem>
                </CardDropdown>
            }
        }])
    }
    if(canDelete) {
        return columns.concat([{
            ...col,
            Cell: (rowData) => {
                return <CardDropdown>
                    <DropdownItem as={"button"} onClick={() => {onDeleteClick(rowData.row.original)}}>Delete</DropdownItem>
                </CardDropdown>
            }
        }])
    }
    return columns;
}
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useFragment, useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import InfoPanel from "../../components/common/repeated-page-panels/InfoPanel";
import HorizontalFormInput from "../../components/common/horizontal-form-inputs/HorizontalFormInput";
import {toast} from "react-toastify";
import {useUser} from "../../hooks/useUser";

const NetworkOwnerInfo = ({networkOwner}) => {
    const networkOwnerMutation = graphql`
        mutation NetworkOwnerInfo_Mutation($id: ID!, $name: String!) {
            networkOwner {
                modify(input: {id: $id, name: $name}) {
                    networkOwner {
                        ...NetworkOwnerInfo_NetworkOwner
                    }
                }
            }
        }
    `
    const networkOwnerInfo = useFragment(graphql`
        fragment NetworkOwnerInfo_NetworkOwner on NetworkOwner {
            id
            name
        }
    `, networkOwner)
    
    const [readMode, setReadMode] = useState(true);
    const [commitMutation, isInFlight] = useMutation(networkOwnerMutation);
    const methods = useForm({values: {name: networkOwnerInfo.name}})
    const {hasPermission} = useUser()
    const onSubmit = (data) => {
        commitMutation({variables: {
            id: networkOwnerInfo.id,
                ...data
            }, onCompleted: () => {
                toast.success("Network Owner has been updated")
                setReadMode(true);
            }})
    }
    
    const handleCancelChanges = () => {
        methods.reset();
        setReadMode(true);
    }
    
    return (
        <FormProvider {...methods}>
            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <InfoPanel onUpdateDetailsClick={() => {setReadMode(false)}} 
                           onCancelChangesClick={handleCancelChanges}
                           name={networkOwnerInfo.name}
                           isInFlight={isInFlight}
                           readMode={readMode}
                           showUpdate={hasPermission("NetworkOwners.Update")}
                >
                    <HorizontalFormInput controlId={"name"} 
                                         label={"Name"} 
                                         readMode={readMode}
                                         validation={{required: "Name is required"}}
                    />
                </InfoPanel>
            </Form>
        </FormProvider>
    )
}
NetworkOwnerInfo.propTypes = {
    networkOwner: PropTypes.array
}
export default NetworkOwnerInfo
/**
 * @generated SignedSource<<ee50ecc13840bcf72f6e8f67cdf0e9b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine1"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine2"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressLine3"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressUseCodeId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "latitude"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "longitude"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationAddressId"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postalCode"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v12 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "addressLine1",
        "variableName": "addressLine1"
      },
      {
        "kind": "Variable",
        "name": "addressLine2",
        "variableName": "addressLine2"
      },
      {
        "kind": "Variable",
        "name": "addressLine3",
        "variableName": "addressLine3"
      },
      {
        "kind": "Variable",
        "name": "addressUseCodeId",
        "variableName": "addressUseCodeId"
      },
      {
        "kind": "Variable",
        "name": "city",
        "variableName": "city"
      },
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "latitude",
        "variableName": "latitude"
      },
      {
        "kind": "Variable",
        "name": "longitude",
        "variableName": "longitude"
      },
      {
        "kind": "Variable",
        "name": "organizationAddressId",
        "variableName": "organizationAddressId"
      },
      {
        "kind": "Variable",
        "name": "postalCode",
        "variableName": "postalCode"
      },
      {
        "kind": "Variable",
        "name": "state",
        "variableName": "state"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditOrganizationAddressModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMutationGroup",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "OrganizationAddressModifyPayload",
            "kind": "LinkedField",
            "name": "modifyAddress",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationAddress",
                "kind": "LinkedField",
                "name": "organizationAddress",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationAddress",
                        "kind": "LinkedField",
                        "name": "addresses",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "OrganizationAddresses_Addresses"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v9/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v11/*: any*/),
      (v10/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditOrganizationAddressModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMutationGroup",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "OrganizationAddressModifyPayload",
            "kind": "LinkedField",
            "name": "modifyAddress",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationAddress",
                "kind": "LinkedField",
                "name": "organizationAddress",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationAddress",
                        "kind": "LinkedField",
                        "name": "addresses",
                        "plural": true,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AddressUseCode",
                            "kind": "LinkedField",
                            "name": "addressUseCode",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "display",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "addressLine1",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "addressLine2",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "addressLine3",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postalCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "longitude",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latitude",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "County",
                            "kind": "LinkedField",
                            "name": "county",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94ef5c43b18f55531aeecb33620e6570",
    "id": null,
    "metadata": {},
    "name": "EditOrganizationAddressModalMutation",
    "operationKind": "mutation",
    "text": "mutation EditOrganizationAddressModalMutation(\n  $organizationAddressId: ID!\n  $addressUseCodeId: ID!\n  $addressLine1: String!\n  $addressLine2: String!\n  $addressLine3: String!\n  $city: String!\n  $state: String!\n  $postalCode: String!\n  $longitude: DecimalInput\n  $latitude: DecimalInput\n  $effectiveFrom: DateOnly\n  $effectiveTo: DateOnly\n) {\n  organization {\n    modifyAddress(input: {organizationAddressId: $organizationAddressId, addressUseCodeId: $addressUseCodeId, addressLine1: $addressLine1, addressLine2: $addressLine2, addressLine3: $addressLine3, city: $city, state: $state, postalCode: $postalCode, longitude: $longitude, latitude: $latitude, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      organizationAddress {\n        id\n        organization {\n          addresses {\n            ...OrganizationAddresses_Addresses\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment OrganizationAddresses_Addresses on OrganizationAddress {\n  id\n  addressUseCode {\n    id\n    display\n  }\n  addressLine1\n  addressLine2\n  addressLine3\n  city\n  state\n  postalCode\n  longitude\n  latitude\n  county {\n    id\n    name\n    state\n  }\n  effectiveFrom\n  effectiveTo\n}\n"
  }
};
})();

node.hash = "4bf710693502079786b2a1d6e28a9585";

export default node;

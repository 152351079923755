import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {addEditAndDeleteCells, DateCell} from "../../../components/common/advance-table/CustomCells";
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {useUser} from "../../../hooks/useUser";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import NewOrganizationContactPointModal from "./NewOrganizationContactPointModal";
import EditOrganizationContactPointModal from "./EditOrganizationContactPointModal";
import DeleteOrganizationContactPointModal from "./DeleteOrganizationContactPointModal";

const columns = [
    {
        accessor:"contactPointSystemCode.display",
        Header:"System",
    },
    {
        accessor:"contactPointUseCode.display",
        Header:"Use",
    },
    {
        accessor:"value",
        Header:"Value"
    },
    {
        accessor:"effectiveFrom",
        Header:"Effective From",
        Cell: DateCell
    },
    {
        accessor:"effectiveTo",
        Header:"Effective To",
        Cell:DateCell
    }
]
const OrganizationContactPoints = ({contactPoints, organizationId, contactPointSystemCodes, contactPointUseCodes}) => {
    const data = useFragment(graphql`
        fragment OrganizationContactPoints_ContactPoints on OrganizationContactPoint @relay(plural: true) {
            id
            contactPointSystemCode {
                id
                display
            }
            contactPointUseCode {
                id
                display
            }
            value
            effectiveFrom
            effectiveTo
        }`,contactPoints)
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedContactPoint, setSelectedContactPoint] = useState({});

    const handleEditClick = (data) => {
        setSelectedContactPoint(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedContactPoint(data);
        setShowDeleteModal(true);
    }
    
    const cols = useMemo(() => {
        const canEdit = hasPermission("OrganizationContactPoints.Update");
        const canDelete = hasPermission("OrganizationContactPoints.Delete");
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick)
    }, [hasPermission])
    
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
                                 >
                <Card className={"mb-3"}>
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Contact Points"}
                                         table
                                         showNew={hasPermission("OrganizationContactPoints.Create")}
                        />
                    </Card.Header>
                    <Card.Body className={"p-0"}>
                        <AdvanceTable table
                                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                                      rowClassName="align-middle white-space-nowrap"
                                      tableProps={{
                                          size: 'sm',
                                          striped: true,
                                          className: 'fs--1 mb-0 overflow-hidden'}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewOrganizationContactPointModal organizationId={organizationId} 
                                              onClose={() => {setShowNewModal(false)}} 
                                              contactPointSystemCodes={contactPointSystemCodes} 
                                              showModal={showNewModal} 
                                              contactPointUseCodes={contactPointUseCodes}
            />
            <EditOrganizationContactPointModal onClose={() => {setShowEditModal(false)}} 
                                               showModal={showEditModal} 
                                               selectedContactPoint={selectedContactPoint}
                                               contactPointSystemCodes={contactPointSystemCodes}
                                               contactPointUseCodes={contactPointUseCodes}
            />
            <DeleteOrganizationContactPointModal showModal={showDeleteModal}
                                                 onClose={() => {setShowDeleteModal(false)}}
                                                 selectedContactPoint={selectedContactPoint}
            />
        </>
    )
}
OrganizationContactPoints.propTypes = {}
export default OrganizationContactPoints
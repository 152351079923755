/**
 * @generated SignedSource<<d9afef76bb2309bf80ae9290de0cd9b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identifierTypeId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identifierUseCodeId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "display",
    "storageKey": null
  },
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationMutationGroup",
    "kind": "LinkedField",
    "name": "organization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "effectiveFrom",
                "variableName": "effectiveFrom"
              },
              {
                "kind": "Variable",
                "name": "effectiveTo",
                "variableName": "effectiveTo"
              },
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              },
              {
                "kind": "Variable",
                "name": "identifierTypeId",
                "variableName": "identifierTypeId"
              },
              {
                "kind": "Variable",
                "name": "identifierUseCodeId",
                "variableName": "identifierUseCodeId"
              },
              {
                "kind": "Variable",
                "name": "value",
                "variableName": "value"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "OrganizationIdentifierModifyPayload",
        "kind": "LinkedField",
        "name": "modifyIdentifier",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationIdentifier",
            "kind": "LinkedField",
            "name": "organizationIdentifier",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveFrom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveTo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "IdentifierUseCode",
                "kind": "LinkedField",
                "name": "identifierUseCode",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "IdentifierType",
                "kind": "LinkedField",
                "name": "identifierType",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditOrganizationIdentifierModalMutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditOrganizationIdentifierModalMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "f075df15add5ded331dee383ce6b8c57",
    "id": null,
    "metadata": {},
    "name": "EditOrganizationIdentifierModalMutation",
    "operationKind": "mutation",
    "text": "mutation EditOrganizationIdentifierModalMutation(\n  $id: ID!\n  $identifierTypeId: ID!\n  $identifierUseCodeId: ID!\n  $value: String!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  organization {\n    modifyIdentifier(input: {id: $id, identifierTypeId: $identifierTypeId, identifierUseCodeId: $identifierUseCodeId, value: $value, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      organizationIdentifier {\n        id\n        organization {\n          id\n        }\n        effectiveFrom\n        effectiveTo\n        value\n        identifierUseCode {\n          display\n          id\n        }\n        identifierType {\n          display\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "c1a5f49080eb6747017fb99d8022c105";

export default node;

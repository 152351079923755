import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {useUser} from "../../../hooks/useUser";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import NewContractAttachmentModal from "./NewContractAttachmentModal";
import {useFragment} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {addEditAndDeleteCells, UtcTimestampCell} from "../../../components/common/advance-table/CustomCells";
import DeleteContractAttachmentModal from "./DeleteContractAttachmentModal";

const defaultColumns = [
    {
        accessor: 'fileName',
        Header: "File Name",
        Cell: rowData => {
            const {downloadUrl, id, fileName} = rowData.row.original;
            return (<a href={downloadUrl} download={fileName}>{fileName}</a>);
        }
    },
    {
        accessor: 'category.name',
        Header: "Category",
    },
    {
        accessor: 'size',
        Header: "Size",
    },
    {
        accessor: 'lastModifiedTimestampUtc',
        Header: "Last Modified",
        Cell: UtcTimestampCell
    }
]

const ContractAttachments = ({attachments, contractId, possibleAttachmentCategories}) => {
    const data = useFragment(graphql`
        fragment ContractAttachments_Attachments on LegalContractAttachment @relay(plural: true) {
            id
            fileName
            size
            lastModifiedTimestampUtc
            downloadUrl
            category {
                id
                name
            }
        }
    `, attachments);
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState({});
    const [attachmentToDelete, setAttachmentToDelete] = useState(null);
    const showDeleteModal = attachmentToDelete != null;

    const handleEditClick = (data) => {
        setSelectedTerm(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setAttachmentToDelete(data);
    }

    const cols = useMemo(() => {
        const canEdit = hasPermission("LegalContractAttachments.Update") && false
        const canDelete = hasPermission("LegalContractAttachments.Delete")
        return addEditAndDeleteCells(canEdit, canDelete, defaultColumns, handleEditClick, handleDeleteClick)
    }, [hasPermission])

    return (
        <>
            <AdvanceTableWrapper columns={cols} 
                                 data={data} 
                                 sortable 
                                 pagination 
                                 perPage={10}
                                 initialSortColumn={'fileName'}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                         title={"Contract Attachments"}
                                         table
                                         showNew={hasPermission("LegalContractAttachments.Create")}
                        />
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable table
                                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                                      rowClassName="align-middle white-space-nowrap"
                                      tableProps={{
                                          size: 'sm',
                                          striped: true,
                                          className: 'fs--1 mb-0 overflow-hidden'}}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
            <NewContractAttachmentModal contractId={contractId}
                                        allAttachmentCategories={possibleAttachmentCategories}
                                        showModal={showNewModal}
                                        onClose={() => {
                                            setShowNewModal(false)
                                        }}
            />
            {/*<EditContractTermModal showModal={showEditModal} */}
            {/*                       onClose={() => {setShowEditModal(false)}} */}
            {/*                       selectedTerm={selectedTerm} */}
            {/*                       possibleTermTypes={possibleTermTypes}*/}
            {/*/>*/}
            <DeleteContractAttachmentModal showModal={showDeleteModal}
                                     onClose={() => {setAttachmentToDelete(null)}}
                                     attachmentId={attachmentToDelete?.id}
                                     fileName={attachmentToDelete?.fileName}
                                     contractId={contractId}      
            />
        </>
    )
}
ContractAttachments.propTypes = {    
    attachments: PropTypes.array.isRequired,
    contractId: PropTypes.string.isRequired,
    possibleAttachmentCategories: PropTypes.array.isRequired
}
export default ContractAttachments
/**
 * @generated SignedSource<<5867c7c0d5b4beb203cf90c0fc1a6182>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Fragments_AllContactPointUseCodesDropdown",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display",
      "storageKey": null
    }
  ],
  "type": "ContactPointUseCode",
  "abstractKey": null
};

node.hash = "c0bc577f9000604d570589677d0dc63f";

export default node;

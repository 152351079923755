/**
 * @generated SignedSource<<4b12369d0cefd1058837e0abddb5b9e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addPermissions"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removePermissions"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "addPermissionIds",
        "variableName": "addPermissions"
      },
      {
        "kind": "Variable",
        "name": "removePermissionIds",
        "variableName": "removePermissions"
      },
      {
        "kind": "Variable",
        "name": "roleId",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RolePermissionsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RoleMutationGroup",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RoleModifyRolePayload",
            "kind": "LinkedField",
            "name": "modifyPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RolePermissions_rolePermission"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RolePermissionsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RoleMutationGroup",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RoleModifyRolePayload",
            "kind": "LinkedField",
            "name": "modifyPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Permission",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9e376a1b3ce25dd268e47380bf51ea0",
    "id": null,
    "metadata": {},
    "name": "RolePermissionsMutation",
    "operationKind": "mutation",
    "text": "mutation RolePermissionsMutation(\n  $id: ID!\n  $addPermissions: [ID!]!\n  $removePermissions: [ID!]!\n) {\n  role {\n    modifyPermissions(input: {roleId: $id, addPermissionIds: $addPermissions, removePermissionIds: $removePermissions}) {\n      role {\n        ...RolePermissions_rolePermission\n        id\n      }\n    }\n  }\n}\n\nfragment RolePermissions_rolePermission on Role {\n  id\n  permissions {\n    id\n    name\n  }\n}\n"
  }
};
})();

node.hash = "5c31c3868c6ca82617b88b69484dab49";

export default node;

import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import { logs } from 'data/ecommerce/customerDetailsData';
import classNames from 'classnames';

const data = [
    {
        id: '1',
        action: 'create',
        user: 'user1@accesshealthservices.com',
        date: '2019/02/26',
        time: '12:23:43'
    },
    {
        id: '2',
        action: 'edit',
        user: 'user3@accesshealthservices.com',
        date: '2021/03/26',
        time: '11:05:01'
    }
]

const OrganizationLog = () => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0">Logs</h5>
      </Card.Header>
      <Card.Body className="border-top p-0">
        {data.map(log => (
          <Row
            key={log.id}
            className="g-0 align-items-center border-bottom py-2 px-3"
          >
            <Col md="auto" className="pe-3">
              <span
                className={classNames('badge rounded-pill', {
                  'badge-soft-success': log.action === 'create',
                  'badge-soft-info': log.action === 'edit'
                })}
              >
                {log.action}
              </span>
            </Col>
            <Col md className="mt-1 mt-md-0">
              <code>
                {log.user}
              </code>
            </Col>
            <Col md="auto">
              <p className="mb-0">
                {log.date} {log.time}
              </p>
            </Col>
          </Row>
        ))}
      </Card.Body>
      <Card.Footer className="bg-light p-0">
        
      </Card.Footer>
    </Card>
  );
};

export default OrganizationLog;
